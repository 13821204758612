import { Person as PersonIcon } from '@material-ui/icons'
import { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createCustomer, hasPatientsRemaining } from '../../actions/customers'
import CustomerForm from '../../components/CustomerForm'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import { StyledAddCustomer } from './StyledAddCustomer'
import DataLoading from '../../components/DataLoading/DataLoading'
import { plan } from '../../shared/utils/constants'
import { sApiServer } from '../../shared/utils/auth'
import { useParams } from 'react-router-dom'
import { sCurrentUser } from '../../shared/utils/users'

interface Props {
  actions: any
  dialogActions: {
    openTerminAbsageDialog: (termin: any) => void
    openTerminAbrechnenDialog: (termin: any) => void
    openFinishAnamneseDialog: (anamnese: any) => void
    openPlanLimitDialog: (data: {
      isOpen: boolean
      title: string
      body: string
      showConfirmBtn: boolean
      showUpgradeBtn: boolean
      confirmRedirectPath?: string
    }) => void
    closePlanLimitDialog: () => void
  }
  doctors: any
}

const AddCustomer = ({ actions, dialogActions, doctors, currentUser, currentServer }: Props) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [loadingPlanData, setLoadingPlanData] = useState<boolean>(true)
  const [patientsTotal, setPatientsTotal] = useState<number>(0)
  const [ownerPlanId, setOwnerPlanId] = useState<string | null>(null)

  const { serverHash } = useParams()

  const saveForm = async (data) => {
    data.krankenkasse = !data.krankenkasse || data.krankenkasse === 'null' ? null : data.krankenkasse
    data.titel = !data.titel || data.titel === 'null' ? null : data.titel

    actions.createCustomer(data, true)
  }

  if (!doctors) return null

  const getPatientsRemaining = async () => {
    const result = await actions.hasPatientsRemaining()
    setPatientsTotal(result.ownerPatientsCount)
    setOwnerPlanId(result.ownerPlanId)
    console.log('result: ', result)

    setLoadingPlanData(false)
  }

  useEffect(() => {
    setLoadingPlanData(true)

    getPatientsRemaining()
  }, [])

  useEffect(() => {
    if (loadingPlanData) return
    if (!ownerPlanId) return

    const planDataForId = plan.find((p) => p.id === ownerPlanId)

    if (planDataForId) {
      const { upgradeThreshold, maxNumberOfPatients } = planDataForId
      const remainingPatients = maxNumberOfPatients ? maxNumberOfPatients - patientsTotal : 0
      const isOwner = currentUser?.authUserId === currentServer?.createdBy

      if (maxNumberOfPatients !== null && remainingPatients <= 0) {
        dialogActions.openPlanLimitDialog({
          isOpen: true,
          title: 'Patientenlimit erreicht',
          body: 'Die maximale Patientenanzahl Ihres Planmodells ist erreicht',
          showConfirmBtn: true,
          showUpgradeBtn: isOwner,
          confirmRedirectPath: `/${serverHash}/contacts/customers`,
        })
      } else if (maxNumberOfPatients !== null && upgradeThreshold && remainingPatients <= upgradeThreshold) {
        dialogActions.openPlanLimitDialog({
          isOpen: true,
          title: 'Patientenlimit fast erreicht',
          body: `Die maximale Patientenanzahl Ihres Planmodells ist fast erreicht (${remainingPatients} verbleibend)`,
          showConfirmBtn: true,
          showUpgradeBtn: isOwner,
        })
      }
    }
  }, [loadingPlanData, patientsTotal, ownerPlanId])

  return loadingPlanData ? (
    <DataLoading text="Lade Daten..." />
  ) : (
    <StyledAddCustomer>
      <IconHeading icon={<PersonIcon />} text="Patient neu anlegen" />
      <CustomerForm
        onSubmitFunc={saveForm}
        doctors={doctors}
        initialValues={{
          patientSeit: currentDate,
          vorname: null,
          nachname: null,
          geschlecht: null,
          titel: null,
          geburtsdatum: null,
          strasse: null,
          hausnummer: null,
          postleitzahl: null,
          ort: null,
          telefon: null,
          mobil: null,
          email: null,
          emailErinnerung: null,
          emailNewsletter: null,
          krankenkasse: null,
          versichertennummer: null,
          hausarzt: null,
          facharzt: null,
          frequenz: null,
          maxFrequenz: null,
          empfehlung: null,
          bisherigePodologie: null,
          abgewandertZu: null,
          bild: null,
          verstorben: null,
          notizen: null,
          dateien: null,
          land: 'DE',
          sozialeGemeinschaft: null,
          patientennummer: null,
          regularTherapist: null,
          versichertenstatus: null,
          ikNummerKK: null,
          mobilCountry: 49,
        }}
      />
    </StyledAddCustomer>
  )
}

const mapStateToProps = (state) => ({
  doctors: state.entities.aerzte,
  currentUser: sCurrentUser(state),
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createCustomer: bindActionCreators(createCustomer, dispatch),
    hasPatientsRemaining: bindActionCreators(hasPatientsRemaining, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(AddCustomer))
