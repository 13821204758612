import { useEffect } from 'react'
import { useAuth } from './AuthProvider/useAuth'
import { hasAuthParams } from './AuthProvider/utils'
import DataLoading from '../components/DataLoading/DataLoading'

export const PrivateRoute = ({ children }): JSX.Element => {
  const auth = useAuth()

  useEffect(() => {
    if (hasAuthParams() || auth.isAuthLoading || auth.isAuthenticated) return

    if (!auth.isAuthenticated) {
      window.localStorage.setItem('login_redirect_url', window.location.pathname)
      auth.signinRedirect({ state: { targetUrl: window.location.pathname }})
    }
  }, [auth.isAuthLoading, auth.isAuthenticated])

  return auth.isAuthenticated ? children : <DataLoading text="Login..." />
}
