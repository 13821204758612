export const startbereichRoutes = ['', 'startbereich', 'login', 'register', 'bezahlen', 'pwyw']

export const START_PAGE_PATH = 'startbereich'
export const NEW_PAYMENT_PAGE_PATH = 'pwyw'

export const plansStaging = [
  {
    id: '01916f4ecc87705ba04e49a6f16a205e',
    articleNo: '123456',
    name: 'calendar',
    maxNumberOfPatients: null,
    maxNumberOfPatientsPrefix: 'unbegrenzt',
    maxNumberOfPractices: 2,
    supportTime: 20,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 10,
    kkInterfaceAvailable: false,
    kkInterfaceIncluded: false,
  },
  {
    id: '018fa55aa18775e694cb34d0ca585e91',
    articleNo: '7890101',
    name: 'free',
    maxNumberOfPatients: 20,
    upgradeThreshold: 5,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 1,
    supportTime: 0,
    supportReachability: 'Chat, Ticket, E-Mail',
    smsPrice: null,
    kkInterfaceAvailable: false,
    kkInterfaceIncluded: false,
  },
  {
    id: '018ef5c7d65779758ff4ca94d29d3853',
    articleNo: '7890201',
    name: 'small',
    maxNumberOfPatients: 600,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 1,
    supportTime: 20,
    supportReachability: 'Chat, Ticket, E-Mail',
    smsPrice: 10,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: false,
  },
  {
    id: '018fde788045739486037aa44d122e48',
    articleNo: '7890801',
    name: 'small+',
    maxNumberOfPatients: 600,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 1,
    supportTime: 20,
    supportReachability: 'Chat, Ticket, E-Mail',
    smsPrice: 10,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '018ef5d520b67fdabb15be4f39e308d6',
    articleNo: '7890301',
    name: 'medium',
    maxNumberOfPatients: 1200,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 2,
    supportTime: 40,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 8,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: false,
  },
  {
    id: '018fde8817a57d43ae13b83f0d3069f5',
    articleNo: '7890901',
    name: 'medium+',
    maxNumberOfPatients: 1200,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 2,
    supportTime: 40,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 8,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '018ef5d748e9715da9722f0e289a8ae1',
    articleNo: '7890401',
    name: 'large',
    maxNumberOfPatients: 4000,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 3,
    supportTime: 60,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 5,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '018fa5602d2873718b136d1d8983552d',
    articleNo: '7890501',
    name: 'extra large',
    maxNumberOfPatients: 8000,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 10,
    supportTime: 60,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 3,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '018fa55aa18775e694cb34d0ca585e91',
    articleNo: '7890701',
    name: 'school',
    maxNumberOfPatients: 4000,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 3,
    supportTime: 40,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 5,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '01916f4fac5c7a36b3a37adf86ebdc5b',
    articleNo: '123456',
    name: 'unlimited',
    maxNumberOfPatients: null,
    maxNumberOfPatientsPrefix: 'unbegrenzt',
    maxNumberOfPractices: null,
    maxNumberOfPracticesPrefix: 'unbegrenzt',
    supportTime: 60,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 3,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
]

export const plansProd = [
  {
    id: '01916f12f8bf7a939df0d40cea541651',
    articleNo: '123456',
    name: 'calendar',
    maxNumberOfPatients: null,
    maxNumberOfPatientsPrefix: 'unbegrenzt',
    maxNumberOfPractices: 2,
    supportTime: 20,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 10,
    kkInterfaceAvailable: false,
    kkInterfaceIncluded: false,
  },
  {
    id: '01904a4b7e867600aea452adf8311fbb',
    articleNo: '7890101',
    name: 'free',
    maxNumberOfPatients: 20,
    upgradeThreshold: 5,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 1,
    supportTime: 0,
    supportReachability: 'Chat, Ticket, E-Mail',
    smsPrice: null,
    kkInterfaceAvailable: false,
    kkInterfaceIncluded: false,
  },
  {
    id: '01904a430e2974db9ae5ce682de452a5',
    articleNo: '7890201',
    name: 'small',
    maxNumberOfPatients: 600,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 1,
    supportTime: 20,
    supportReachability: 'Chat, Ticket, E-Mail',
    smsPrice: 10,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: false,
  },
  {
    id: '01904a505fa47f5a9df6d10f79ce76b3',
    articleNo: '7890801',
    name: 'small+',
    maxNumberOfPatients: 600,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 1,
    supportTime: 20,
    supportReachability: 'Chat, Ticket, E-Mail',
    smsPrice: 10,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '01904a467f7a7c6ab1583cb6a799187e',
    articleNo: '7890301',
    name: 'medium',
    maxNumberOfPatients: 1200,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 2,
    supportTime: 40,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 8,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: false,
  },
  {
    id: '01904a51ebcf759a8eb7efcb198fae76',
    articleNo: '7890901',
    name: 'medium+',
    maxNumberOfPatients: 1200,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 2,
    supportTime: 40,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 8,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '01904a4913d876a4b85ee4c0dafab59d',
    articleNo: '7890401',
    name: 'large',
    maxNumberOfPatients: 4000,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 3,
    supportTime: 60,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 5,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '01904a4e0b1f7065b881cec4fdb394d0',
    articleNo: '7890501',
    name: 'extra large',
    maxNumberOfPatients: 8000,
    upgradeThreshold: 20,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 10,
    supportTime: 60,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 3,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '01904a531e917c4088f331e7985f9f0a',
    articleNo: '7890701',
    name: 'school',
    maxNumberOfPatients: 4000,
    maxNumberOfPatientsPrefix: 'bis',
    maxNumberOfPractices: 3,
    supportTime: 40,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 5,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
  {
    id: '01916f1e56e574abafa3d86f2bf7f7f3',
    articleNo: '123456',
    name: 'unlimited',
    maxNumberOfPatients: null,
    maxNumberOfPatientsPrefix: 'unbegrenzt',
    maxNumberOfPractices: null,
    maxNumberOfPracticesPrefix: 'unbegrenzt',
    supportTime: 60,
    supportReachability: 'Telefon, Chat, Ticket, E-Mail',
    smsPrice: 3,
    kkInterfaceAvailable: true,
    kkInterfaceIncluded: true,
  },
]

export const plan = process.env.NODE_ENV === 'production' ? plansProd : plansStaging
