import { useEffect } from 'react'
import { useAuth } from '../containers/AuthProvider/useAuth'
import { useNavigate } from 'react-router-dom'

export const LoginCallback = (): JSX.Element => {
  const { isAuthLoading, isAuthenticated } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated) {
      const loginRedirectUrl = window.localStorage.getItem('login_redirect_url')
      navigate(loginRedirectUrl || '/startbereich')
    }
  }, [isAuthLoading, isAuthenticated])

  return <div>You should not see this page</div>
}

