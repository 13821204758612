import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import {
  ArrowRightAlt as ArrowRightAltIcon,
  ErrorOutline as ErrorOutlineIcon, // ion-alert-circled
} from '@material-ui/icons'
import { format } from 'date-fns'
import { default as de } from 'date-fns/locale/de'
import * as R from 'ramda'
import { FC } from 'react'
import { connect } from 'react-redux'
import { asyncValidateDate } from '../../actions/dates'
import { showNotification } from '../../actions/notification'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'
import PrettyTime from '../PrettyTime/PrettyTime'
import {
  istTerminAusserhalbDerArbeitszeiten,
  istTerminAusserhalbDerOeffnungszeiten,
  sEvent,
  sHVOConditions,
  sIntersectedAllDayAppointments,
} from './selectors'
import { sApiServer } from '../../shared/utils/auth'
import { isReadOnly } from '../../utils/helpers'

const MoveDateDialog: FC<any> = ({
  date,
  target,
  shorterDuration,
  closeDialog,
  open,
  updateDate,
  dispatch,
  terminIstAusserhalbderOeffnungszeiten,
  terminIstAusserhalbderArbeitszeiten,
  intersectedAllDayAppointments,
  HVOConditions,
  event,
  people,
  currentServer,
}) => {
  const moveDate = async () => {
    const { beginn, ende, therapeut } = target

    let data = {}
    if (!date.istOrganisatorisch) {
      data = {
        ...date,
        therapeut,
        beginn,
        ende,
      }
    } else {
      data = {
        ...date,
        beginn,
        ende,
        teilnehmerOrganisatorischerTermin:
          date.teilnehmerOrganisatorischerTermin.filter((id) => id === therapeut).length === 0
            ? [...date.teilnehmerOrganisatorischerTermin, therapeut]
            : date.teilnehmerOrganisatorischerTermin,
      }
    }

    try {
      await dispatch(asyncValidateDate(data))
      updateDate && (await updateDate(data))
    } catch (err) {
      console.log(err)
      await dispatch(showNotification(err._error))
    }
    closeDialog()
  }

  const errors = null
  /* let errors = null
  if (target && date) {
    errors = validateHVOConditions(
      { beginn: target.beginn, heilmittelverordnung: date.heilmittelverordnung, id: date.id },
      { HVOConditions },
    )
    if (errors.heilmittelverordnung) {
      actions[1] = null
    }
  } */

  const warnings = [
    ...R.chain(
      (appointment) => [`Überschneidung mit "${appointment.titelOrganisatorischerTermin}"`, <br key={1} />],
      intersectedAllDayAppointments,
    ),
    ...(event
      ? [`Überschneidung mit "${event.name}" im externen Kalender "${event.bezeichnung}".`, <br key={1} />]
      : []),
    ...(terminIstAusserhalbderOeffnungszeiten
      ? ['Termin liegt außerhalb der Öffnungszeit, kann jedoch trotzdem verschoben werden.', <br key={1} />]
      : []),
    ...(terminIstAusserhalbderArbeitszeiten && !terminIstAusserhalbderOeffnungszeiten
      ? ['Termin liegt außerhalb der Arbeitszeit, kann jedoch trotzdem verschoben werden.', <br key={1} />]
      : []),
    ...(HVOConditions.frequenzunterschreitungTag.vergangenheit
      ? [
          `Achtung! Min. Frequenz der HVO um ${HVOConditions.frequenzunterscheitungTag.differenzTage} Tag(e) unterschritten. Es kann zur Absetzung durch die KK kommen.`,
          <br key={1} />,
        ]
      : []),
    ...(HVOConditions.frequenzunterschreitungTag.zukunft
      ? [
          `Achtung! Der Folgetermin unterschreitet die min. Frequenz um ${HVOConditions.frequenzunterschreitungTag.differenzTage} Tag(e). Es kann zur Absetzung durch die KK kommen.`,
          <br key={1} />,
        ]
      : []),
  ]

  return target ? (
    <Dialog disableBackdropClick open={open} onClose={closeDialog}>
      <DialogTitle>Termin verschieben</DialogTitle>
      <DialogContent>
        {(!errors || (errors && !errors.heilmittelverordnung)) && (
          <span>
            Wollen Sie den Termin wirklich verschieben?
            <br />
          </span>
        )}
        {warnings.length > 0 && (
          <span>
            {warnings.map((w, i) => (
              <span key={i}>{w}</span>
            ))}
          </span>
        )}
        {errors && errors.heilmittelverordnung && <span>{errors.heilmittelverordnung}</span>}
        <br />
        {date && target && (
          <div>
            {format(date.beginn, 'EEEE', { locale: de })}&nbsp;
            {format(date.beginn, GERMAN_DATE_SHORT_YEAR_FNS)}&nbsp;
            <PrettyTime time={date.beginn} /> - <PrettyTime time={date.ende} />
            <br />
            {!date.istOrganisatorisch ? people[date.therapeut].vorname : null}
            <br />
            <ArrowRightAltIcon />
            <br />
            {format(target.beginn, 'EEEE', { locale: de })}&nbsp;
            {format(target.beginn, GERMAN_DATE_SHORT_YEAR_FNS)}&nbsp;
            <PrettyTime time={target.beginn} /> - <PrettyTime time={target.ende} />
            <br />
            {!date.istOrganisatorisch ? people[target.therapeut].vorname : null}
          </div>
        )}
        {shorterDuration && (
          <div>
            <ErrorOutlineIcon style={{ color: 'red' }} />
            &nbsp;Achtung! Die Dauer wird verkürzt, um das gewählte Zeitfenster nutzen zu können.
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={closeDialog}>
          Abbrechen
        </Button>
        {!isReadOnly() && (
          <Button variant="contained" color="secondary" onClick={moveDate}>
            Termin verschieben
          </Button>
        )}
      </DialogActions>
    </Dialog>
  ) : null
}

const mapStateToProps = (state, props) => ({
  termine: state.entities.termine,
  heilmittelverordnungenEntities: state.entities.heilmittelverordnungen,
  terminIstAusserhalbderOeffnungszeiten: istTerminAusserhalbDerOeffnungszeiten(state, props),
  terminIstAusserhalbderArbeitszeiten: istTerminAusserhalbDerArbeitszeiten(state, props),
  HVOConditions: sHVOConditions(state, props),
  event: sEvent(state, props),
  intersectedAllDayAppointments: sIntersectedAllDayAppointments(state, props),
  currentServer: sApiServer(state),
})

export default connect(mapStateToProps)(MoveDateDialog)
