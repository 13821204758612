import { type User } from 'oidc-client'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { currentUserAuth } from '../../redux/modules/auth'
import { AuthContext } from './AuthContext'
import { hasAuthParams, userManager } from './utils'
import { sCurrentUser } from '../../shared/utils/users'

export const AuthProvider = ({ children }): JSX.Element => {
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<User | null>(null)

  const dispatch = useDispatch()

  const didInitialize = useRef(false)

  const userDataInRedux = useSelector((state) => sCurrentUser(state))

  useEffect(() => {
    if (user?.profile?.email && userDataInRedux?.email) {
        window?.__configure__global__feedback_widget__(userDataInRedux)
    }
  }, [user?.profile?.email, userDataInRedux?.email])

  useEffect(() => {
    if (!userManager || didInitialize.current) return

    didInitialize.current = true

    void (async (): Promise<void> => {
      // Sign-in
      let usr: User | null = null
      try {
        // Check if returning back from authority server
        if (hasAuthParams()) {
          const testingRedirectUrl = window.localStorage.getItem('login_redirect_url')
          usr = await userManager.signinCallback()
          window.history.replaceState({}, document.title, window.location.pathname)
        }

        dispatch(currentUserAuth())
        usr = !usr ? await userManager.getUser() : usr
        setIsAuthLoading(false)

        setIsAuthenticated(usr ? !usr.expired : false)
      } catch (err) {
        console.error('Error while initializing auth provider:', err)
      }

      // Sign-out
      try {
        await userManager.signoutCallback()
      } catch (err) {
        console.error('Error while initializing auth provider:', err)
      }
    })()
  }, [userManager])

  useEffect(() => {
    if (!userManager) return

    // Subscribe to logged in event
    const handleUserLoaded = (loadedUser: User) => {
      setIsAuthenticated(loadedUser ? !loadedUser.expired : false)
      setIsAuthLoading(false)
      setUser(loadedUser)
    }
    userManager.events.addUserLoaded(handleUserLoaded)

    // Subscribe to unloaded and signed out events
    const handleUserUnloadedOrSignedOut = () => {
      setIsAuthenticated(false)
      setUser(null)
    }
    userManager.events.addUserUnloaded(handleUserUnloadedOrSignedOut)
    userManager.events.addUserSignedOut(handleUserUnloadedOrSignedOut)

    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded)
      userManager.events.removeUserUnloaded(handleUserUnloadedOrSignedOut)
      userManager.events.removeUserSignedOut(handleUserUnloadedOrSignedOut)
    }
  }, [userManager])

  const signinRedirect = async (): Promise<void> => {
    await userManager.signinRedirect()
  }

  const signout = async () => {
    await userManager.removeUser()
    await userManager.signoutRedirect({ id_token_hint: user?.id_token })
    setIsAuthenticated(false)
    setUser(null)
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAuthLoading, user, signinRedirect, signout }}>
      {children}
    </AuthContext.Provider>
  )
}
