import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { addMinutes, setHours, setMinutes, setSeconds } from 'date-fns'
import { differenceInMinutes } from 'date-fns/esm'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useRef, useState, memo } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { createDate } from '../../actions/dates'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import CheckboxField from '../../shared/components/CheckboxField'
import { trackError } from '../../shared/utils/trackjs'
import { getDateAfterSameDaySameTherapeut } from '../../utils/dates'
import TerminForm from '../TerminForm/TerminForm'
import TerminOrganisatorischForm from '../TerminOrganisatorischForm/TerminOrganisatorischForm'
import { AddDateDialogStyled } from './AddDateDialogStyled'
import { sApiServer } from '../../shared/utils/auth'
import { isReadOnly } from '../../utils/helpers'

const AddDateDialog = ({
  actions,
  date,
  open,
  closeDialog,
  selectedTerminProps,
  selectedPatient,
  selectedHeilmittelverordnung,
  termine,
  praxisstammdaten,
  loading,
  notification,
  currentServer,
}): JSX.Element => {
  const [terminIstOrganisatorisch, setTerminIstOrganisatorisch] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [delayingNextMessage, setDelayingNextMessage] = useState<boolean>(false)

  const { serverHash } = useParams()
  const navigate = useNavigate()

  const terminOrganisatorischForm = useRef<any>()
  const terminForm = useRef<any>()

  const handleTerminOrganisatorischChange = useCallback(
    (checked): void => {
      setTerminIstOrganisatorisch(checked)
    },
    [setTerminIstOrganisatorisch],
  )

  const createDate = useCallback(
    async (data): Promise<void> => {
      closeDialog()
      await actions.createDate({
        ...data,
        patient: parseInt(data.patient, 10),
        ...(data.isAllDay
          ? {
              beginn: setHours(setMinutes(setSeconds(data.beginn, 0), 0), 0),
              ende: setHours(setMinutes(setSeconds(data.ende, 59), 59), 23),
            }
          : {}),
      })

      if (!!data?.leistungen?.find((leistung) => leistung?.istKKLeistung && leistung?.nagelkorrektur)) {
        navigate(`/${serverHash}/hvo/${data?.heilmittelverordnung}`)
      }
    },
    [closeDialog, actions.createDate],
  )

  const doSubmit = debounce(async () => {
    try {
      setIsSubmitting(true)

      if (terminIstOrganisatorisch) {
        await terminOrganisatorischForm.current?.submit()
      } else {
        await terminForm.current?.submit()
      }

      setTerminIstOrganisatorisch(false)
    } catch (err) {
      console.log(err)
      trackError(err, {
        terminIstOrganisatorisch,
      })
    } finally {
      setIsSubmitting(false)
    }
  }, 500)

  let dauer: number | null = null
  if (selectedTerminProps?.beginn && selectedTerminProps?.ende) {
    dauer = differenceInMinutes(selectedTerminProps.ende, selectedTerminProps.beginn)
  } else if (date && termine && praxisstammdaten) {
    const termineArray = Object.keys(termine).map((key) => termine[key])
    const terminNachDiesem = getDateAfterSameDaySameTherapeut(date, termineArray)
    if (terminNachDiesem) {
      const zeitBisNaechsterTermin = differenceInMinutes(terminNachDiesem.beginn, date.beginn)
      if (praxisstammdaten.standardBehandlungsdauer > zeitBisNaechsterTermin) {
        dauer = zeitBisNaechsterTermin
      }
    }
  }

  useEffect(() => {
    const currentMessageNotNull = !!notification?.message
    let timer: any = null
    if (currentMessageNotNull) {
      setDelayingNextMessage(true)
      timer = setTimeout(() => {
        setDelayingNextMessage(false)
      }, 1000)
    }
    return () => {
      timer && clearTimeout(timer)
    }
  }, [notification?.message])

  const isSnackbarOpen = !!notification?.message && !delayingNextMessage

  return (
    <AddDateDialogStyled
      isSnackbarOpen={isSnackbarOpen}
      disableBackdropClick
      onClose={closeDialog}
      open={open}
      scroll="body"
      PaperProps={{ style: { backgroundColor: '#fff' } }}
    >
      <DialogTitle>Termin hinzufügen</DialogTitle>
      <DialogContent>
        <CheckboxField
          label="organisatorischer Termin"
          onChange={handleTerminOrganisatorischChange}
          checked={terminIstOrganisatorisch}
        />
        {date?.beginn && !terminIstOrganisatorisch && (
          <TerminForm
            ref={terminForm}
            referenceDate={date}
            initialValues={{
              beginn: date.beginn,
              ende: dauer ? addMinutes(date.beginn, dauer) : null,
              patient: selectedPatient || selectedTerminProps.patient || null,
              hausbesuch: selectedTerminProps.hausbesuch || null,
              heilmittelverordnung: selectedTerminProps.hvoWirdNachgereicht
                ? 'hvoWirdNachgereicht'
                : selectedHeilmittelverordnung || selectedTerminProps.heilmittelverordnung || null,
              hvoWirdNachgereicht: selectedTerminProps.hvoWirdNachgereicht || null,
              leistungen: selectedTerminProps.leistungen || [],
              notizen: selectedTerminProps.notizen || null,
              therapeut: date?.therapeut || null,
              room: date?.room || null,
              absagegrund: null,
              absagedatum: null,
              begruendungFrequenzueberschreitung: selectedTerminProps.begruendungFrequenzueberschreitung || null,
              istOrganisatorisch: false,
              teilnehmerOrganisatorischerTermin: [],
              titelOrganisatorischerTermin: null,
              preparationNeeded: selectedTerminProps.preparationNeeded || false,
            }}
            onSubmit={createDate}
          />
        )}
        {date?.beginn && terminIstOrganisatorisch && (
          <TerminOrganisatorischForm
            ref={terminOrganisatorischForm}
            referenceDate={date}
            isAllDay={false}
            initialValues={{
              beginn: date.beginn,
              ende: null,
              patient: null,
              hausbesuch: null,
              heilmittelverordnung: null,
              hvoWirdNachgereicht: null,
              leistungen: [],
              notizen: null,
              therapeut: null,
              absagegrund: null,
              absagedatum: null,
              begruendungFrequenzueberschreitung: null,
              istOrganisatorisch: true,
              teilnehmerOrganisatorischerTermin: date ? [date.therapeut] : [],
              titelOrganisatorischerTermin: null,
              isAllDay: false,
              dateShadesCalendar: false,
              preparationNeeded: false,
            }}
            onSubmit={createDate}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" type="button" color="secondary" onClick={closeDialog}>
          Abbrechen {/* Abort */}
        </Button>
        {!isReadOnly() && (
          <Button
            variant="contained"
            type="button"
            color="secondary"
            onClick={doSubmit}
            disabled={loading.patienten || isSubmitting}
          >
            Termin anlegen {/* Create appointment */}
          </Button>
        )}
      </DialogActions>
    </AddDateDialogStyled>
  )
}

const mapStateToProps = (state): any => ({
  selectedTerminProps: state.selectedTerminProps,
  selectedPatient: state.selectedPatient,
  selectedHeilmittelverordnung: state.selectedHeilmittelverordnung,
  termine: state.entities.termine,
  praxisstammdaten: praxisstammdatenSelector(state),
  loading: state.loading,
  notification: state.notification,
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch): any => ({
  actions: {
    createDate: bindActionCreators(createDate, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(AddDateDialog))
