import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core'
import throttle from 'lodash.throttle'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import isEmail from 'validator/lib/isEmail'
import * as changelogActions from '../../actions/changelog'
import { showNotification } from '../../actions/notification'
import { loadPraxisstammdaten } from '../../actions/praxisstammdaten'
import Notification from '../../components/Notification/Notification'
import { addInstance, addNewUser, addUserToInstance } from '../../redux/modules/auth'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import InputField from '../../shared/components/InputField'
import { getAuthUrl } from '../../utils/auth'
import Layout from '../Layout/Layout'
import Info from './Info/Info'
import NewPraxisCard from './NewPraxis/NewPraxis'
import PraxisCard from './PraxisCard/PraxisCard'
import { StyledStartbereich } from './StyledStartbereich'
import UserCard from './UserCard/UserCard'
import image from './logo_greyscale.png'

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { isAfter, isBefore, parseISO } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { MessageError } from '../../shared/components/Message'
import globalNavigation from '../../utils/navigation'

import { NoPlanDialog } from '../../components/NoPlanDialog/NoPlanDialog'
import RuckFlag from '../../shared/components/RuckFlag/RuckFlag'
import { plan } from '../../shared/utils/constants'
import { sCurrentUser } from '../../shared/utils/users'
import { getCurrencyInEuros } from '../../utils/form'
import { calculateTotalPatientsCount } from '../../utils/helpers'
import ChangelogHistoryDialog from './ChangelogHistoryDialog/ChangelogHistoryDialog'
import ViewChangelogDialog from './ViewChangelogDialog/ViewChangelogDialog'

const UNKNOWN_EMAIL_ERROR = 'UNKNOWN_EMAIL_ERROR'

const buildPatientString = (planData) => {}

const Startbereich: FC<any> = ({
  auth,
  actions,
  allChangelogs,
  changelogLastSeen,
  loadingChangelogLastSeen,
  loadPraxisstammdatenComplete,
  fields,
  handleSubmit,
  resetForm,
  praxisstammdaten,
  currentUser,
  credentialsUser,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    document['title'] = 'Startbereich'
  }, [])

  useEffect(() => {
    globalNavigation.navigate = navigate
  }, [])

  const [addPraxisDialogOpen, setAddPraxisDialogOpen] = useState<boolean>(false)
  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false)
  const [changelogHistoryDialogOpen, setChangelogHistoryDialogOpen] = useState<boolean>(false)
  const [viewChangelogDialogOpen, setViewChangelogDialogOpen] = useState<boolean>(false)
  const [viewChangelogId, setViewChangelogId] = useState<number | null>(null)
  const [noPlanDialogData, setNoPlanDialogData] = useState<{
    open: boolean
    content?: React.ReactNode
    title?: string
    showReadOnlyButton?: boolean
  }>({ open: false })
  const [emailError, setEmailError] = useState<any>(null)
  const [dialogServerId, setDialogServerId] = useState<any>(null)
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false)
  const [totalPatientsCreated, setTotalPatientsCreated] = useState(0)

  const currentPlanData = useMemo(() => {
    if (currentUser?.planId) {
      return plan.find((p) => p.id === currentUser?.planId)
    }

    return null
  }, [currentUser?.authUserId])

  useEffect(() => {
    actions.loadPraxisstammdaten()
    actions.changelog.loadLastSeen()
    document.title = 'Startbereich'
  }, [])

  useEffect(() => {
    if (
      !loadingChangelogLastSeen &&
      allChangelogs?.length &&
      currentUser &&
      isAfter(parseISO(allChangelogs[0].createdAt), parseISO(currentUser.createdAt)) &&
      (!changelogLastSeen || isBefore(parseISO(changelogLastSeen), parseISO(allChangelogs[0].createdAt)))
    ) {
      openViewChangelogDialog(null)
    }
  }, [loadingChangelogLastSeen, changelogLastSeen, allChangelogs, viewChangelogId, currentUser])

  const closeDialog = () => {
    setAddPraxisDialogOpen(false)
    setAddUserDialogOpen(false)
    setEmailError(null)

    resetForm()
  }

  const addInstanceOrUser = async (data) => {
    const instanceData = {
      description: data.description.value,
      praxisInhaber: data.praxisInhaber.value,
    }
    const email = data.email ? data.email.value.toLowerCase() : null

    if (email) {
      await actions.addUserToInstance(email, dialogServerId)
    } else if (instanceData.description && instanceData.praxisInhaber) {
      await actions.addInstance(instanceData)
    }

    closeDialog()
  }

  const asyncValidateEmail = throttle(async (email) => {
    let error
    const url = `${getAuthUrl()}/email-registered/${email}`
    try {
      const result = await fetch(url, {
        credentials: 'include',
      })

      if (result.status === 200) {
        error = null
      } else {
        error = UNKNOWN_EMAIL_ERROR
      }
    } catch (err) {
      error = (
        <MessageError message="Netzwerkfehler: E-Mail-Adresse konnte nicht verifiziert werden. Bitte wiederholen!" />
      )
    }
    setEmailError(error)
    return error
  }, 2000)

  const checkEmail = async (data) => {
    if (!data.email.value) {
      actions.showNotification('Bitte E-Mail-Adresse angeben!')
      return false
    }
    if (!isEmail(data.email.value)) {
      actions.showNotification('Bitte eine gültige E-Mail-Adresse angeben!')
      return false
    }
    const message = await asyncValidateEmail(data.email.value)
    if (message) {
      actions.showNotification(message)
      return false
    }
    return true
  }

  const checkPraxis = (data) => {
    if (!data.description.value || !data.praxisInhaber.value) {
      const required = ['description', 'praxisInhaber']
      const translate = {
        description: 'Firmenbezeichnung',
        praxisInhaber: 'Praxisinhaber',
      }
      let notification = ''
      required.forEach((fieldName) => {
        if (!data[fieldName].value) {
          notification += translate[fieldName] + ', '
        }
      })

      if (notification !== '') {
        notification = `Bitte Felder ausfüllen: ${notification}`
        notification = notification.slice(0, -2)
        actions.showNotification(notification)
        return false
      }

      return true
    }
  }

  const checkThenSubmit = async () => {
    if (submitInProgress) return undefined

    setSubmitInProgress(true)
    try {
      const data = fields
      if (addUserDialogOpen && !(await checkEmail(data))) {
        throw new Error('add user error')
      }
      if (addPraxisDialogOpen && !checkPraxis(data)) {
        throw new Error('add praxis error')
      }

      await handleSubmit(addInstanceOrUser(data))
    } catch (err) {
      console.error('error: ', err)
    } finally {
      setSubmitInProgress(false)
    }
  }

  const createInstance = async ({ description }) => {
    const instanceData = {
      description,
      praxisInhaber: '',
    }
    await actions.addInstance(instanceData)
  }

  const submitInstance = (description) => {
    const translate = {
      description: 'Firmenbezeichnung',
    }
    let notificationText = 'Bitte Felder ausfüllen: '

    notificationText += `${translate.description}`
    actions.showNotification(notificationText)

    handleSubmit(createInstance({ description }))
    setSubmitInProgress(false)
  }

  const { description, praxisInhaber, email } = fields
  const { apiServers = [] } = auth

  const userApiServers = useMemo(() => {
    if (!apiServers?.length || !currentUser?.authUserId) {
      return []
    }

    return apiServers.filter((server) => server.createdBy === currentUser.authUserId)
  }, [apiServers, currentUser?.authUserId])

  const AlreadyExist = (
    <span>
      Benutzer nicht vorhanden. Dieser muss sich zuerst bei pododesk registrieren! (
      <a
        href="https://pododesk.freshdesk.com/support/solutions/articles/17000086779-mitarbeiter-zu-einer-praxis-hinzuf%C3%BCgen"
        target="_blank"
        rel="noreferrer"
      >
        Hilfe dazu
      </a>
      )
    </span>
  )

  const openChangelogHistoryDialog = () => {
    setChangelogHistoryDialogOpen(true)
  }

  const closeChangelogHistoryDialog = () => {
    setChangelogHistoryDialogOpen(false)
  }

  const openViewChangelogDialog = (id: number | null) => {
    setViewChangelogId(id)
    setViewChangelogDialogOpen(true)
  }

  const closeViewChangelogDialog = () => {
    if (!viewChangelogId) {
      actions.changelog.updateLastSeen()
    }

    setViewChangelogDialogOpen(false)
    setViewChangelogId(null)
  }

  const closeNoPlanDialog = () => {
    setNoPlanDialogData({
      open: false,
    })
  }

  useEffect(() => {
    console.log('CURRENT USER: ', currentUser)
    console.log('USER API SERVERS: ', userApiServers)
    if (currentUser?.authUserId && !currentUser?.planId) {
      console.log('no plan for user. checking for other practices...')
      if (userApiServers?.length && sessionStorage.getItem('readOnly') !== 'true') {
        setNoPlanDialogData({
          open: true,
          title: 'Kein Planmodell gebucht',
          content: (
            <>
              <Typography variant="body1" gutterBottom>
                Sie haben noch kein Planmodell gebucht.{' '}
                <Link
                  color="secondary"
                  href={
                    process.env.NODE_ENV === 'production'
                      ? 'https://pododesk.de/fuexc_unidy/start'
                      : 'https://staging.pododesk.de/fuexc_unidy/start'
                  }
                >
                  Bitte wählen Sie ein Planmodell
                </Link>
                , um pododesk nutzen zu können.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Um die Daten in Ihren bestehenden Praxen lediglich einzusehen, klicken Sie auf den Button "Lesezugriff".
              </Typography>
            </>
          ),
          showReadOnlyButton: true,
        })
      }

      if (!userApiServers?.length) {
        setNoPlanDialogData({
          open: true,
          title: 'Kein Planmodell gebucht',
          content: (
            <>
              <Typography variant="body1" gutterBottom>
                Sie haben noch kein Planmodell gebucht.{' '}
                <Link
                  color="secondary"
                  href={
                    process.env.NODE_ENV === 'production'
                      ? 'https://pododesk.de/fuexc_unidy/start'
                      : 'https://staging.pododesk.de/fuexc_unidy/start'
                  }
                >
                  Bitte wählen Sie ein Planmodell
                </Link>
                , um pododesk nutzen zu können.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Sie sind nur Mitarbeiter:in? Buchen Sie in diesem Fall bitte das kostenlose Planmodell "free", um Praxen
                anderer Praxisinhaber:innen betreten zu können.
              </Typography>
            </>
          ),
        })
      }
    }
  }, [currentUser, userApiServers])

  return (
    <>
      <Layout>
        <StyledStartbereich>
          <div className="header">
            Startbereich
            <div className="triangle" />
          </div>
          <div className="background">
            <div className="wrapper">
              <div className="cardContainerOuter">
                <Accordion style={{ marginRight: 30, borderRadius: 8 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" color="secondary">
                      {currentPlanData?.id ? <>{currentPlanData?.name}&nbsp;</> : <>Kein Planmodell gebucht</>}
                    </Typography>
                    {!!currentPlanData?.id && <Typography variant="h6">gebucht</Typography>}
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    {currentPlanData?.id ? (
                      <>
                        <Typography variant="body1">
                          Anzahl Patient:innen:{' '}
                          {currentPlanData?.maxNumberOfPatients
                            ? `${currentPlanData.maxNumberOfPatientsPrefix} ${currentPlanData.maxNumberOfPatients} | ${
                                (currentPlanData.maxNumberOfPatients || 0) -
                                calculateTotalPatientsCount(userApiServers || [])
                              } verbleibend`
                            : `unbegrenzt | ${calculateTotalPatientsCount(userApiServers || [])}`}
                        </Typography>
                        <Typography variant="body1">
                          Anzahl Praxen:{' '}
                          {currentPlanData?.maxNumberOfPractices
                            ? `${currentPlanData.maxNumberOfPractices} | ${
                                currentPlanData.maxNumberOfPractices - userApiServers.length
                              } verbleibend`
                            : `unbegrenzt | ${userApiServers.length}`}
                        </Typography>
                        <Typography variant="body1">
                          Support Inklusivminuten pro Monat:{' '}
                          {currentPlanData?.supportTime > 0
                            ? `${currentPlanData.supportTime} Minuten`
                            : String.fromCharCode(8212)}
                        </Typography>
                        <Typography variant="body1">
                          Support Erreichbarkeit: {currentPlanData?.supportReachability}
                        </Typography>
                        <Typography variant="body1">
                          SMS-Erinnerung (optional):{' '}
                          {typeof currentPlanData?.smsPrice !== 'number'
                            ? String.fromCharCode(8212)
                            : `${getCurrencyInEuros(currentPlanData?.smsPrice)}€ / SMS`}
                        </Typography>
                        <Typography variant="body1">
                          Schnittstelle zur Krankenkassen-Abrechnung (optional):{' '}
                          {currentPlanData?.kkInterfaceIncluded ? 'gebucht' : 'nicht gebucht'}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body1">
                        Wählen Sie jetzt ein Planmodell, um pododesk zu nutzen. Alle Infos finden Sie&nbsp;
                        <Link
                          href={
                            process.env.NODE_ENV === 'production'
                              ? 'https://pododesk.de/fuexc_unidy/start'
                              : 'https://staging.pododesk.de/fuexc_unidy/start'
                          }
                          color="secondary"
                        >
                          hier
                        </Link>
                        .
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>

                <div className="cardContainer">
                  <UserCard className="cardContainer__item" user={credentialsUser} title="Herzlich Willkommen!" />
                  {apiServers.map(
                    ({ id, description, praxisInhaber, nameHash, disabled, istAdmin, isWhitelisted, readOnly }) => {
                      const currentPraxisstammdaten = praxisstammdaten[nameHash] || {}
                      const { inhaber = '', firmenbezeichnung = '', logo = '' } = currentPraxisstammdaten
                      return (
                        <PraxisCard
                          className="cardContainer__item"
                          key={id}
                          nameHash={nameHash}
                          image={logo || image}
                          title={!disabled && firmenbezeichnung ? firmenbezeichnung : description}
                          inhaber={!disabled && inhaber ? inhaber : praxisInhaber}
                          disabled={disabled}
                          isWhitelisted={isWhitelisted}
                          loadPraxisstammdatenComplete={loadPraxisstammdatenComplete}
                          noOfPractices={apiServers.length}
                          isAdmin={istAdmin}
                          totalPatientsCreated={totalPatientsCreated}
                          setTotalPatientsCreated={setTotalPatientsCreated}
                          onAddUser={() => {
                            setAddUserDialogOpen(true)
                            setDialogServerId(id)
                          }}
                          readOnly={readOnly}
                        />
                      )
                    },
                  )}
                  {currentPlanData?.id && (
                    <NewPraxisCard
                      className="cardContainer__item"
                      onSubmit={submitInstance}
                      remainingPractices={currentPlanData?.maxNumberOfPractices - userApiServers.length}
                    />
                  )}
                </div>
              </div>

              <div className="infoContainer">
                <Info openChangelogHistoryDialog={openChangelogHistoryDialog} className="infoContainer__item" />
              </div>
              <Link href="https://www.hellmut-ruck.de" style={{ position: 'absolute' }} target="_blank">
                <RuckFlag style={{ height: 60, position: 'fixed', bottom: 0 }} />
              </Link>
            </div>
          </div>
        </StyledStartbereich>

        <Dialog disableBackdropClick onClose={closeDialog} open={addUserDialogOpen}>
          <DialogTitle>Mitarbeiter zur Praxis hinzufügen</DialogTitle>
          <DialogContent>
            <form onSubmit={checkThenSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
              <InputField
                {...email}
                name="email"
                onChange={(value) => {
                  email.onChange(value)
                }}
                onBlur={(value) => {
                  if (value) {
                    asyncValidateEmail(value)
                  }
                  email.onBlur(value)
                }}
                label="E-Mail des pododesk Benutzers"
                error={(email.touched && !!email.error) || (!email.error && !!emailError)}
                helperText={
                  (email.touched && !!email.error && email.error) ||
                  (!email.error && !!emailError
                    ? emailError !== UNKNOWN_EMAIL_ERROR
                      ? emailError
                      : AlreadyExist
                    : undefined) ||
                  undefined
                }
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={closeDialog}>
              Abbrechen
            </Button>
            <Button variant="contained" color="secondary" onClick={checkThenSubmit} disabled={submitInProgress}>
              Speichern
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog disableBackdropClick onClose={closeDialog} open={addPraxisDialogOpen}>
          <DialogTitle>Neue Praxis erstellen</DialogTitle>
          <DialogContent>
            <form onSubmit={checkThenSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
              <InputField
                {...description}
                name="description"
                label="Firmenbezeichnung Ihrer Praxis"
                error={description.touched && !!description.error}
                helperText={description.touched && !!description.error && description.error}
              />
              <InputField
                {...praxisInhaber}
                fullWidth
                name="praxisInhaber"
                label="Name des Praxisinhabers"
                error={praxisInhaber.touched && !!praxisInhaber.error}
                helperText={praxisInhaber.touched && !!praxisInhaber.error && praxisInhaber.error}
              />
            </form>
          </DialogContent>
          <DialogActions>
            {!email.error && emailError === UNKNOWN_EMAIL_ERROR ? (
              <Button variant="contained" color="secondary" onClick={closeDialog}>
                Abbrechen
              </Button>
            ) : (
              <Button variant="contained" color="secondary" onClick={checkThenSubmit} disabled={submitInProgress}>
                Speichern
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Layout>

      <ChangelogHistoryDialog
        onClose={closeChangelogHistoryDialog}
        open={changelogHistoryDialogOpen}
        openViewChangelogDialog={openViewChangelogDialog}
      />
      <ViewChangelogDialog
        open={viewChangelogDialogOpen}
        changelogId={viewChangelogId}
        onClose={closeViewChangelogDialog}
      />
      <NoPlanDialog
        open={noPlanDialogData?.open}
        onCloseFunc={closeNoPlanDialog}
        title={noPlanDialogData?.title}
        content={noPlanDialogData?.content}
        showReadOnlyButton={noPlanDialogData?.showReadOnlyButton}
      />
      <Notification />
    </>
  )
}

const validate = (values) => {
  const required = ['email', 'praxisInhaber', 'description']
  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen!'
    }
  })
  if (values.email && !isEmail(values.email) && !errors.email) {
    errors.email = 'Bitte geben Sie eine gültige E-Mail Adresse ein!'
  }
  return errors
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  praxisstammdaten: praxisstammdatenSelector(state),
  loadPraxisstammdatenComplete: state.initialLoadComplete.praxisstammdaten,
  changelogLastSeen: state.changelog.lastSeen,
  loadingChangelogLastSeen: state.changelog.loadingChangelogLastSeen,
  allChangelogs: state.changelog.allChangelogs,
  currentUser: sCurrentUser(state),
  credentialsUser: state.auth.credentials,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    addInstance: bindActionCreators(addInstance, dispatch),
    addUserToInstance: bindActionCreators(addUserToInstance, dispatch),
    addNewUser: bindActionCreators(addNewUser, dispatch),
    changelog: bindActionCreators(changelogActions, dispatch),
    loadPraxisstammdaten: bindActionCreators(loadPraxisstammdaten, dispatch),
    showNotification: bindActionCreators(showNotification, dispatch),
  },
})

export default reduxForm(
  {
    form: 'createInstanceForm',
    fields: ['description', 'praxisInhaber', 'email'],
    validate,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(Startbereich))
