import {
  Build as BuildIcon,
  Camera as CameraIcon,
  EuroSymbol,
  Home as HomeIcon,
  Lock as LockIcon,
  MeetingRoom as MeetingRoomIcon,
  People as PeopleIcon,
  Schedule as ScheduleIcon,
  ShoppingCart as ShoppingCartIcon,
  Subject as SubjectIcon,
} from '@material-ui/icons'
import { FC, useMemo } from 'react'
import { connect } from 'react-redux'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import Notification from '../../components/Notification/Notification'
import { AbbreviationSettings } from '../../containers/AbbreviationSettings/AbbreviationSettings'
import Arbeitszeiten from '../../containers/Arbeitszeiten/Arbeitszeiten'
import Avatar from '../../containers/Avatar/Avatar'
import EditUsersForm from '../../containers/EditUsersForm/EditUsersForm'
import { EquipmentSettings } from '../../containers/EquipmentSettings/EquipmentSettings'
import LeistungenPrivatForm from '../../containers/LeistungenPrivatForm/LeistungenPrivatForm'
import PraxisstammdatenForm from '../../containers/PraxisstammdatenForm/PraxisstammdatenForm'
import { RoomSettings } from '../../containers/RoomSettings/RoomSettings'
import SicherheitForm from '../../containers/SicherheitForm/SicherheitForm'
import StyledLink from '../../shared/components/StyledLink/StyledLink'
import { isCurrentUserAdmin, sCurrentUser } from '../../shared/utils/users'
import { sTreatmentRoomFeatureEnabled } from '../Calendar/selectors'
import PlanSettings from '../PlanSettings/PlanSettings'
import { StyledEinstellungen } from './StyledEinstellungen'
import { sApiServer } from '../../shared/utils/auth'
import { plan } from '../../shared/utils/constants'

interface Props {
  currentUserIsAdmin: boolean
  treatmentRoomFeatureEnabled: boolean
  currentUser: any
  currentApiServer: ApiServer | null
}

const Einstellungen: FC<Props> = ({
  currentUserIsAdmin,
  treatmentRoomFeatureEnabled,
  currentUser,
  currentApiServer,
  ...props
}: Props): JSX.Element => {
  const { serverHash } = useParams()

  const isPracticeCreatedByCurrentUser = useMemo(() => {
    if (!currentUser?.authUserId || !currentApiServer?.createdBy) {
      return false
    }

    return currentUser.authUserId === currentApiServer.createdBy
  }, [currentUser?.authUserId, currentApiServer?.createdBy])

  return (
    <>
      <StyledEinstellungen>
        <div className="main">
          <div className="topBar">
            <StyledLink
              to={`${serverHash}/settings/praxisstammdaten`}
              fullWidth
              startIcon={<HomeIcon fontSize="inherit" />}
              borderEnd
            >
              Praxisstammdaten
            </StyledLink>
            {/* {currentUserIsAdmin && (
              <StyledLink
                to={`${serverHash}/settings/sicherheit`}
                fullWidth
                startIcon={<LockIcon fontSize="inherit" />}
                borderEnd
              >
                Sicherheit
              </StyledLink>
            )} */}
            {currentUserIsAdmin && (
              <StyledLink to={`${serverHash}/settings/mitarbeiter`} fullWidth startIcon={<PeopleIcon />} borderEnd>
                Mitarbeiter
              </StyledLink>
            )}
            <StyledLink
              to={`${serverHash}/settings/leistungen`}
              fullWidth
              startIcon={<ShoppingCartIcon fontSize="inherit" />}
              borderEnd
            >
              Leistungen
            </StyledLink>
            {treatmentRoomFeatureEnabled && (
              <>
                {currentUserIsAdmin && (
                  <StyledLink
                    to={`${serverHash}/settings/ausstattung`}
                    fullWidth
                    startIcon={<BuildIcon fontSize="inherit" />}
                    borderEnd
                  >
                    Ausstattung
                  </StyledLink>
                )}
                {currentUserIsAdmin && (
                  <StyledLink
                    to={`${serverHash}/settings/behandlungsraum`}
                    fullWidth
                    startIcon={<MeetingRoomIcon fontSize="inherit" />}
                    borderEnd
                  >
                    Behandlungsraum
                  </StyledLink>
                )}
              </>
            )}
            {/* <StyledLink
              to={`${serverHash}/settings/passwort`}
              fullWidth
              startIcon={<LockIcon fontSize="inherit" />}
              borderEnd
            >
              Passwort/PIN
            </StyledLink> */}
            <StyledLink
              to={`${serverHash}/settings/avatar`}
              fullWidth
              startIcon={<CameraIcon fontSize="inherit" />}
              borderEnd
            >
              Avatar
            </StyledLink>
            <StyledLink
              to={`${serverHash}/settings/arbeitszeiten`}
              fullWidth
              startIcon={<ScheduleIcon fontSize="inherit" />}
              borderEnd
            >
              Arbeitszeiten
            </StyledLink>
            {!!props.practicePlanId && plan.find((p) => p.id === props.practicePlanId)?.name !== 'calendar' && (
              <StyledLink
                to={`${serverHash}/settings/abbreviations`}
                fullWidth
                startIcon={<SubjectIcon fontSize="inherit" />}
              >
                Textbausteine
              </StyledLink>
            )}
            {isPracticeCreatedByCurrentUser && !!currentUser?.planId && (
              <StyledLink to={`${serverHash}/settings/plan`} fullWidth startIcon={<EuroSymbol />}>
                Planmodell
              </StyledLink>
            )}
          </div>
          <Routes>
            <Route path="/praxisstammdaten" element={<PraxisstammdatenForm {...props} />} />
            {/* <Route path="/sicherheit" element={<SicherheitForm />} /> */}
            <Route path="/mitarbeiter" element={<EditUsersForm />} />
            <Route path="/leistungen" element={<LeistungenPrivatForm {...props} />} />
            <Route path="/leistungen/:leistungPrivat" element={<LeistungenPrivatForm {...props} />} />
            <Route path="/ausstattung" element={<EquipmentSettings />} />
            <Route path="/behandlungsraum" element={<RoomSettings />} />
            {/* <Route path="/passwort" element={<CredentialsPage />} /> */}
            {/* <Route path="/pin" element={<ChangePin />} /> */}
            <Route path="/avatar" element={<Avatar />} />
            <Route path="/arbeitszeiten" element={<Arbeitszeiten />} />
            <Route path="/abbreviations" element={<AbbreviationSettings />} />
            <Route path="/plan" element={<PlanSettings />} />
            <Route path="*" element={<Navigate replace to="./praxisstammdaten" />} />
          </Routes>
        </div>
      </StyledEinstellungen>
      <Notification />
    </>
  )
}

const mapStateToProps = (state) => ({
  currentUserIsAdmin: isCurrentUserAdmin(state),
  treatmentRoomFeatureEnabled: sTreatmentRoomFeatureEnabled(state),
  currentUser: sCurrentUser(state),
  currentApiServer: sApiServer(state),
})

export default connect(mapStateToProps)(Einstellungen)
