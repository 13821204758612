import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm, stopAsyncValidation } from 'redux-form'
import isEmail from 'validator/lib/isEmail'
import * as smsActions from '../../actions/phoneNumber'
import { IMAGE_MIME_TYPES } from '../../constants/misc'
import FileUpload from '../../containers/FileUpload/FileUpload'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import { AutocompleteField } from '../../shared/components/AutocompleteField'
import CheckboxField from '../../shared/components/CheckboxField'
import DateField from '../../shared/components/DateField/DateField'
import InputField from '../../shared/components/InputField/InputField'
import { SelectFieldOption } from '../../shared/components/SelectField'
import { SelectField } from '../../shared/components/SelectField/SelectField'
import countryCodes from '../../shared/static-data/countryCodes'
import { sortByNachnameVorname } from '../../utils/persons'
import { asyncValidateCustomer, validateDateFieldNotInFuture } from '../../utils/validation'
import { StyledCustomerForm } from './StyledCustomerForm'
import krankenkassen from '../../reducers/krankenkassen'
import { sCurrentUser } from '../../shared/utils/users'
import { sApiServer } from '../../shared/utils/auth'
import { isReadOnly } from '../../utils/helpers'
import { plan } from '../../shared/utils/constants'

export const fields = [
  'id',
  'patientSeit',
  'vorname',
  'nachname',
  'geschlecht',
  'titel',
  'geburtsdatum',
  'strasse',
  'hausnummer',
  'postleitzahl',
  'ort',
  'telefon',
  'mobil',
  'beruf',
  'email',
  'emailErinnerung',
  'smsReminder',
  'krankenkasse',
  'versichertennummer',
  'hausarzt',
  'facharzt',
  'frequenz',
  'empfehlung',
  'bisherigePodologie',
  'abgewandertZu',
  'bild',
  'verstorben',
  'notizen',
  'dateien',
  'sozialeGemeinschaft',
  'land',
  'mobilCountry',
  'patientennummer',
  'regularTherapist',
  'callReminder',
  'versichertenstatus',
  'ikNummerKK',
  'otherInsurance',
]

const emptyPlaceholder = String.fromCharCode(8212)

const titles = [
  { value: 'Dr.', text: 'Dr.' },
  { value: 'Prof.', text: 'Prof.' },
  { value: 'Mag.', text: 'Mag.' },
  { value: 'Prof. Dr.', text: 'Prof. Dr.' },
  { value: 'Dipl. Ing.', text: 'Dipl. Ing.' },
  { value: 'null', text: emptyPlaceholder },
]

const errorText = ''
const errorTextHausarzt = ''
const errorTextFacharzt = ''
const errorTextMobilCountry = ''

const findCountryCallingCode = (countryCode: string): string | null => {
  const selected = countryCodes.find((country: Country) => country.code === countryCode)
  if (selected) {
    return selected.callingCode
  }
  return null
}

const findCountryCode = (callingCodeLike: string | number): Country | null => {
  const country = countryCodes.find((country: Country) => {
    return String(callingCodeLike).replace('+', '') === country.callingCode
  })
  return country || null
}

const findCountryByCountryCode = (countryCode: string) => {
  const country = countryCodes.find((c: Country) => c.code === countryCode)
  return country || null
}

// const filterOptions = createFilterOptions({
//   matchFrom: 'any',
//   stringify: (option: Country) => `${option.code} ${option.name}`,
// });

const CustomerForm: FC<any> = ({
  // autocomplete,
  fields,
  submitText = 'Patientenkartei anlegen',
  busy,
  actions,
  editMode = false,
  doctors,
  berufen,
  krankenkassen,
  onSubmitFunc,
  invalid,
  pristine,
  people,
  submitFailed,
  submitting,
  asyncValidating,
  praxisstammdaten,
  handleSubmit: rfHandleSubmit,
  currentUser,
  currentServer,
}): JSX.Element => {
  const [krankenkassenSelect, setKrankenkassenSelect] = useState<Array<SelectFieldOption>>([])
  const [peopleSelect, setPeopleSelect] = useState<Array<SelectFieldOption>>([])

  const [doctorsAutocompleteItems, setDoctorsAutocompleteItems] = useState<Array<AutocompleteOption>>([])
  // const [berufenAutocompleteItems, setBerufenAutocompleteItems] = useState<Array<AutocompleteOption | undefined>>([]);

  // const [autocompletePatienten, setAutocompletePatienten] =  useState<any>({})
  // const [otherPractices, setOtherPractices] =  useState<Array<AutocompleteOption>>([])

  // const [autocompleteStrasse, setAutocompleteStrasse] = useState<Array<AutocompleteOption>>([]);
  // const [autocompletePostleitzahl, setAutocompletePostleitzahl] = useState<Array<AutocompleteOption>>([]);
  // const [autocompleteOrt, setAutocompleteOrt] = useState<Array<AutocompleteOption>>([]);
  // const [autocompleteSozialeGemeinschaft, setAutocompleteSozialeGemeinschaft] = useState<Array<AutocompleteOption>>([]);
  // const [autocompleteEmpfehlung, setAutocompleteEmpfehlung] = useState<Array<AutocompleteOption>>([]);

  const autocompleteCountryCallingCodes = countryCodes.map((country) => ({
    value: country.callingCode,
    text: `+${country.callingCode}`,
  }))

  // useEffect(() => {
  //   actions.autocomplete.loadAutocomplete({
  //     patienten: [
  //       // 'strasse',
  //       // 'postleitzahl',
  //       // 'ort',
  //       // 'bisherigePodologie',
  //       // 'abgewandertZu',
  //       // 'empfehlung',
  //       // 'sozialeGemeinschaft',
  //       // 'land',
  //       // 'mobilCountry',
  //     ],
  //   });
  // }, []);

  // set krankenkassenSelect
  useEffect(() => {
    const newKrankenkassenSelect = [
      { value: 'null', text: emptyPlaceholder },
      ...(Object.keys(krankenkassen) || []).map((key) => ({
        value: krankenkassen[key].id,
        text: krankenkassen[key].kuerzel,
      })),
    ]
    setKrankenkassenSelect(newKrankenkassenSelect)
  }, [krankenkassen])

  // set peopleSelect
  useEffect(() => {
    const newPeopleSelect: Array<SelectFieldOption> = Object.keys(people)
      .filter((key) => people[key].istTherapeut || parseInt(key, 10) === regularTherapist.value)
      .map((key) => {
        const person = people[key]
        return {
          text: [person.vorname, person.nachname].filter(Boolean).join(' '),
          value: person.id,
        }
      })

    newPeopleSelect.unshift({
      text: emptyPlaceholder,
      value: null,
    })

    setPeopleSelect(newPeopleSelect)
  }, [people])

  // set doctorsAutocompleteItems
  useEffect(() => {
    const newDoctors: Array<AutocompleteOption> = Object.keys(doctors)
      .map((key) => doctors[key])
      .sort(sortByNachnameVorname)
      .map((arzt) => ({
        value: arzt.id,
        text: [arzt.vorname, arzt.nachname].filter(Boolean).join(' '),
      }))
    setDoctorsAutocompleteItems(newDoctors)
  }, [doctors])

  // set autocompletePatienten
  // useEffect(() => {
  //   const newAutocompletePatienten = {}
  //   const currentPatienten = autocomplete?.patienten ?? {}
  //   Object.keys(currentPatienten).forEach(key => {
  //     // in some arrays we have empty string values (got from api)
  //     newAutocompletePatienten[key] = (currentPatienten[key] ?? []).filter(Boolean)
  //   })
  //   setAutocompletePatienten(newAutocompletePatienten)
  // }, [autocomplete])

  const autocompleteCountries = countryCodes.map((country) => ({
    value: country.code,
    text: country.name,
    filterText: `${country.code} ${country.name}`,
  }))

  // set otherPractices
  // useEffect(() => {
  // let newAutocompleteStrasse: AutocompleteOption[] = [];
  // let newAutocompletePostleitzahl: AutocompleteOption[] = [];
  // let newAutocompleteOrt: AutocompleteOption[] = [];
  // let newAutocompleteSozialeGemeinschaft: AutocompleteOption[] = [];
  // let newAutocompleteEmpfehlung: AutocompleteOption[] = [];

  // if(autocompletePatienten?.strasse) {
  //   newAutocompleteStrasse = autocompletePatienten?.strasse.map((value: string) => ({text: value, value}));
  // }
  // if(autocompletePatienten?.postleitzahl) {
  //  newAutocompletePostleitzahl = autocompletePatienten?.postleitzahl.map((value: string) => ({text: value, value}));
  // }
  // if(autocompletePatienten?.ort) {
  //   newAutocompleteOrt = autocompletePatienten?.ort.map((value: string) => ({text: value, value}));
  //  }
  //  if(autocompletePatienten?.sozialeGemeinschaft) {
  //   newAutocompleteSozialeGemeinschaft = autocompletePatienten?.sozialeGemeinschaft.map((value: string) => ({text: value, value}));
  //  }
  //  if(autocompletePatienten?.empfehlung) {
  //   newAutocompleteEmpfehlung = autocompletePatienten?.empfehlung.map((value: string) => ({text: value, value}));
  //  }

  //  const newOtherPractices: Array<AutocompleteOption> = Array.from(
  //   new Set([
  //     ...(autocompletePatienten.bisherigePodologie ?? []),
  //     ...(autocompletePatienten.abgewandertZu ?? [])
  //   ])
  //  ).map(value => ({text: value, value}));

  // ---
  // setAutocompleteStrasse(newAutocompleteStrasse)
  // setAutocompletePostleitzahl(newAutocompletePostleitzahl)
  // setAutocompleteOrt(newAutocompleteOrt)
  // setAutocompleteSozialeGemeinschaft(newAutocompleteSozialeGemeinschaft);
  // setAutocompleteEmpfehlung(newAutocompleteEmpfehlung);
  // ---
  // setOtherPractices(newOtherPractices)
  // }, [autocompletePatienten])

  useEffect(() => {
    const germanCode = '49'
    if (fields.mobilCountry.value) {
      return
    }

    const prefillByCode = fields.land.value || praxisstammdaten.land
    const code = prefillByCode ? findCountryCallingCode(prefillByCode) : germanCode

    fields.mobilCountry.onChange(`+${code}`)
  }, [])

  useEffect(() => {
    if (fields.krankenkasse.value !== 16) {
      fields.otherInsurance.onChange(null)
    }
  }, [fields.krankenkasse.value])

  const _handleSubmit = useCallback(
    (event, funcOrEvent) => {
      const handleSubmit = rfHandleSubmit(funcOrEvent)
      handleSubmit(event)
    },
    [rfHandleSubmit],
  )

  const onSubmit = useCallback(
    async (data) => {
      if (submitting || asyncValidating) return
      await onSubmitFunc(data)
    },
    [onSubmitFunc, submitting, asyncValidating],
  )

  const {
    patientSeit,
    vorname,
    nachname,
    geschlecht,
    titel,
    geburtsdatum,
    strasse,
    hausnummer,
    postleitzahl,
    ort,
    telefon,
    mobil,
    beruf,
    email,
    emailErinnerung,
    smsReminder,
    krankenkasse,
    versichertennummer,
    hausarzt,
    facharzt,
    frequenz,
    bild,
    verstorben,
    notizen,
    land,
    sozialeGemeinschaft,
    mobilCountry,
    patientennummer,
    regularTherapist,
    callReminder,
    versichertenstatus,
    ikNummerKK,
    empfehlung,
    bisherigePodologie,
    abgewandertZu,
    otherInsurance,
  } = fields

  // set berufAutocompleteItems
  // useEffect(() => {
  //   if (berufen) {
  //     const newBeruf: Array<AutocompleteOption> = Object.keys(berufen)
  //       .map((key) => berufen[key])
  //       .filter(item => item.beruf !== null && beruf?.value !== item.beruf)
  //       .map((arzt) => ({
  //         value: arzt.id,
  //         text: arzt.beruf
  //       }));

  //     const uniqueFields = Array.from(new Set(newBeruf.map(ber => ber.text))).map(txt => {
  //       return newBeruf.find(ber => ber.text === txt)
  //     })

  //     setBerufenAutocompleteItems(uniqueFields)
  //   }
  // }, [berufen, beruf]);

  return (
    <StyledCustomerForm onSubmit={(e) => _handleSubmit(e, onSubmit)} autoComplete="off">
      <div className="fieldContainer">
        <div className="fieldGroup">
          <DateField
            {...patientSeit}
            onChange={(date) => {
              patientSeit.onChange(date)
              patientSeit.onBlur(date)
            }}
            helperText={!!patientSeit.touched && !!patientSeit.error ? patientSeit.error : ''}
            error={!!patientSeit.touched && !!patientSeit.error}
            label="Patient seit *"
          />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Geschlecht"
              name="gender"
              row={true}
              onChange={(event) => {
                geschlecht.onChange(event.target.value)
              }}
              value={geschlecht.value || ''}
            >
              <FormControlLabel value="female" control={<Radio />} label="weiblich" />
              <FormControlLabel value="male" control={<Radio />} label="männlich" />
              <FormControlLabel value="trans" control={<Radio />} label="divers" />
            </RadioGroup>
            {geschlecht.error && geschlecht.touched ? <FormHelperText error> {geschlecht.error}</FormHelperText> : ''}
          </FormControl>
          <InputField
            {...patientennummer}
            label="Patienten-Nr. (optional)"
            helperText={!!patientennummer.touched && !!patientennummer.error ? patientennummer.error : ''}
            error={!!patientennummer.touched && !!patientennummer.error}
            InputProps={{ autoComplete: 'off' }}
            onKeyUp={() => actions.form.clearAsyncErrors()}
          />

          <SelectField {...titel} label="akad. Titel" options={titles} />

          <InputField
            {...nachname}
            label="Nachname (*)"
            helperText={!!nachname.touched && !!nachname.error ? nachname.error : ''}
            error={!!nachname.touched && !!nachname.error}
            onKeyUp={() => actions.form.clearAsyncErrors()}
            InputProps={{ autoComplete: 'off' }}
          />
          <InputField
            {...vorname}
            label="Vorname (*)"
            helperText={!!vorname.touched && !!vorname.error ? vorname.error : ''}
            error={!!vorname.touched && !!vorname.error}
            InputProps={{ autoComplete: 'off' }}
            onKeyUp={() => actions.form.clearAsyncErrors()}
          />
          <DateField
            {...geburtsdatum}
            label="Geburtsdatum"
            disableFuture
            helperText={!!geburtsdatum.touched && !!geburtsdatum.error ? geburtsdatum.error : ''}
            error={!!geburtsdatum.touched && !!geburtsdatum.error}
            fullWidth
            onKeyUp={() => actions.form.clearAsyncErrors()}
            onChange={(date) => {
              geburtsdatum.onChange(date)
              geburtsdatum.onBlur(date)
            }}
          />

          <InputField
            {...beruf}
            label="Beruf"
            autoComplete="new-password"
            error={beruf.touched && !!beruf.error}
            helperText={beruf.touched && !!beruf.error ? beruf.error : ''}
          />
          {/* <AutocompleteField
            {...beruf}
            label="Beruf"
            className="autoComplete"
            options={berufenAutocompleteItems}
            error={!!beruf.touched && !!beruf.error}
            helperText={!!beruf.touched && !!beruf.error ? beruf.error : ''}
            freeSolo
            autoSelect
          /> */}
          <label>Foto des Patienten:</label>
          <FileUpload
            onChange={bild.onChange}
            value={bild.value}
            multiple={false}
            mimetypes={IMAGE_MIME_TYPES}
            errorText={bild.touched && bild.error ? bild.error : null}
          />
        </div>
        <div className="fieldGroup">
          <div className="streetGroup">
            <InputField
              {...strasse}
              label="Straße"
              autoComplete="new-password"
              error={strasse.touched && !!strasse.error}
              helperText={strasse.touched && !!strasse.error ? strasse.error : ''}
            />
            {/* <AutocompleteField
              {...strasse}
              label="Straße"
              autoComplete={'new-password'}
              options={autocompleteStrasse}
              error={!!strasse.touched && !!strasse.error}
              helperText={!!strasse.touched && !!strasse.error ? strasse.error : ''}
              freeSolo
              autoSelect
            /> */}

            <InputField
              {...hausnummer}
              label="Nr."
              autoComplete="new-password"
              error={hausnummer.touched && !!hausnummer.error}
              helperText={hausnummer.touched && !!hausnummer.error ? hausnummer.error : ''}
            />
          </div>
          <div className="addressGroup">
            <InputField
              {...postleitzahl}
              label="PLZ"
              autoComplete="new-password"
              error={postleitzahl.touched && !!postleitzahl.error}
              helperText={postleitzahl.touched && !!postleitzahl.error ? postleitzahl.error : ''}
            />
            {/* <AutocompleteField
              {...postleitzahl}
              label="PLZ"
              autoComplete={'new-password'}
              options={autocompletePostleitzahl}
              error={!!postleitzahl.touched && !!postleitzahl.error}
              helperText={!!postleitzahl.touched && !!postleitzahl.error ? postleitzahl.error : ''}
              freeSolo
              autoSelect
            /> */}
            <InputField
              {...ort}
              label="Ort"
              autoComplete="new-password"
              error={ort.touched && !!ort.error}
              helperText={ort.touched && !!ort.error ? ort.error : ''}
            />
            {/* <AutocompleteField
              {...ort}
              label="Ort"
              autoComplete={'new-password'}
              options={autocompleteOrt}
              error={!!ort.touched && !!ort.error}
              helperText={!!ort.touched && !!ort.error ? ort.error : ''}
              freeSolo
              autoSelect
            /> */}
          </div>
          {/* <Autocomplete
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Land"
                  error={!!land.touched && !!land.error}
                  helperText={!!land.touched && !!land.error && land.error}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
              options={countryCodes}
              getOptionLabel={(option: any) => option?.name}
              getOptionSelected={(option: any) => option?.code === land.value}
              value={land.value ? findCountryByCountryCode(land.value) : null}
              blurOnSelect
              onChange={(e, val: any) => {
                if (val) {
                  land.onChange(val.code);
                  mobilCountry.onChange(`+${val.callingCode}`);
                } else {
                  land.onChange(null);
                }
              }}
              filterOptions={filterOptions}
            /> */}

          <AutocompleteField
            {...land}
            label="Land"
            autoComplete={'new-password'}
            error={!!land.touched && !!land.error}
            helperText={!!land.touched && !!land.error && land.error}
            options={autocompleteCountries}
            onChange={(val: string) => {
              const currentCountry = countryCodes.find((country) => country.code === String(val))
              if (currentCountry) {
                land.onChange(currentCountry.code)
                mobilCountry.onChange(currentCountry.callingCode)
              } else {
                land.onChange(null)
              }
            }}
            blurOnSelect
          />

          <InputField
            {...telefon}
            label={callReminder.value ? 'Telefon *' : 'Telefon (*)'}
            InputProps={{ autoComplete: 'off' }}
            helperText={!!telefon.touched && !!telefon.invalid && !!telefon.error ? telefon.error : ''}
            error={!!telefon.touched && !!telefon.invalid && !!telefon.error}
          />

          <InputField
            {...mobil}
            label={smsReminder.value ? 'Mobil *' : 'Mobil (*)'}
            InputProps={{ autoComplete: 'off' }}
            helperText={!!mobil.touched && !!mobil.invalid && !!mobil.error ? mobil.error : ''}
            error={!!mobil.touched && !!mobil.invalid && !!mobil.error}
          />
          {/* <InputField
            {...mobilCountry}
            helperText={mobilCountry.touched && !!mobilCountry.error && mobilCountry.error}
            error={mobilCountry.touched && !!mobilCountry.error}
            onBlur={(e) => {
              if (e.target.value) {
                const country = findCountryCode(e.target.value)
                if (!land.value && country) {
                  land.onChange(country)
                }
              }
            }}
            label={`${smsReminder.value || callReminder.value ? 'Ländercode *' : 'Ländercode (*)'}`}
            InputProps={{ autoComplete: 'off' }}
          /> */}
          <AutocompleteField
            {...mobilCountry}
            label={`${smsReminder.value || callReminder.value ? 'Ländervorwahl *' : 'Ländervorwahl (*)'}`}
            autoComplete={'new-password'}
            options={autocompleteCountryCallingCodes}
            error={!!mobilCountry.error}
            helperText={!!mobilCountry.error && mobilCountry.error}
            onChange={(val: string) => {
              const currentCountry = countryCodes.find((country) => country.callingCode === String(val))
              if (currentCountry) {
                mobilCountry.onChange(currentCountry.callingCode)
                land.onChange(currentCountry.code)
              } else {
                mobilCountry.onChange(null)
              }
            }}
          />

          <InputField
            {...email}
            label="E-Mail"
            helperText={!!email.touched && !!email.error ? email.error : ''}
            error={!!email.touched && !!email.error}
            InputProps={{ autoComplete: 'off' }}
          />

          <div className="checkboxGroup">
            <CheckboxField
              {...smsReminder}
              label="Terminerinnerung per SMS"
              onChange={(e) => {
                actions.form.clearAsyncErrors()
                smsReminder.onChange(e)
                smsReminder.onBlur(e)
              }}
            />
            <CheckboxField
              {...callReminder}
              label="Terminerinnerung per Festnetz-Anruf"
              onChange={(e) => {
                actions.form.clearAsyncErrors()
                callReminder.onChange(e)
                callReminder.onBlur(e)
              }}
            />
            <CheckboxField {...emailErinnerung} label="Terminerinnerung per E-Mail" />
          </div>
        </div>
        <div className="fieldGroup">
          {!!currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name !== 'calendar' && (
            <InputField
              {...sozialeGemeinschaft}
              label="Soziale Gemeinschaft"
              placeholder="z.B. Krankenhaus oder Seniorenheim"
              autoComplete="new-password"
              error={sozialeGemeinschaft.touched && !!sozialeGemeinschaft.error}
              helperText={sozialeGemeinschaft.touched && !!sozialeGemeinschaft.error ? sozialeGemeinschaft.error : ''}
            />
          )}
          {/* <AutocompleteField
            {...sozialeGemeinschaft}
            label="Soziale Gemeinschaft"
            placeholder="z.B. Krankenhaus oder Seniorenheim"
            autoComplete={'new-password'}
            options={autocompleteSozialeGemeinschaft}
            error={!!sozialeGemeinschaft.touched && !!sozialeGemeinschaft.error}
            helperText={!!sozialeGemeinschaft.touched && !!sozialeGemeinschaft.error ? sozialeGemeinschaft.error : ''}
            freeSolo
            autoSelect
          /> */}

          {!!currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name !== 'calendar' && (
            <SelectField
              {...krankenkasse}
              fullWidth
              label="Krankenkasse"
              SelectProps={{ MenuProps: { PaperProps: { backgroundColor: '#fff', maxHeight: 200 } } }}
              options={krankenkassenSelect}
            />
          )}

          {krankenkasse.value === 16 && (
            <InputField
              {...otherInsurance}
              label="Sonstige Kostenträger *"
              error={!!otherInsurance.touched && !!otherInsurance.error}
              helperText={!!otherInsurance.touched && !!otherInsurance.error ? otherInsurance.error : ''}
              InputProps={{ autoComplete: 'off' }}
            />
          )}

          {!!currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name !== 'calendar' && (
            <InputField
              {...ikNummerKK}
              label="IK-Nr. Krankenkasse"
              error={!!ikNummerKK.touched && !!ikNummerKK.error}
              helperText={!!ikNummerKK.touched && !!ikNummerKK.error ? ikNummerKK.error : ''}
              InputProps={{ autoComplete: 'off' }}
            />
          )}

          {!!currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name !== 'calendar' && (
            <InputField
              {...versichertennummer}
              label="Versicherten-Nr."
              helperText={!!versichertennummer.touched && !!versichertennummer.error ? versichertennummer.error : ''}
              error={!!versichertennummer.touched && !!versichertennummer.error}
              InputProps={{ autoComplete: 'off' }}
            />
          )}

          {!!currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name !== 'calendar' && (
            <InputField
              {...versichertenstatus}
              label="Versichertenstatus"
              error={!!versichertenstatus.touched && !!versichertenstatus.error}
              helperText={!!versichertenstatus.touched && !!versichertenstatus.error ? versichertenstatus.error : ''}
              InputProps={{ autoComplete: 'off' }}
            />
          )}
          <AutocompleteField
            {...hausarzt}
            label="Hausarzt"
            autoComplete={'new-password'}
            options={doctorsAutocompleteItems}
            error={!!hausarzt.touched && !!hausarzt.error}
            helperText={!!hausarzt.touched && !!hausarzt.error ? hausarzt.error : ''}
          />
          <AutocompleteField
            {...facharzt}
            label="Facharzt"
            autoComplete={'new-password'}
            options={doctorsAutocompleteItems}
            error={!!facharzt.touched && !!facharzt.error}
            helperText={!!facharzt.touched && !!facharzt.error ? facharzt.error : ''}
          />

          <InputField
            {...frequenz}
            label="gewünschte min. Frequenz"
            helperText={!!frequenz.touched && !!frequenz.error ? frequenz.error : ''}
            placeholder="Wochen als ganze Zahl"
            error={!!frequenz.touched && !!frequenz.error}
            InputProps={{ autoComplete: 'off' }}
          />

          <SelectField {...regularTherapist} label="Stammtherapeut" options={peopleSelect} />

          <InputField
            {...empfehlung}
            label="empfohlen/geworben von"
            placeholder="falls bekannt"
            autoComplete="new-password"
            error={empfehlung.touched && !!empfehlung.error}
            helperText={empfehlung.touched && !!empfehlung.error ? empfehlung.error : ''}
          />
          {/* <AutocompleteField
            {...empfehlung}
            label="empfohlen/geworben von"
            placeholder="falls bekannt"
            autoComplete={'new-password'}
            options={autocompleteEmpfehlung}
            error={!!empfehlung.touched && !!empfehlung.error}
            helperText={!!empfehlung.touched && !!empfehlung.error ? empfehlung.error : ''}
            freeSolo
            autoSelect
          /> */}

          <InputField
            {...bisherigePodologie}
            label="bisherige Podologie Praxis"
            autoComplete="new-password"
            error={bisherigePodologie.touched && !!bisherigePodologie.error}
            helperText={bisherigePodologie.touched && !!bisherigePodologie.error ? bisherigePodologie.error : ''}
          />
          {/* <AutocompleteField
            {...bisherigePodologie}
            label="bisherige Podologie Praxis"
            autoComplete={'new-password'}
            options={otherPractices}
            error={!!bisherigePodologie.touched && !!bisherigePodologie.error}
            helperText={!!bisherigePodologie.touched && !!bisherigePodologie.error ? bisherigePodologie.error : ''}
            freeSolo
            autoSelect
          /> */}

          {editMode && (
            <InputField
              {...abgewandertZu}
              label="abgewandert zu"
              placeholder="wird bei Terminvereinbarung eingeblendet"
              error={!!abgewandertZu.touched && !!abgewandertZu.error}
              helperText={!!abgewandertZu?.touched && !!abgewandertZu?.error && abgewandertZu.error}
              redHelperText={
                !!abgewandertZu?.value &&
                !abgewandertZu?.error &&
                'Bei Speicherung der Kartei werden alle künftigen Termine abgesagt!'
              }
              freeSolo
              autoSelect
            />
          )}
        </div>
      </div>
      <div className="notesGroup">
        <InputField
          {...notizen}
          label="Notizen"
          placeholder="allgemeine Notizen zum Patienten (nicht Anamnese und Dokumentation)"
          multiline
          rows={''}
          InputProps={{ autoComplete: 'off' }}
        />
        {editMode && <CheckboxField {...verstorben} label="Patient verstorben?" />}
        {verstorben?.error ? (
          <FormHelperText error>{verstorben.error}</FormHelperText>
        ) : verstorben?.checked ? (
          <FormHelperText error>Bei Speicherung werden alle zukünftigen Termine abgesagt!</FormHelperText>
        ) : null}
        <div>
          <Typography variant="caption">* sofortige Pflichtfelder, (*) spätere Pflichtfelder</Typography>
          &nbsp;
          <a
            className="supportLink"
            href="https://pododesk.freshdesk.com/support/solutions/articles/17000086739-pflichtfelder-in-der-pododesk-patientenkartei"
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="caption">Was bedeutet das?</Typography>
          </a>
        </div>
        <Divider className="formDivider" variant="fullWidth" />
        {!isReadOnly() && (
          <Button
            className="submitButton"
            variant="contained"
            color="secondary"
            type="submit"
            disabled={busy || (invalid && submitFailed) || pristine || submitting || asyncValidating}
            fullWidth
          >
            {submitText}
          </Button>
        )}
      </div>
    </StyledCustomerForm>
  )
}

const validate = (values, props) => {
  const requiredFields = ['nachname', 'geschlecht', 'patientSeit']
  const errors: KeyValue<string | undefined> = {}
  const { emailErinnerung } = values

  if (emailErinnerung) {
    requiredFields.push('email')
  }

  if (values.krankenkasse === 16) {
    requiredFields.push('otherInsurance')
  }

  requiredFields.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen!'
    }
  })

  if (values.smsReminder && !values.mobilCountry) {
    errors.mobilCountry = 'Bitte ausfüllen!'
  }
  if (values.smsReminder && !values.mobil) {
    errors.mobil = 'Bitte ausfüllen!'
  }
  if (values.callReminder && !values.mobilCountry) {
    errors.mobilCountry = 'Bitte ausfüllen!'
  }
  if (values.callReminder && !values.telefon) {
    errors.telefon = 'Bitte ausfüllen!'
  }
  if (values.frequenz && !/^\d+$/.test(values.frequenz)) {
    errors.frequenz = 'Bitte ganze Zahl eingeben!'
  }

  if (values.email && !errors.email && !isEmail(values.email)) {
    errors.email = 'Bitte gültige E-Mail Adresse eingeben!'
  }

  if (
    values.versichertennummer &&
    !/^[A-Z]{0,1}[0-9]{8,9}$/.test(values.versichertennummer) &&
    values.krankenkasse !== 1 &&
    values.krankenkasse !== 15
  ) {
    errors.versichertennummer = 'Bitte gültige Versicherten-Nr. eingeben!'
  }

  if (values.versichertenstatus && values.versichertenstatus.length > 7) {
    errors.versichertenstatus = 'Max. 7 Zeichen erlaubt!'
  }
  errors.land = errorText.trim() || undefined

  errors.hausarzt = errorTextHausarzt.trim() || undefined
  errors.facharzt = errorTextFacharzt.trim() || undefined
  errors.mobilCountry = errorTextMobilCountry.trim() || undefined

  if (values.mobilCountry) {
    const code = values.mobilCountry.toString().replace('+', '')
    const isValid = countryCodes.some((country) => country.callingCode === code)
    if (!isValid) {
      errors.mobilCountry = 'Bitte gültigen Ländervorwahl eingeben!'
    }
  }

  return {
    ...errors,
    ...validateDateFieldNotInFuture(values, props, ['geburtsdatum', 'patientSeit']),
  }
}

const FORM_NAME = 'customerForm'

const mapStateToProps = (state) => ({
  // autocomplete: state.autocomplete,
  busy: state.notification.busy,
  krankenkassen: state.krankenkassen,
  patienten: state.entities.patienten,
  praxisstammdaten: praxisstammdatenSelector(state),
  people: state.entities.users,
  berufen: state.entities.berufen,
  currentUser: sCurrentUser(state),
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    // autocomplete: bindActionCreators(autocompleteActions, dispatch),
    sms: bindActionCreators(smsActions, dispatch),
    form: {
      clearAsyncErrors: () => dispatch(stopAsyncValidation(FORM_NAME, { clear: true })),
    },
  },
})

const CustomerFormWithRedux = reduxForm(
  {
    form: FORM_NAME,
    validate,
    asyncValidate: asyncValidateCustomer,
    asyncBlurFields: ['mobil', 'mobilCountry', 'smsReminder', 'callReminder'],
    fields,
  },
  mapStateToProps,
  mapDispatchToProps,
)(CustomerForm)

export default (props) => {
  const params = useParams()
  return <CustomerFormWithRedux params={params} {...props} />
}
