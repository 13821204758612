import { Button, FormControlLabel, Radio, RadioGroup, Slider, Switch, Typography } from '@material-ui/core'
import { Home as HomeIcon } from '@material-ui/icons'
import { memo, useCallback, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import isEmail from 'validator/lib/isEmail'
import isURL from 'validator/lib/isURL'
import * as praxisstammdatenActions from '../../actions/praxisstammdaten'
import ColorField from '../../components/ColorField'
import Zeiten from '../../components/Zeiten'
import { isCallFeatureEnabled, isSMSFeatureEnabled, praxisstammdatenSelector } from '../../selectors/selectors'
import CheckboxField from '../../shared/components/CheckboxField/CheckboxField'
import FormDivider from '../../shared/components/FormDivider'
import IconHeading from '../../shared/components/IconHeading'
import InputField from '../../shared/components/InputField'
import { SelectField } from '../../shared/components/SelectField/SelectField'
import countryCodes from '../../shared/static-data/countryCodes'
import { sCurrentUser } from '../../shared/utils/users'
import { handleScrollToInvalidInput } from '../../utils/form'
import { validatePracticePhoneNumber } from '../../utils/validation'
import LogoCropper from './LogoCropper'
import { StyledPraxisstammdatenForm } from './StyledPraxisstammdatenForm'
import { plan } from '../../shared/utils/constants'

export const fields = [
  'logo',
  'firmenbezeichnung',
  'kassenzulassung',
  'inhaber',
  'strasse',
  'hausnummer',
  'postleitzahl',
  'ort',
  'bundesland',
  'angeschlossenAn',
  'telefon',
  'fax',
  'mobil',
  'email',
  'website',
  'oeffnungszeiten',
  'institutionskennzeichen',
  'iban',
  'bic',
  'bank',
  'paypalEmail',
  'currency',
  'standardBehandlungsdauer',
  'terminraster',
  'terminFindenDeaktiviert',
  'icsSources[].bezeichnung',
  'icsSources[].kalenderURL',
  'icsSources[].farbe',
  'icsSources[].termineErzeugenFarbschleier',
  'icsSources[].id',
  'autoLockActive',
  'autoLockTimeout',
  'isAppointmentReminderEnabled',
  'emailReminderNote',
  'appointmentRemindersSchedule',
  'smsReminder',
  'smsSchedule',
  'callReminder',
  'countryCode',
  'arniCustomer',
  'callReminderSchedule',
  'treatmentRoomFeature',
  'roomSelectionMandatory',
]

let errorTextCountryCode = ''

const findCountryCode = (countryCode: string): Country | undefined => {
  return countryCodes.find((country) => {
    return String(countryCode).replace('+', '') === country.callingCode
  })
}

const PraxisstammdatenForm = ({
  actions,
  praxisstammdaten,
  bundeslaender,
  fields,
  handleSubmit,
  smsFeatureEnabled,
  callFeatureEnabled,
  currentUser,
  errors,
  practicePlanId,
}) => {
  const firmenbezeichnungRef = useRef(null)
  const institutionskennzeichenRef = useRef(null)
  const telefonRef = useRef(null)
  const bezeichnungRef = useRef(null)
  const kalenderURLRef = useRef(null)
  const standardBehandlungsdauerRef = useRef(null)
  const inhaberRef = useRef(null)
  const countryCodeRef = useRef()
  const emailRef = useRef(null)
  const websiteRef = useRef(null)
  const terminrasterRef = useRef(null)

  const refMap = {
    firmenbezeichnung: firmenbezeichnungRef,
    institutionskennzeichen: institutionskennzeichenRef,
    telefon: telefonRef,
    bezeichnung: bezeichnungRef,
    kalenderURL: kalenderURLRef,
    standardBehandlungsdauer: standardBehandlungsdauerRef,
    inhaber: inhaberRef,
    countryCode: countryCodeRef,
    email: emailRef,
    website: websiteRef,
    terminraster: terminrasterRef,
  }

  const onSubmit = useCallback(
    (data) => {
      data.bundesland = data.bundesland === '' ? null : data.bundesland
      actions.praxisstammdaten.updatePraxisstammdaten(data)
    },
    [actions.praxisstammdaten.updatePraxisstammdaten],
  )

  const {
    logo,
    firmenbezeichnung,
    kassenzulassung,
    inhaber,
    strasse,
    hausnummer,
    postleitzahl,
    ort,
    angeschlossenAn,
    telefon,
    fax,
    mobil,
    email,
    website,
    oeffnungszeiten,
    institutionskennzeichen,
    iban,
    bic,
    bank,
    paypalEmail,
    currency,
    standardBehandlungsdauer,
    terminraster,
    terminFindenDeaktiviert,
    isAppointmentReminderEnabled,
    emailReminderNote,
    icsSources,
    appointmentRemindersSchedule,
    smsReminder,
    smsSchedule,
    callReminder,
    countryCode,
    bundesland,
    arniCustomer,
    callReminderSchedule,
    treatmentRoomFeature,
    roomSelectionMandatory,
  } = fields

  const isArniCustomer = arniCustomer?.value ? ' *' : ''

  const emptyPlaceholder = String.fromCharCode(8212) // Gedankenstrich: —
  const bundeslaenderSelect = [{ value: NaN, text: emptyPlaceholder }].concat(
    Object.keys(bundeslaender).map((key) => ({
      value: bundeslaender[key].id,
      text: bundeslaender[key].name,
    })),
  )

  if (!praxisstammdaten) {
    return null
  }

  return (
    <>
      <IconHeading icon={<HomeIcon />} text="Stammdaten der Praxis" />
      <StyledPraxisstammdatenForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="gridContainer">
          <div className="formGroup">
            <LogoCropper onChange={logo.onChange} value={logo.value} />

            <InputField
              ref={firmenbezeichnungRef}
              {...firmenbezeichnung}
              label="Firmenbezeichnung *"
              error={firmenbezeichnung.touched && !!firmenbezeichnung.error}
              helperText={firmenbezeichnung.touched && !!firmenbezeichnung.error ? firmenbezeichnung.error : ''}
            />
            <InputField
              ref={inhaberRef}
              {...inhaber}
              readOnly={true}
              label="Inhaber (kann nur vom Support geändert werden)"
              // errorText={inhaber.touched && inhaber.error}
            />
            <InputField
              {...angeschlossenAn}
              label="angeschlossen an"
              placeholder="ggf. Klinik/Heim/etc."
              error={angeschlossenAn.touched && !!angeschlossenAn.error}
              helperText={angeschlossenAn.touched && !!angeschlossenAn.error ? angeschlossenAn.error : ''}
            />
            {!!practicePlanId && plan.find((p) => p.id === practicePlanId)?.name === 'calendar' && (
              <>
                <CheckboxField {...kassenzulassung} label="Kassenzulassung" />

                {kassenzulassung.value && (
                  <>
                    <CheckboxField
                      {...arniCustomer}
                      label={
                        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          Abrechnung via ARNI
                        </span>
                      }
                    />
                    <div
                      style={{
                        marginLeft: 40,
                        marginTop: '-15px',
                        fontSize: 'small',
                      }}
                    >
                      (
                      <a href="https://pododesk.de" target="_blank" rel="noreferrer">
                        Was ist das?
                      </a>
                      )
                    </div>
                    <InputField
                      ref={institutionskennzeichenRef}
                      {...institutionskennzeichen}
                      label={`Institutionskennzeichen${isArniCustomer}`}
                      placeholder="IK Nummer"
                      error={institutionskennzeichen.touched && !!institutionskennzeichen.error}
                      helperText={
                        institutionskennzeichen.touched && !!institutionskennzeichen.error
                          ? institutionskennzeichen.error
                          : ''
                      }
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className="formGroup">
            <div className="streetGroup">
              <InputField
                {...strasse}
                label="Straße"
                error={strasse.touched && !!strasse.error}
                helperText={strasse.touched && !!strasse.error ? strasse.error : ''}
              />
              <InputField
                {...hausnummer}
                label="Haus-Nr."
                error={hausnummer.touched && !!hausnummer.error}
                helperText={hausnummer.touched && !!hausnummer.error ? hausnummer.error : ''}
              />
            </div>
            <div className="addressGroup">
              <InputField
                {...postleitzahl}
                label="PLZ"
                error={postleitzahl.touched && !!postleitzahl.error}
                helperText={postleitzahl.touched && !!postleitzahl.error ? postleitzahl.error : ''}
              />
              <InputField
                {...ort}
                label="Ort"
                error={ort.touched && !!ort.error}
                helperText={ort.touched && !!ort.error ? ort.error : ''}
              />
            </div>
            <SelectField {...bundesland} fullWidth label="Bundesland" options={bundeslaenderSelect} />
            <InputField
              {...countryCode}
              inputRef={countryCodeRef}
              label={callReminder.value ? 'Ländervorwahl *' : 'Ländervorwahl (*)'}
              error={countryCode.touched && !!countryCode.error}
              helperText={countryCode.touched && !!countryCode.error ? countryCode.error : ''}
              onBlur={(e) => {
                countryCode.onBlur(e)
                const country = findCountryCode(String(countryCode.value))

                if (country && countryCode.value) {
                  errorTextCountryCode = ''
                  countryCode.onChange(`+${country.callingCode}`)
                }
                if (!country && countryCode.value) {
                  errorTextCountryCode = 'Bitte gültigen Ländervorwahl eingeben!'
                }
              }}
            />
            <InputField
              ref={telefonRef}
              {...telefon}
              label={callReminder.value ? 'Telefon *' : 'Telefon (*)'}
              error={telefon.touched && !!telefon.error}
              helperText={telefon.touched && !!telefon.error ? telefon.error : ''}
            />
            <InputField
              {...fax}
              label="Fax"
              error={fax.touched && !!fax.error}
              helperText={fax.touched && !!fax.error ? fax.error : ''}
            />
            <InputField
              {...mobil}
              label="Mobil"
              error={mobil.touched && !!mobil.error}
              helperText={mobil.touched && !!mobil.error ? mobil.error : ''}
            />
            <InputField
              ref={emailRef}
              {...email}
              label="E-Mail"
              error={email.touched && !!email.error}
              helperText={email.touched && !!email.error ? email.error : ''}
            />
            <InputField
              ref={websiteRef}
              {...website}
              label="Website"
              error={website.touched && !!website.error}
              helperText={website.touched && !!website.error ? website.error : ''}
            />
            <InputField
              {...bank}
              label="Bank"
              error={bank.touched && !!bank.error}
              helperText={bank.touched && !!bank.error ? bank.error : ''}
            />
            <InputField
              {...iban}
              label="IBAN"
              error={iban.touched && !!iban.error}
              helperText={iban.touched && !!iban.error ? iban.error : ''}
            />
            <InputField
              {...bic}
              label="BIC"
              error={bic.touched && !!bic.error}
              helperText={bic.touched && !!bic.error ? bic.error : ''}
            />
            <InputField
              {...paypalEmail}
              label="PayPal Adresse"
              error={paypalEmail.touched && !!paypalEmail.error}
              helperText={paypalEmail.touched && !!paypalEmail.error ? paypalEmail.error : ''}
            />
            <SelectField
              {...currency}
              label="Währung"
              error={currency.touched && !!currency.error}
              helperText={currency.touched && !!currency.error ? currency.error : ''}
              options={[
                {
                  value: 'EUR',
                  text: '€ | Euro',
                },
                {
                  value: 'CHF',
                  text: 'CHF | Schweizer Franken',
                },
              ]}
            />
          </div>
        </div>

        <FormDivider />

        <div>
          <div>
            <Typography variant="h5" paragraph>
              Kalendereinstellungen
            </Typography>
            <Typography variant="h6">
              <span className="subHeader">Externe Kalender für alle Benutzer einbinden</span>
            </Typography>
            <Typography variant="caption" paragraph>
              (
              <a
                className="supportLink"
                href="https://pododesk.freshdesk.com/support/solutions/articles/17000086660-externe-kalender-z-b-feiertage-einbinden"
                target="_blank"
                rel="noreferrer"
              >
                Hilfe zu dieser Funktion
              </a>
              )
            </Typography>
            <div className="icsSourcesContainer">
              {Array.isArray(icsSources)
                ? icsSources.map(({ bezeichnung, kalenderURL, farbe, termineErzeugenFarbschleier }, index) => (
                    <div key={index} className="icsSource">
                      <InputField
                        ref={bezeichnungRef}
                        {...bezeichnung}
                        label="Kalender Bezeichnung"
                        error={bezeichnung.touched && !!bezeichnung.error}
                        helperText={bezeichnung.touched && !!bezeichnung.error ? bezeichnung.error : ''}
                      />
                      <InputField
                        ref={kalenderURLRef}
                        {...kalenderURL}
                        type="url"
                        label="Kalender URL"
                        error={kalenderURL.touched && !!kalenderURL.error}
                        helperText={kalenderURL.touched && !!kalenderURL.error ? kalenderURL.error : ''}
                      />
                      <ColorField {...farbe} labelText="Farbe" fullWidth />
                      <CheckboxField {...termineErzeugenFarbschleier} label="Termine schattieren Kalender" />
                      {bezeichnung.value && kalenderURL.value ? (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            icsSources.removeField(index)
                          }}
                        >
                          Diesen Kalender entfernen
                        </Button>
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
            <div>
              <FormDivider />
              <div className="icsSourceButtonContainer">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    icsSources.addField({
                      bezeichnung: '',
                      kalenderURL: '',
                      farbe: '#A8CDD2',
                      termineErzeugenFarbschleier: false,
                    })
                  }}
                >
                  Einen neuen Kalender hinzufügen
                </Button>
                {/* <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (icsSources.length > 0) {
                      icsSources.removeField(icsSources.length - 1)
                    }
                  }}
                >
                  Den letzten Kalender entfernen
                </Button> */}
              </div>
            </div>
          </div>
        </div>

        <FormDivider />

        <div>
          <Typography variant="h6" paragraph>
            <span className="subHeader">Kalenderanzeige</span>
          </Typography>
        </div>
        <div ref={terminrasterRef} className="durationGridSettings">
          <InputField
            ref={standardBehandlungsdauerRef}
            {...standardBehandlungsdauer}
            label="Standard-Behandlungsdauer (in Minuten)"
            error={standardBehandlungsdauer.touched && !!standardBehandlungsdauer.error}
            helperText={
              standardBehandlungsdauer.touched && !!standardBehandlungsdauer.error ? standardBehandlungsdauer.error : ''
            }
          />
          <SelectField
            {...terminraster}
            label="Terminraster (in Minuten)"
            error={terminraster.touched && !!terminraster.error}
            helperText={terminraster.touched && !!terminraster.error && terminraster.error}
            options={[5, 10, 15, 20, 30, 60].map((item) => {
              return { value: item, text: item }
            })}
          />
        </div>
        <div className="calendarFeatures">
          <CheckboxField {...terminFindenDeaktiviert} label={'"Termin finden" Ansicht überspringen'} />
          <CheckboxField {...treatmentRoomFeature} label="Behandlungsräume aktivieren" />
          {!!treatmentRoomFeature?.value && (
            <CheckboxField {...roomSelectionMandatory} label="Verpflichtende Raumauswahl bei Terminvergabe" />
          )}
        </div>

        <FormDivider />

        <div>
          <div>
            <Typography variant="h5">Terminerinnerungen für Patienten</Typography>
            <Typography variant="caption" paragraph>
              (
              <a
                className="supportLink"
                href="https://pododesk.freshdesk.com/support/solutions/articles/17000086789-terminerinnerung-f%C3%BCr-patienten"
                target="_blank"
                rel="noreferrer"
              >
                Hilfe zu dieser Funktion
              </a>
              )
            </Typography>
            <FormControlLabel
              {...isAppointmentReminderEnabled}
              control={<Switch />}
              label="E-Mail-Erinnerung aktivieren/deaktivieren"
              checked={isAppointmentReminderEnabled.value}
              onChange={isAppointmentReminderEnabled.onChange}
            />
            <RadioGroup {...appointmentRemindersSchedule} value={appointmentRemindersSchedule.value}>
              <FormControlLabel
                control={<Radio />}
                value="3"
                label="kurz vorher: 3 Stunden vor dem Termin"
                disabled={!isAppointmentReminderEnabled.value}
              />
              <FormControlLabel
                control={<Radio />}
                value="00 8 * * *"
                label="ein Tag vorher: am Vortag um 8 Uhr morgens"
                disabled={!isAppointmentReminderEnabled.value}
              />
              <FormControlLabel
                control={<Radio />}
                value="72"
                label="drei Tage vorher: 72 Stunden vor dem Termin"
                disabled={!isAppointmentReminderEnabled.value}
              />
            </RadioGroup>
            {!!isAppointmentReminderEnabled.value && (
              <InputField
                {...emailReminderNote}
                multiline
                rows={3}
                fullWidth
                label="optionaler Hinweistext"
                placeholder="ggfs. optionaler Hinweistext, der in der E-Mail-Terminerinnerung angezeigt werden soll"
                error={emailReminderNote.touched && !!emailReminderNote.error}
                helperText={emailReminderNote.touched && !!emailReminderNote.error ? emailReminderNote.error : ''}
              />
            )}
            {smsFeatureEnabled ? (
              <>
                <FormControlLabel
                  {...smsReminder}
                  control={<Switch />}
                  label="SMS-Erinnerung aktivieren/deaktivieren"
                  checked={smsReminder.value}
                  onChange={smsReminder.onChange}
                />
                {smsReminder.value && (
                  <div>
                    <span>Vorlaufzeit {smsSchedule.value || 0}h</span>
                    <Slider
                      min={1}
                      max={168}
                      step={1}
                      style={{ width: '350px' }}
                      value={isNaN(smsSchedule.value) ? 0 : +smsSchedule.value}
                      onDragStart={() => {}}
                      onChange={(e, value) => {
                        smsSchedule.onChange(value)
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <span style={{ marginTop: '20px', display: 'block' }}>
                Die SMS Erinnerung steht in Ihrer Praxis nicht zur Verfügung.
              </span>
            )}
            {callFeatureEnabled ? (
              <>
                <FormControlLabel
                  {...callReminder}
                  control={<Switch />}
                  label="Terminerinnerung per Festnetz-Anruf aktivieren/deaktivieren"
                  checked={callReminder.value}
                  onChange={callReminder.onChange}
                />

                <RadioGroup {...callReminderSchedule} value={callReminderSchedule.value}>
                  <FormControlLabel
                    control={<Radio />}
                    value="3"
                    label="kurz vorher: 3 Stunden vor dem Termin"
                    disabled={!callReminder.value}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="00 8 * * *"
                    label="ein Tag vorher: am Vortag um 8 Uhr morgens"
                    disabled={!callReminder.value}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="72"
                    label="drei Tage vorher: 72 Stunden vor dem Termin"
                    disabled={!callReminder.value}
                  />
                </RadioGroup>
              </>
            ) : (
              <span style={{ marginTop: '20px', display: 'block' }}>
                Die Erinnerung per Festnetz-Anruf steht in Ihrer Praxis nicht zur Verfügung.
              </span>
            )}
          </div>
        </div>

        <FormDivider />

        <div>
          <Typography variant="h5">Öffnungszeiten/Kalender Sichtbarkeit</Typography>
          <Typography variant="caption" paragraph>
            (
            <a
              className="supportLink"
              href="https://pododesk.freshdesk.com/support/solutions/articles/17000086778-%C3%96ffnungszeiten-und-arbeitszeiten-einstellen"
              target="_blank"
              rel="noreferrer"
            >
              Hilfe zu dieser Funktion
            </a>
            )
          </Typography>
          <Zeiten {...oeffnungszeiten} />
        </div>

        <FormDivider />

        <Button
          onClick={() => handleScrollToInvalidInput(refMap, errors)}
          variant="contained"
          color="secondary"
          type="submit"
          fullWidth
        >
          Einstellungen speichern
        </Button>
      </StyledPraxisstammdatenForm>
    </>
  )
}

const validate = (values) => {
  const required = ['inhaber', 'standardBehandlungsdauer', 'terminraster', 'firmenbezeichnung']
  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  if (values.arniCustomer && !values.institutionskennzeichen) {
    errors.institutionskennzeichen = 'Bitte ausfüllen!'
  }

  if (values.callReminder && !values.telefon) {
    errors.telefon = 'Bitte ausfüllen!'
  }

  if (values.callReminder && !values.countryCode) {
    errors.countryCode = 'Bitte ausfüllen!'
  }

  if (values.emailReminderNote && values.emailReminderNote.length > 255) {
    errors.emailReminderNote = 'Der Text darf maximal 255 Zeichen lang sein.'
  }

  errors.icsSources = values.icsSources.map((source) => {
    const { bezeichnung, kalenderURL } = source
    const error: KeyValue<string> = {}

    if (!bezeichnung) {
      error.bezeichnung = 'Bitte ausfüllen.'
    }

    if (values.firmenbezeichnung && values.firmenbezeichnung.length > 70) {
      errors.firmenbezeichnung = 'Die Bezeichnung darf maximal 70 Zeichen lang sein.'
    }

    if (bezeichnung && bezeichnung.length > 20) {
      error.bezeichnung = 'Die Bezeichnung darf maximal 20 Zeichen lang sein.'
    }

    if (!kalenderURL) {
      error.kalenderURL = 'Bitte ausfüllen.'
    }

    if (!/^(webcal|http|https):\/\/[^ "]+$/.test(kalenderURL)) {
      error.kalenderURL = 'Dies ist keine gültige Kalender URL.'
    }

    const hasURLDuplicates =
      kalenderURL &&
      values.icsSources.some((src) => {
        const { kalenderURL: URL } = src

        return src !== source && kalenderURL.replace(' ', '') === URL.replace(' ', '')
      })

    if (hasURLDuplicates) {
      error.kalenderURL = 'Diese Kalender URL existiert bereits.'
    }

    return error
  })

  if (values.email && !isEmail(values.email)) {
    errors.email = 'Bitte geben Sie eine gültige E-mail-Adresse ein.'
  }

  if (
    values.website &&
    !isURL(values.website, {
      protocols: ['http', 'https'],
      require_protocol: false,
    })
  ) {
    errors.website = 'Dies ist keine gültige URL.'
  }

  if (!/^\d+$/.test(values.standardBehandlungsdauer)) {
    errors.standardBehandlungsdauer = 'Die Standard-Behandlungsdauer darf nur aus Ziffern bestehen.'
  }

  if (values.telefon && values.telefon.length > 25) {
    errors.telefon = 'Die Rufnummer darf maximal 25 Zeichen lang sein.'
  }

  if (parseInt(values.standardBehandlungsdauer, 10) < 5) {
    errors.standardBehandlungsdauer = 'Die Standard-Behandlungsdauer muss mindestens 5 Minuten betragen.'
  }

  if (parseInt(values.standardBehandlungsdauer, 10) < parseInt(values.terminraster, 10)) {
    errors.standardBehandlungsdauer = 'Die Standard-Behandlungsdauer darf nicht kürzer als das Terminraster sein.'
    errors.terminraster = 'Das Terminraster darf nicht länger als die Standard-Behandlungsdauer sein.'
  }

  if (errorTextCountryCode) {
    errors.countryCode = errorTextCountryCode.trim()
  }

  return errors
}

const mapStateToProps = (state) => {
  let initialValues
  const ps = praxisstammdatenSelector(state)
  if (ps && ps.oeffnungszeiten === null) {
    initialValues = ps
    initialValues.oeffnungszeiten = [
      {
        tag: 'mon',
        zeiten: [
          {
            von: '',
            bis: '',
          },
        ],
      },
    ]
  } else {
    initialValues = ps
  }
  return {
    bundeslaender: state.bundeslaender,
    busy: state.notification.busy,
    praxisstammdaten: ps,
    initialValues,
    smsFeatureEnabled: isSMSFeatureEnabled(state),
    callFeatureEnabled: isCallFeatureEnabled(state),
    currentUser: sCurrentUser(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    praxisstammdaten: bindActionCreators(praxisstammdatenActions, dispatch),
  },
})

export default reduxForm(
  {
    form: 'praxisstammdatenForm',
    validate,
    asyncValidate: validatePracticePhoneNumber,
    asyncBlurFields: ['telefon', 'countryCode', 'callReminder'],
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(PraxisstammdatenForm))
