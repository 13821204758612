export const isDebuggingEnabled = (): boolean =>
  process.env.DEBUG_LOGGING_ENABLED === 'true' || process.env.DEBUG_LOGGING_ENABLED === '1'

export const isLocalDev = (): boolean => window.location.hostname === 'localhost'

export const isWebBluetoothEnabled = (): boolean => !!navigator?.bluetooth

export const calculateTotalPatientsCount = (apiServers): number => {
  return apiServers.reduce((acc, server) => acc + server.patientCount, 0)
}

export const isReadOnly = (): boolean => sessionStorage.getItem('readOnly') === 'true'