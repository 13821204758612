import { LocalHospital as LocalHospitalIcon } from '@material-ui/icons';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as doctorsActions from '../../actions/doctors';
import DoctorForm from '../../components/DoctorForm/DoctorForm';
import IconHeading from '../../shared/components/IconHeading/IconHeading';
import { StyledEditDoctor } from './StyledEditDoctor';
import { sApiServer } from '../../shared/utils/auth';
import { isReadOnly } from '../../utils/helpers';


const EditDoctor = ({ actions, doctors, currentServer }) => {
  const params = useParams();

  const saveForm = useCallback(async (data) => {
    await actions.doctors.updateDoctor(data, params.doctor);
  }, [actions.doctors.updateDoctor]);

  return (
    <StyledEditDoctor>
      <IconHeading 
        icon={<LocalHospitalIcon />}
        text="Arzt bearbeiten" />
      <DoctorForm
        onSubmitFunc={saveForm}
        initialValues={doctors[params.doctor]}
        submitText="Ärztekartei aktualisieren"
        editMode
        viewOnly={isReadOnly()}
      />
    </StyledEditDoctor>
  );
};

const mapStateToProps = (state) => ({
  doctors: state.entities.aerzte,
  currentServer: sApiServer(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    doctors: bindActionCreators(doctorsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDoctor);
