/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import CheckboxXOR from '../../../shared/components/CheckboxXOR'
import InputField from '../../../shared/components/InputField/InputField'
import { SelectField } from '../../../shared/components/SelectField/SelectField'

import DividerLink from '../components/DividerLink'
import FieldContainer from '../components/FieldContainer'
import { StyledAnamnesisForm } from '../styles'
import { fields } from '../utils'

const kmaBluterMedikamentItems = [
  { value: 'Blutverdünner', text: 'Blutverdünner' },
  { value: 'Blutgerinnungshemmer', text: 'Blutgerinnungshemmer' },
  { value: 'keins', text: 'keins' },
]

const AF05 = ({
  fields: {
    kmaKrankheitenMedikamente,
    kmaBluter,
    kmaBluterMedikament,
    kmaEingeschraenktesImmunsystemChemo,
    kmaPsoriasis,
    kmaNeurodermitis,
    kmaAthritisArthroseRheumaGichtOsteoporose,
    kmaBluthochdruck,
    kmaHerzerkrankung,
    kmaSonstigeErkrankungen,
    kmaSonstigeMedikamente,
    kmaKrankheitenMedikamenteKommentar,
    kmaAllergien,
    kmaAllergienKommentar,
    patient,
  },
  readOnly,
}) => {
  const { serverHash } = useParams()

  return (
    <StyledAnamnesisForm autoComplete="off">
      <div className="outer">
        <div className="inner">
          <div className="columnFullWidth">
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/04`} iconDirection="up" />

            <CheckboxXOR
              {...kmaKrankheitenMedikamente}
              className="checkboxXORHead"
              label="Krankheiten & Medikamente?"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />

            {kmaKrankheitenMedikamente.value && (
              <>
                <FieldContainer label="Bluter?" pullLeft>
                  <CheckboxXOR
                    {...kmaBluter}
                    className="checkboxXOR"
                    labelNo="Nein"
                    labelYes="Ja"
                    disabled={readOnly}
                  />
                </FieldContainer>

                {kmaBluter.value && (
                  <FieldContainer label="Medikament:" pullLeft>
                    <SelectField {...kmaBluterMedikament} options={kmaBluterMedikamentItems} disabled={readOnly} />
                  </FieldContainer>
                )}

                <FieldContainer label="Eingeschränktes Immunsystem (Chemo)?" pullLeft>
                  <CheckboxXOR
                    {...kmaEingeschraenktesImmunsystemChemo}
                    className="checkboxXOR"
                    labelNo="Nein"
                    labelYes="Ja"
                    disabled={readOnly}
                  />
                </FieldContainer>

                <FieldContainer label="Psoriasis?" pullLeft>
                  <CheckboxXOR
                    {...kmaPsoriasis}
                    className="checkboxXOR"
                    labelNo="Nein"
                    labelYes="Ja"
                    disabled={readOnly}
                  />
                </FieldContainer>

                <FieldContainer label="Neurodermitis?" pullLeft>
                  <CheckboxXOR
                    {...kmaNeurodermitis}
                    className="checkboxXOR"
                    labelNo="Nein"
                    labelYes="Ja"
                    disabled={readOnly}
                  />
                </FieldContainer>

                <FieldContainer label="Arthritis/Arthrose/Rheuma/Gicht/Osteoporose?" pullLeft>
                  <CheckboxXOR
                    {...kmaAthritisArthroseRheumaGichtOsteoporose}
                    className="checkboxXOR"
                    labelNo="Nein"
                    labelYes="Ja"
                    disabled={readOnly}
                  />
                </FieldContainer>

                <FieldContainer label="Bluthochdruck?" pullLeft>
                  <CheckboxXOR
                    {...kmaBluthochdruck}
                    className="checkboxXOR"
                    labelNo="Nein"
                    labelYes="Ja"
                    disabled={readOnly}
                  />
                </FieldContainer>

                <FieldContainer label="Herzerkrankung?" pullLeft>
                  <CheckboxXOR
                    {...kmaHerzerkrankung}
                    className="checkboxXOR"
                    labelNo="Nein"
                    labelYes="Ja"
                    disabled={readOnly}
                  />
                </FieldContainer>

                <FieldContainer label="Sonstige Erkrankungen?" pullLeft>
                  <CheckboxXOR
                    {...kmaSonstigeErkrankungen}
                    className="checkboxXOR"
                    labelNo="Nein"
                    labelYes="Ja"
                    disabled={readOnly}
                  />
                </FieldContainer>

                <FieldContainer label="Sonstige Medikamente?" pullLeft>
                  <CheckboxXOR
                    {...kmaSonstigeMedikamente}
                    className="checkboxXOR"
                    labelNo="Nein"
                    labelYes="Ja"
                    disabled={readOnly}
                  />
                </FieldContainer>
              </>
            )}

            <InputField
              {...kmaKrankheitenMedikamenteKommentar}
              multiline
              rows={5}
              label="Kommentar"
              readOnly={readOnly}
            />

            <CheckboxXOR
              {...kmaAllergien}
              label="Allergien?"
              className="checkboxXORHead"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />
            <InputField {...kmaAllergienKommentar} multiline rows={5} label="Kommentar" readOnly={readOnly} />

            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/06`} iconDirection="down" />
          </div>
        </div>
      </div>
    </StyledAnamnesisForm>
  )
}

const validate = (values) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  return errors
}

export default reduxForm({
  form: 'anamneseForm',
  validate,
  fields,
  destroyOnUnmount: false,
})(AF05)
