/*
 * App
 **/
export const APP_INITIALIZE = 'APP_INITIALIZE'
export const MIDDLEWARES_INITIALIZE = 'MIDDLEWARES_INITIALIZE'

/*
 *   Dialogs
 **/
export const OPEN_EDIT_TERMIN_DIALOG = 'OPEN_EDIT_TERMIN_DIALOG'
export const CLOSE_EDIT_TERMIN_DIALOG = 'CLOSE_EDIT_TERMIN_DIALOG'

/**
 * Phone Number SMS
 */
export const VERIFY_NUMBER = 'VERIFY_NUMBER'
export const VERIFY_NUMBER_SUCCESS = `${VERIFY_NUMBER}_SUCCESS`
export const VERIFY_NUMBER_FAILURE = `${VERIFY_NUMBER}_FAILURE`

/*
 *   Icon Menus
 **/
export const OPEN_TERMIN_ICON_MENU = 'OPEN_TERMIN_ICON_MENU'
export const CLOSE_TERMIN_ICON_MENU = 'CLOSE_TERMIN_ICON_MENU'

export const OPEN_PATIENT_ICON_MENU = 'OPEN_PATIENT_ICON_MENU'
export const CLOSE_PATIENT_ICON_MENU = 'CLOSE_PATIENT_ICON_MENU'

export const OPEN_ARZT_ICON_MENU = 'OPEN_ARZT_ICON_MENU'
export const CLOSE_ARZT_ICON_MENU = 'CLOSE_ARZT_ICON_MENU'

export const OPEN_THERAPEUT_ICON_MENU = 'OPEN_THERAPEUT_ICON_MENU'
export const CLOSE_THERAPEUT_ICON_MENU = 'CLOSE_THERAPEUT_ICON_MENU'

export const OPEN_LEISTUNGPRIVAT_ICON_MENU = 'OPEN_LEISTUNGPRIVAT_ICON_MENU'
export const CLOSE_LEISTUNGPRIVAT_ICON_MENU = 'CLOSE_LEISTUNGPRIVAT_ICON_MENU'

export const OPEN_HEILMITTELVERORDNUNG_ICON_MENU = 'OPEN_HEILMITTELVERORDNUNG_ICON_MENU'
export const CLOSE_HEILMITTELVERORDNUNG_ICON_MENU = 'CLOSE_HEILMITTELVERORDNUNG_ICON_MENU'

export const OPEN_THERAPIEBERICHT_ICON_MENU = 'OPEN_THERAPIEBERICHT_ICON_MENU'
export const CLOSE_THERAPIEBERICHT_ICON_MENU = 'CLOSE_THERAPIEBERICHT_ICON_MENU'

export const OPEN_ANAMNESE_ICON_MENU = 'OPEN_ANAMNESE_ICON_MENU'
export const CLOSE_ANAMNESE_ICON_MENU = 'CLOSE_ANAMNESE_ICON_MENU'

/*
 *   ANAMNESIS
 **/
export const CREATE_ANAMNESIS = 'CREATE_ANAMNESIS'
export const CREATE_ANAMNESIS_SUCCESS = 'CREATE_ANAMNESIS_SUCCESS'
export const CREATE_ANAMNESIS_FAILURE = 'CREATE_ANAMNESIS_FAILURE'

export const LOAD_ANAMNESIS = 'LOAD_ANAMNESIS'
export const LOAD_ANAMNESIS_SUCCESS = 'LOAD_ANAMNESIS_SUCCESS'
export const LOAD_ANAMNESIS_FAILURE = 'LOAD_ANAMNESIS_FAILURE'

export const UPDATE_ANAMNESIS = 'UPDATE_ANAMNESIS'
export const UPDATE_ANAMNESIS_SUCCESS = 'UPDATE_ANAMNESIS_SUCCESS'
export const UPDATE_ANAMNESIS_FAILURE = 'UPDATE_ANAMNESIS_FAILURE'

export const FINISH_ANAMNESIS = 'FINISH_ANAMNESIS'
export const FINISH_ANAMNESIS_SUCCESS = 'FINISH_ANAMNESIS_SUCCESS'
export const FINISH_ANAMNESIS_FAILURE = 'FINISH_ANAMNESIS_FAILURE'

/*
 *   DATES
 **/
export const CREATE_DATE = 'CREATE_DATE'
export const CREATE_DATE_SUCCESS = 'CREATE_DATE_SUCCESS'
export const CREATE_DATE_FAILURE = 'CREATE_DATE_FAILURE'

export const UPDATE_DATE = 'UPDATE_DATE'
export const UPDATE_DATE_SUCCESS = 'UPDATE_DATE_SUCCESS'
export const UPDATE_DATE_FAILURE = 'UPDATE_DATE_FAILURE'

export const UNDO_CANCEL_DATE = 'UNDO_CANCEL_DATE'
export const UNDO_CANCEL_DATE_SUCCESS = 'UNDO_CANCEL_DATE_SUCCESS'
export const UNDO_CANCEL_DATE_FAILURE = 'UNDO_CANCEL_DATE_FAILURE'

export const LOAD_DATE = 'LOAD_DATE'
export const LOAD_DATE_SUCCESS = 'LOAD_DATE_SUCCESS'
export const LOAD_DATE_FAILURE = 'LOAD_DATE_FAILURE'

export const LOAD_DATES = 'LOAD_DATES'
export const LOAD_DATES_SUCCESS = 'LOAD_DATES_SUCCESS'
export const LOAD_DATES_FAILURE = 'LOAD_DATES_FAILURE'

export const CANCEL_DATE_DECEASED = 'CANCEL_DATE_DECEASED'
export const CANCEL_DATE_DECEASED_SUCCESS = 'CANCEL_DATE_DECEASED_SUCCESS'
export const CANCEL_DATE_DECEASED_FAILURE = 'CANCEL_DATE_DECEASED_FAILURE'

/*
 *   CUSTOMERS
 **/
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const LOAD_CUSTOMER = 'LOAD_CUSTOMER'
export const LOAD_CUSTOMER_SUCCESS = 'LOAD_CUSTOMER_SUCCESS'
export const LOAD_CUSTOMER_FAILURE = 'LOAD_CUSTOMER_FAILURE'
export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS'
export const LOAD_CUSTOMERS_SUCCESS = 'LOAD_CUSTOMERS_SUCCESS'
export const LOAD_CUSTOMERS_FAILURE = 'LOAD_CUSTOMERS_FAILURE'
export const LOAD_CUSTOMERS_ALPHABETICALLY = 'LOAD_CUSTOMERS_ALPHABETICALLY'
export const LOAD_CUSTOMERS_ALPHABETICALLY_SUCCESS = 'LOAD_CUSTOMERS_ALPHABETICALLY_SUCCESS'
export const LOAD_CUSTOMERS_ALPHABETICALLY_FAILURE = 'LOAD_CUSTOMERS_ALPHABETICALLY_FAILURE'
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER'
export const FILTER_CUSTOMERS_LASTNAME_BEGINS_WITH = 'FILTER_CUSTOMERS_LASTNAME_BEGINS_WITH'
export const FILTER_DECEASED = 'FILTER_DECEASED'
export const FILTER_LEAVERS = 'FILTER_LEAVERS'
export const FIND_CUSTOMERS = 'FIND_CUSTOMERS'
export const FIND_CUSTOMERS_SUCCESS = 'FIND_CUSTOMERS_SUCCESS'
export const FIND_CUSTOMERS_FAILURE = 'FIND_CUSTOMERS_FAILURE'
export const HAS_PATIENTS_REMAINING = 'HAS_PATIENTS_REMAINING'
export const HAS_PATIENTS_REMAINING_SUCCESS = 'HAS_PATIENTS_REMAINING_SUCCESS'
export const HAS_PATIENTS_REMAINING_FAILURE = 'HAS_PATIENTS_REMAINING_FAILURE'

/*
 *   DOCTORS
 **/
export const CREATE_DOCTOR = 'CREATE_DOCTOR'
export const CREATE_DOCTOR_SUCCESS = 'CREATE_DOCTOR_SUCCESS'
export const CREATE_DOCTOR_FAILURE = 'CREATE_DOCTOR_FAILURE'
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR'
export const UPDATE_DOCTOR_SUCCESS = 'UPDATE_DOCTOR_SUCCESS'
export const UPDATE_DOCTOR_FAILURE = 'UPDATE_DOCTOR_FAILURE'
export const DELETE_DOCTOR = 'DELETE_DOCTOR'
export const LOAD_DOCTORS = 'LOAD_DOCTORS'
export const LOAD_DOCTORS_SUCCESS = 'LOAD_DOCTORS_SUCCESS'
export const LOAD_DOCTORS_FAILURE = 'LOAD_DOCTORS_FAILURE'

export const SELECT_DOCTOR = 'SELECT_DOCTOR'

export const FILTER_DOCTORS_LASTNAME_BEGINS_WITH = 'FILTER_DOCTORS_LASTNAME_BEGINS_WITH'

/*
 *   USERS
 **/
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const UPDATE_INSTANCE_USER = 'UPDATE_INSTANCE_USER'
export const UPDATE_INSTANCE_USER_SUCCESS = 'UPDATE_INSTANCE_USER_SUCCESS'
export const UPDATE_INSTANCE_USER_FAILURE = 'UPDATE_INSTANCE_USER_FAILURE'
export const LOAD_PEOPLE = 'LOAD_PEOPLE'
export const LOAD_PEOPLE_SUCCESS = 'LOAD_PEOPLE_SUCCESS'
export const LOAD_PEOPLE_FAILURE = 'LOAD_PEOPLE_FAILURE'

export const SELECT_PERSON = 'SELECT_PERSON'
export const SELECT_THERAPEUT = 'SELECT_THERAPEUT'

export const GET_ICALENDAR_TOKEN = 'GET_ICALENDAR_TOKEN'
export const GET_ICALENDAR_TOKEN_SUCCESS = 'GET_ICALENDAR_TOKEN_SUCCESS'
export const GET_ICALENDAR_TOKEN_FAILURE = 'GET_ICALENDAR_TOKEN_FAILURE'

export const RESET_ICALENDAR_TOKEN = 'RESET_ICALENDAR_TOKEN'
export const RESET_ICALENDAR_TOKEN_SUCCESS = 'RESET_ICALENDAR_TOKEN_SUCCESS'
export const RESET_ICALENDAR_TOKEN_FAILURE = 'RESET_ICALENDAR_TOKEN_FAILURE'

export const DELETE_ICALENDAR_TOKEN = 'DELETE_ICALENDAR_TOKEN'
export const DELETE_ICALENDAR_TOKEN_SUCCESS = 'DELETE_ICALENDAR_TOKEN_SUCCESS'
export const DELETE_ICALENDAR_TOKEN_FAILURE = 'DELETE_ICALENDAR_TOKEN_FAILURE'

/*
 *   AUTOCOMPLETE
 **/
export const LOAD_AUTOCOMPLETE = 'LOAD_AUTOCOMPLETE'
export const LOAD_AUTOCOMPLETE_SUCCESS = 'LOAD_AUTOCOMPLETE_SUCCESS'
export const LOAD_AUTOCOMPLETE_FAILURE = 'LOAD_AUTOCOMPLETE_FAILURE'

/*
 *   FILES
 */
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'

export const UPDATE_FILE = 'UPDATE_FILE'
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS'
export const UPDATE_FILE_FAILURE = 'UPDATE_FILE_FAILURE'

export const LOAD_FILES = 'LOAD_FILES'
export const LOAD_FILES_SUCCESS = 'LOAD_FILES_SUCCESS'
export const LOAD_FILES_FAILURE = 'LOAD_FILES_FAILURE'

export const LOAD_IMAGE = 'LOAD_IMAGE'
export const LOAD_IMAGE_SUCCESS = 'LOAD_IMAGE_SUCCESS'
export const LOAD_IMAGE_FAILURE = 'LOAD_IMAGE_FAILURE'

/*
 *   PRAXISSTAMMDATEN
 **/
export const LOAD_PRAXISSTAMMDATEN = 'LOAD_PRAXISSTAMMDATEN'
export const LOAD_PRAXISSTAMMDATEN_SUCCESS = 'LOAD_PRAXISSTAMMDATEN_SUCCESS'
export const LOAD_PRAXISSTAMMDATEN_FAILURE = 'LOAD_PRAXISSTAMMDATEN_FAILURE'

export const UPDATE_PRAXISSTAMMDATEN = 'UPDATE_PRAXISSTAMMDATEN'
export const UPDATE_PRAXISSTAMMDATEN_SUCCESS = 'UPDATE_PRAXISSTAMMDATEN_SUCCESS'
export const UPDATE_PRAXISSTAMMDATEN_FAILURE = 'UPDATE_PRAXISSTAMMDATEN_FAILURE'

/*
 *   HEILMITTELVERORDNUNGEN
 **/
export const LOAD_HEILMITTELVERORDNUNG = 'LOAD_HEILMITTELVERORDNUNG'
export const LOAD_HEILMITTELVERORDNUNG_SUCCESS = 'LOAD_HEILMITTELVERORDNUNG_SUCCESS'
export const LOAD_HEILMITTELVERORDNUNG_FAILURE = 'LOAD_HEILMITTELVERORDNUNG_FAILURE'

export const LOAD_HEILMITTELVERORDNUNGEN = 'LOAD_HEILMITTELVERORDNUNGEN'
export const LOAD_HEILMITTELVERORDNUNGEN_SUCCESS = 'LOAD_HEILMITTELVERORDNUNGEN_SUCCESS'
export const LOAD_HEILMITTELVERORDNUNGEN_FAILURE = 'LOAD_HEILMITTELVERORDNUNGEN_FAILURE'

export const CREATE_HEILMITTELVERORDNUNG = 'CREATE_HEILMITTELVERORDNUNG'
export const CREATE_HEILMITTELVERORDNUNG_SUCCESS = 'CREATE_HEILMITTELVERORDNUNG_SUCCESS'
export const CREATE_HEILMITTELVERORDNUNG_FAILURE = 'CREATE_HEILMITTELVERORDNUNG_FAILURE'

export const UPDATE_HEILMITTELVERORDNUNG = 'UPDATE_HEILMITTELVERORDNUNG'
export const UPDATE_HEILMITTELVERORDNUNG_SUCCESS = 'UPDATE_HEILMITTELVERORDNUNG_SUCCESS'
export const UPDATE_HEILMITTELVERORDNUNG_FAILURE = 'UPDATE_HEILMITTELVERORDNUNG_FAILURE'

export const SUBMIT_HVO = 'SUBMIT_HVO'
export const SUBMIT_HVO_SUCCESS = 'SUBMIT_HVO_SUCCESS'
export const SUBMIT_HVO_FAILURE = 'SUBMIT_HVO_FAILURE'

export const SELECT_HEILMITTELVERORDNUNG = 'SELECT_HEILMITTELVERORDNUNG'

/*
 *   THERAPIEBERICHTE
 **/
export const LOAD_THERAPIEBERICHTE = 'LOAD_THERAPIEBERICHTE'
export const LOAD_THERAPIEBERICHTE_SUCCESS = 'LOAD_THERAPIEBERICHTE_SUCCESS'
export const LOAD_THERAPIEBERICHTE_FAILURE = 'LOAD_THERAPIEBERICHTE_FAILURE'
export const CREATE_THERAPIEBERICHT = 'CREATE_THERAPIEBERICHT'
export const CREATE_THERAPIEBERICHT_SUCCESS = 'CREATE_THERAPIEBERICHT_SUCCESS'
export const CREATE_THERAPIEBERICHT_FAILURE = 'CREATE_THERAPIEBERICHT_FAILURE'

/*
*   TREATMENT ROOMS
**/
export const LOAD_ROOMS = 'LOAD_ROOMS'
export const LOAD_ROOMS_SUCCESS = 'LOAD_ROOMS_SUCCESS'
export const LOAD_ROOMS_FAILURE = 'LOAD_ROOMS_FAILURE'
export const CREATE_ROOM = 'CREATE_ROOM'
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS'
export const CREATE_ROOM_FAILURE = 'CREATE_ROOM_FAILURE'
export const UPDATE_ROOM = 'UPDATE_ROOM'
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS'
export const UPDATE_ROOM_FAILURE = 'UPDATE_ROOM_FAILURE'


/*
*   TREATMENT ROOMS EQUIPMENT
**/
export const LOAD_EQUIPMENT = 'LOAD_EQUIPMENT'
export const LOAD_EQUIPMENT_SUCCESS = 'LOAD_EQUIPMENT_SUCCESS'
export const LOAD_EQUIPMENT_FAILURE = 'LOAD_EQUIPMENT_FAILURE'
export const CREATE_EQUIPMENT = 'CREATE_EQUIPMENT'
export const CREATE_EQUIPMENT_SUCCESS = 'CREATE_EQUIPMENT_SUCCESS'
export const CREATE_EQUIPMENT_FAILURE = 'CREATE_EQUIPMENT_FAILURE'
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT'
export const UPDATE_EQUIPMENT_SUCCESS = 'UPDATE_EQUIPMENT_SUCCESS'
export const UPDATE_EQUIPMENT_FAILURE = 'UPDATE_EQUIPMENT_FAILURE'

/*
 *   DOKUMENTATIONEN
 **/
export const LOAD_DOKUMENTATIONEN = 'LOAD_DOKUMENTATIONEN'
export const LOAD_DOKUMENTATIONEN_SUCCESS = 'LOAD_DOKUMENTATIONEN_SUCCESS'
export const LOAD_DOKUMENTATIONEN_FAILURE = 'LOAD_DOKUMENTATIONEN_FAILURE'
export const CREATE_DOKUMENTATION = 'CREATE_DOKUMENTATION'
export const CREATE_DOKUMENTATION_SUCCESS = 'CREATE_DOKUMENTATION_SUCCESS'
export const CREATE_DOKUMENTATION_FAILURE = 'CREATE_DOKUMENTATION_FAILURE'
export const UPDATE_DOKUMENTATION = 'UPDATE_DOKUMENTATION'
export const UPDATE_DOKUMENTATION_SUCCESS = 'UPDATE_DOKUMENTATION_SUCCESS'
export const UPDATE_DOKUMENTATION_FAILURE = 'UPDATE_DOKUMENTATION_FAILURE'

/*
*   ABBREVIATIONS FOR TEXT EXPANDING
**/
export const LOAD_ABBREVIATIONS = 'LOAD_ABBREVIATIONS'
export const LOAD_ABBREVIATIONS_SUCCESS = 'LOAD_ABBREVIATIONS_SUCCESS'
export const LOAD_ABBREVIATIONS_FAILURE = 'LOAD_ABBREVIATIONS_FAILURE'
export const CREATE_ABBREVIATION = 'CREATE_ABBREVIATION'
export const CREATE_ABBREVIATION_SUCCESS = 'CREATE_ABBREVIATION_SUCCESS'
export const CREATE_ABBREVIATION_FAILURE = 'CREATE_ABBREVIATION_FAILURE'
export const UPDATE_ABBREVIATION = 'UPDATE_ABBREVIATION'
export const UPDATE_ABBREVIATION_SUCCESS = 'UPDATE_ABBREVIATION_SUCCESS'
export const UPDATE_ABBREVIATION_FAILURE = 'UPDATE_ABBREVIATION_FAILURE'

/*
 *   DOKUMENTATIONSTEXTE
 **/
export const LOAD_DOKUMENTATIONSTEXTE = 'LOAD_DOKUMENTATIONSTEXTE'
export const LOAD_DOKUMENTATIONSTEXTE_SUCCESS = 'LOAD_DOKUMENTATIONSTEXTE_SUCCESS'
export const LOAD_DOKUMENTATIONSTEXTE_FAILURE = 'LOAD_DOKUMENTATIONSTEXTE_FAILURE'
export const CREATE_DOKUMENTATIONSTEXT = 'CREATE_DOKUMENTATIONSTEXT'
export const CREATE_DOKUMENTATIONSTEXT_SUCCESS = 'CREATE_DOKUMENTATIONSTEXT_SUCCESS'
export const CREATE_DOKUMENTATIONSTEXT_FAILURE = 'CREATE_DOKUMENTATIONSTEXT_FAILURE'

/*
 *   LEISTUNGENPRIVAT
 **/
export const CREATE_LEISTUNGPRIVAT = 'CREATE_LEISTUNGPRIVAT'
export const CREATE_LEISTUNGPRIVAT_SUCCESS = 'CREATE_LEISTUNGPRIVAT_SUCCESS'
export const CREATE_LEISTUNGPRIVAT_FAILURE = 'CREATE_LEISTUNGPRIVAT_FAILURE'
export const UPDATE_LEISTUNGPRIVAT = 'UPDATE_LEISTUNGPRIVAT'
export const UPDATE_LEISTUNGPRIVAT_SUCCESS = 'UPDATE_LEISTUNGPRIVAT_SUCCESS'
export const UPDATE_LEISTUNGPRIVAT_FAILURE = 'UPDATE_LEISTUNGPRIVAT_FAILURE'
export const DELETE_LEISTUNGPRIVAT = 'DELETE_LEISTUNGPRIVAT'
export const DELETE_LEISTUNGPRIVAT_SUCCESS = 'DELETE_LEISTUNGPRIVAT_SUCCESS'
export const DELETE_LEISTUNGPRIVAT_FAILURE = 'DELETE_LEISTUNGPRIVAT_FAILURE'
export const LOAD_LEISTUNGENPRIVAT = 'LOAD_LEISTUNGENPRIVAT'
export const LOAD_LEISTUNGENPRIVAT_SUCCESS = 'LOAD_LEISTUNGENPRIVAT_SUCCESS'
export const LOAD_LEISTUNGENPRIVAT_FAILURE = 'LOAD_LEISTUNGENPRIVAT_FAILURE'

/*
 * TODOs
 **/
export const LOAD_TODOS = 'LOAD_TODOS'
export const LOAD_TODOS_SUCCESS = 'LOAD_TODOS_SUCCESS'
export const LOAD_TODOS_FAILURE = 'LOAD_TODOS_FAILURE'
export const CLEAR_TODOS = 'CLEAR_TODOS'

export const LOAD_TODOS_DATA_DOCUMENTATION = 'LOAD_TODOS_DATA_DOCUMENTATION'
export const LOAD_TODOS_DATA_DOCUMENTATION_SUCCESS = 'LOAD_TODOS_DATA_DOCUMENTATION_SUCCESS'
export const LOAD_TODOS_DATA_DOCUMENTATION_FAILURE = 'LOAD_TODOS_DATA_DOCUMENTATION_FAILURE'

export const LOAD_TODOS_DATA_ANAMNESE = 'LOAD_TODOS_DATA_ANAMNESE'
export const LOAD_TODOS_DATA_ANAMNESE_SUCCESS = 'LOAD_TODOS_DATA_DOCUMENTATION_SUCCESS'
export const LOAD_TODOS_DATA_ANAMNESE_FAILURE = 'LOAD_TODOS_DATA_ANAMNESE_FAILURE'

export const LOAD_TODOS_DATA_FOLGETERMIN = 'LOAD_TODOS_DATA_FOLGETERMIN'
export const LOAD_TODOS_DATA_FOLGETERMIN_SUCCESS = 'LOAD_TODOS_DATA_FOLGETERMIN_SUCCESS'
export const LOAD_TODOS_DATA_FOLGETERMIN_FAILURE = 'LOAD_TODOS_DATA_FOLGETERMIN_FAILURE'

export const LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG = 'LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG'
export const LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG_SUCCESS = 'LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG_SUCCESS'
export const LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG_FAILURE = 'LOAD_TODOS_DATA_VERVOLLSTAENDIGUNG_FAILURE'

export const LOAD_TODOS_DATA_REZEPT = 'LOAD_TODOS_DATA_REZEPT'
export const LOAD_TODOS_DATA_REZEPT_SUCCESS = 'LOAD_TODOS_DATA_REZEPT_SUCCESS'
export const LOAD_TODOS_DATA_REZEPT_FAILURE = 'LOAD_TODOS_DATA_REZEPT_FAILURE'

export const LOAD_TODOS_DATA_THERAPIEBERICHT = 'LOAD_TODOS_DATA_THERAPIEBERICHT'
export const LOAD_TODOS_DATA_THERAPIEBERICHT_SUCCESS = 'LOAD_TODOS_DATA_THERAPIEBERICHT_SUCCESS'
export const LOAD_TODOS_DATA_THERAPIEBERICHT_FAILURE = 'LOAD_TODOS_DATA_THERAPIEBERICHT_FAILURE'

/*
 *   Notifications
 **/
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'

/*
 *   MISC
 **/
export const SELECT_DAY = 'SELECT_DAY'

/*
 *  Changelog
 **/
export const LOAD_CHANGELOGS = 'LOAD_CHANGELOGS'
export const LOAD_CHANGELOGS_SUCCESS = 'LOAD_CHANGELOGS_SUCCESS'
export const LOAD_CHANGELOGS_FAILURE = 'LOAD_CHANGELOGS_FAILURE'

export const LOAD_CHANGELOG = 'LOAD_CHANGELOG'
export const LOAD_CHANGELOG_SUCCESS = 'LOAD_CHANGELOG_SUCCESS'
export const LOAD_CHANGELOG_FAILURE = 'LOAD_CHANGELOG_FAILURE'

export const LOAD_LATEST_CHANGELOG = 'LOAD_LATEST_CHANGELOG'
export const LOAD_LATEST_CHANGELOG_SUCCESS = 'LOAD_LATEST_CHANGELOG_SUCCESS'
export const LOAD_LATEST_CHANGELOG_FAILURE = 'LOAD_LATEST_CHANGELOG_FAILURE'

export const LOAD_CHANGELOG_LAST_SEEN = 'LOAD_CHANGELOG_LAST_SEEN'
export const LOAD_CHANGELOG_LAST_SEEN_SUCCESS = 'LOAD_CHANGELOG_LAST_SEEN_SUCCESS'
export const LOAD_CHANGELOG_LAST_SEEN_FAILURE = 'LOAD_CHANGELOG_LAST_SEEN_FAILURE'

export const UPDATE_CHANGELOG_LAST_SEEN = 'UPDATE_CHANGELOG_LAST_SEEN'
export const UPDATE_CHANGELOG_LAST_SEEN_SUCCESS = 'UPDATE_CHANGELOG_LAST_SEEN_SUCCESS'
export const UPDATE_CHANGELOG_LAST_SEEN_FAILURE = 'UPDATE_CHANGELOG_LAST_SEEN_FAILURE'

/*
 * Praxen
 **/
export const CREATE_PRAXIS = 'CREATE_PRAXIS'
export const CREATE_PRAXIS_SUCCESS = 'CREATE_PRAXIS_SUCCESS'
export const CREATE_PRAXIS_FAILURE = 'CREATE_PRAXIS_FAILURE'

/*
 * Kassenbuch
 * */
export const LOAD_BUCHUNGEN = 'LOAD_BUCHUNGEN'
export const LOAD_BUCHUNGEN_SUCCESS = 'LOAD_BUCHUNGEN_SUCCESS'
export const LOAD_BUCHUNGEN_FAILURE = 'LOAD_BUCHUNGEN_FAILURE'
export const CREATE_BUCHUNG = 'CREATE_BUCHUNG'
export const CREATE_BUCHUNG_SUCCESS = 'CREATE_BUCHUNG_SUCCESS'
export const CREATE_BUCHUNG_FAILURE = 'CREATE_BUCHUNG_FAILURE'
export const LOAD_POSTEN = 'LOAD_POSTEN'
export const LOAD_POSTEN_SUCCESS = 'LOAD_POSTEN_SUCCESS'
export const LOAD_POSTEN_FAILURE = 'LOAD_POSTEN_FAILURE'

/*
 * Zahlungen
 * */
// diese Action ist dazu da, nicht nur in der Zahlungstabelle einen Eintrag zu machen, sondern
// auch die zugehöreigen Zahlungspositionen und (ggf.) den Kassenbucheintrag zu erzeugen
// so kann der Server alles in einer Transaktion erledigen.
export const ZAHLUNG_ERFASSEN = 'ZAHLUNG_ERFASSEN'
export const ZAHLUNG_ERFASSEN_SUCCESS = 'ZAHLUNG_ERFASSEN_SUCCESS'
export const ZAHLUNG_ERFASSEN_FAILURE = 'ZAHLUNG_ERFASSEN_FAILURE'

export const KASSENBUCH_SELECT_DAY = 'KASSENBUCH_SELECT_DAY'

/*
 * Websockets
 **/
export const SOCKET_INITIALIZE = 'SOCKET_INITIALIZE'
export const SOCKET_DESTROY = 'SOCKET_DESTROY'
export const SOCKET_CONNECT = 'SOCKET_CONNECT'
export const SOCKET_CONNECT_ERROR = 'SOCKET_CONNECT_ERROR'
export const SOCKET_CONNECT_TIMEOUT = 'SOCKET_CONNECT_TIMEOUT'
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT'
export const SOCKET_RECONNECT = 'SOCKET_RECONNECT'
export const SOCKET_RECONNECT_ATTEMPT = 'SOCKET_RECONNECT_ATTEMPT'
export const SOCKET_RECONNECTING = 'SOCKET_RECONNECTING'
export const SOCKET_RECONNECT_ERROR = 'SOCKET_RECONNECT_ERROR'
export const SOCKET_RECONNECT_FAILED = 'SOCKET_RECONNECT_FAILED'
export const SOCKET_PING = 'SOCKET_PING'
export const SOCKET_PONG = 'SOCKET_PONG'
export const SOCKET_RECEIVE_DATA = 'SOCKET_RECEIVE_DATA'

/*
 * Selected Termin-Props
 **/
export const SELECT_TERMIN_PROPS = 'SELECT_TERMIN_PROPS'

/*
Example stuff
 */
export const ADD_TEXT = 'ADD_TEXT'
export const INCREASE_COUNT = 'INCREASE_COUNT'
export const INCREASE_COUNT_COMPLETED = 'INCREASE_COUNT_COMPLETED'
export const INCREASE_COUNT_FAILED = 'INCREASE_COUNT_FAILED'

/*
GET DEBITOOR
 */
export const GET_DEBITOOR_API = 'GET_DEBITOOR_API'
export const GET_DEBITOOR_API_COMPLETED = 'GET_DEBITOOR_API_COMPLETED'
export const GET_DEBITOOR_API_FAILED = 'GET_DEBITOOR_API_FAILED'

/*
* SMS
*/
export const GET_SMS_COUNT = 'GET_SMS_COUNT'
export const GET_SMS_COUNT_SUCCESS = 'GET_SMS_COUNT_SUCCESS'
export const GET_SMS_COUNT_FAILURE = 'GET_SMS_COUNT_FAILURE'