import { LocalHospital as LocalHospitalIcon, People as PeopleIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import CustomersList from '../../containers/CustomersList/CustomersList';
import DoctorsList from '../../containers/DoctorsList/DoctorsList';
import StyledLink from '../../shared/components/StyledLink/StyledLink';
import { isCurrentUserAdmin } from '../../shared/utils/users';
import { StyledContacts } from './StyledContacts';

const Contacts = (props): JSX.Element => {
  const { serverHash } = useParams()

  return (
    <StyledContacts>
      <div className="main">
        <div className="topBar">
          <StyledLink
            to={`/${serverHash}/contacts/customers`}
            isRelative
            fullWidth
            startIcon={<PeopleIcon fontSize="inherit" />}
            borderEnd
          >
            Patienten
          </StyledLink>
          <StyledLink
            to={`/${serverHash}/contacts/doctors`}
            isRelative
            fullWidth
            startIcon={<LocalHospitalIcon fontSize="inherit" />}
          >
            Ärzte
          </StyledLink>
        </div>
        <Routes>
          <Route path="/customers" element={<CustomersList {...props} />} />
          <Route path="/doctors" element={<DoctorsList />} />
          <Route path="*" element={<Navigate replace to="./customers" />} />
        </Routes>
      </div>
    </StyledContacts>
  )
};

const mapStateToProps = (state) => ({
  currentUserIsAdmin: isCurrentUserAdmin(state),
});

export default connect(mapStateToProps)(Contacts);
