import { Link, Paper, Tabs, Typography } from '@material-ui/core'
import { EmojiEmotions as EmojiEmotionsIcon } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as dateActions from '../../actions/dates'
import { ISO8601DATE_FNS } from '../../constants/dateFormats'
import TodoStack from '../../containers/TodoStack/TodoStack'
import { DatePickerToolbar } from '../../shared/components/DatePickerToolbar'
import { StyledTab, tabA11yProps, TabPanel } from '../../shared/components/Tabs'
import { StyledCalendarSidebar } from './StyledCalendarSidebar'
import { sApiServer } from '../../shared/utils/auth'
import { sCurrentUserApi } from '../../shared/utils/users'
import { plan } from '../../shared/utils/constants'
import { hasPatientsRemaining } from '../../actions/customers'

const CalendarSidebar: FC<any> = ({
  selectDay,
  selectedDay,
  route,
  navigateOnCalendarTouchTap,
  currentUserIsAdmin,
  currentServer,
  currentUser,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [loadingPlanData, setLoadingPlanData] = useState<boolean>(true)
  const [patientsTotal, setPatientsTotal] = useState<number>(0)
  const [ownerPlanId, setOwnerPlanId] = useState<string | null>(null)
  const [showPlanUpgradeTodo, setShowPlanUpgradeTodo] = useState<boolean>(false)
  const navigate = useNavigate()
  const { serverHash } = useParams()
  const { pathname } = useLocation()

  const getPatientsRemaining = async () => {
    const result = await actions.hasPatientsRemaining()
    setPatientsTotal(result.ownerPatientsCount)
    setOwnerPlanId(result.ownerPlanId)
    console.log('result: ', result)

    setLoadingPlanData(false)
  }

  useEffect(() => {
    setLoadingPlanData(true)

    getPatientsRemaining()
  }, [])

  useEffect(() => {
    if (loadingPlanData) return
    if (!ownerPlanId) return

    const planDataForId = plan.find((p) => p.id === ownerPlanId)

    if (planDataForId) {
      const { upgradeThreshold } = planDataForId
      const remainingPatients = planDataForId.maxNumberOfPatients - patientsTotal
      const isOwner = currentUser?.authUserId === currentServer?.createdBy

      if (isOwner && upgradeThreshold && remainingPatients <= upgradeThreshold) {
        setShowPlanUpgradeTodo(true)
      }
    }
  }, [loadingPlanData, patientsTotal, ownerPlanId])

  const handleTabChange = useCallback((_, value: number): void => setSelectedTab(value), [])

  const handleDayClick = useCallback(
    (date) => {
      const fDate = format(date, ISO8601DATE_FNS)
      if (navigateOnCalendarTouchTap || route?.navigateOnCalendarTouchTap) {
        navigate(`/${serverHash}/calendar/day/${fDate}`)
      } else {
        selectDay(date)
        pathname.includes('day') && navigate(`/${serverHash}/calendar/day/${fDate}`)
      }
    },
    [pathname, serverHash, route, navigateOnCalendarTouchTap],
  )

  return (
    <StyledCalendarSidebar>
      <DatePicker variant="static" value={selectedDay} onChange={handleDayClick} ToolbarComponent={DatePickerToolbar} />
      <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="secondary" variant="fullWidth">
        <StyledTab label="To-Do" {...tabA11yProps(0)} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        {showPlanUpgradeTodo && (
          <Paper className="payWhatYouWant" elevation={0}>
            <Typography variant="subtitle1" className="payWhatYouWant__title">
              Patientenlimit fast erreicht
            </Typography>
            <Typography variant="body2">
              <Link
                color="secondary"
                href={
                  process.env.NODE_ENV === 'production'
                    ? 'https://pododesk.de/fuexc_unidy/start'
                    : 'https://staging.pododesk.de/fuexc_unidy/start'
                }
                target="_self"
              >
                Jetzt Planmodell upgraden
              </Link>
            </Typography>
          </Paper>
        )}
        <TodoStack />
      </TabPanel>
    </StyledCalendarSidebar>
  )
}

const mapStateToProps = (state) => ({
  selectedDay: state.selectedDay,
  currentServer: sApiServer(state),
  currentUser: sCurrentUserApi(state),
})

const mapDispatchToProps = (dispatch) => ({
  selectDay: bindActionCreators(dateActions.selectDay, dispatch),
  actions: {
    hasPatientsRemaining: bindActionCreators(hasPatientsRemaining, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(CalendarSidebar))
