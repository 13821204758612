import { Button, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { StyledNoPlanDialog } from './StyledNoPlanDialog'
import type { ReactNode } from 'react'

interface Props {
  open: boolean
  showReadOnlyButton?: boolean
  onCloseFunc: (...params) => any
  title?: string
  content?: ReactNode
}

export const NoPlanDialog = ({ open, onCloseFunc, title, content, showReadOnlyButton = false }: Props): JSX.Element => {
  const navigate = useNavigate()
  const handleDialogClose = (): void => {
    onCloseFunc()
  }

  const setSessionReadOnly = () => {
    sessionStorage.setItem('readOnly', 'true')

    handleDialogClose()
  }

  const handleCSVLinkClick = () => {
    setSessionReadOnly()
    window['fcWidget']?.open()
  }

  return (
    <StyledNoPlanDialog open={open}>
      <DialogTitle className="dialogTitle">{title}</DialogTitle>
      <DialogContent className="dialogContent">
        {content}
        {showReadOnlyButton && (
          <Typography variant="body1" gutterBottom>
            Um einen CSV-Export Ihrer Daten anzufordern,{' '}
            <Link
              target="_self"
              rel="noreferrer noopener"
              color="secondary"
              onClick={handleCSVLinkClick}
              style={{ cursor: 'pointer' }}
            >
              klicken Sie hier
            </Link>
            .
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="dialogActions">
        {showReadOnlyButton && (
          <Button variant="contained" type="button" color="secondary" onClick={setSessionReadOnly}>
            Lesezugriff
          </Button>
        )}
      </DialogActions>
    </StyledNoPlanDialog>
  )
}
