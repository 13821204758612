import { ListItemIcon, MenuItem } from '@material-ui/core'
import {
  Assignment as AssignmentIcon,
  CalendarToday as CalendarTodayIcon,
  Create as CreateIcon,
  Eco as EcoIcon,
  LocalHospital as LocalHospitalIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons'
import { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { selectCustomer } from '../../actions/customers'
import { showNotification } from '../../actions/notification'
import { apiServerHashSelector, fetchSecure, sApiServer, sCredentials } from '../../shared/utils/auth'
import { getApiUrl } from '../../utils/auth'
import { StyledListItemText } from './StyledListItemText'
import { isReadOnly } from '../../utils/helpers'

const MenuItemsPatient: FC<any> = ({ actions, patientId, anamnesen, onCloseFunc, api, currentServer }) => {
  const navigate = useNavigate()
  const { serverHash } = useParams()

  const [anamneseVorhanden, setAnamneseVorhanden] = useState<boolean>(false)

  useEffect(() => {
    const anamnesenVonPatient = Object.keys(anamnesen)
      .map((key) => anamnesen[key])
      .filter((anamnese) => anamnese.patient === patientId)
    const newAnamneseVorhanden = anamnesenVonPatient.length > 0
    setAnamneseVorhanden(newAnamneseVorhanden)
  }, [anamnesen])

  const viewPatient = (event, id) => {
    event?.stopPropagation()
    onCloseFunc()
    navigate(`/${serverHash}/contacts/customers/${id}`)
  }

  const editAnamnese = (event, id) => {
    event?.stopPropagation()
    onCloseFunc()
    navigate(`/${serverHash}/anamnesis/${id}/01`)
  }

  const addDate = (event, id) => {
    event?.stopPropagation()
    actions.selectCustomer(id)
    onCloseFunc()
    navigate(`/${serverHash}/calendar/free`)
  }

  const addHVO = (event, id) => {
    event?.stopPropagation()
    onCloseFunc()
    navigate(`/${serverHash}/hvo/add/${id}`)
  }

  const addTherapiebericht = (event, id) => {
    event?.stopPropagation()
    onCloseFunc()
    navigate(`/${serverHash}/reports/add/${id}`)
  }

  const getAppointmentPDF = async (event, id) => {
    event?.stopPropagation()
    onCloseFunc()
    const { apiUrl, auth0Credentials } = api
    const URL = `${apiUrl}/termine/getAppointmentPDF/${id}`
    actions.showNotification('Terminzettel wird in neuem Tab geöffnet. Bitte Pop-Up-Blocker beachten!')
    const response = await fetchSecure(URL, { credentials: 'include' }, auth0Credentials)
    const pdfBlob = await response.blob()
    const fileUrl = window.URL.createObjectURL(pdfBlob)
    window.open(fileUrl)
  }

  return (
    <div>
      <MenuItem onClick={(event) => viewPatient(event, patientId)}>
        <ListItemIcon>
          <VisibilityIcon color="secondary" />
        </ListItemIcon>
        <StyledListItemText primary="Patientenkartei anzeigen" />
      </MenuItem>
      {!!currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name !== 'calendar' && (
        <>
          {!isReadOnly() ? (
            <MenuItem onClick={(event) => editAnamnese(event, patientId)}>
              <ListItemIcon>
                <AssignmentIcon color="secondary" />
              </ListItemIcon>
              <StyledListItemText primary={anamneseVorhanden ? 'Anamnese anzeigen' : 'Anamnese erstellen'} />
            </MenuItem>
          ) : anamneseVorhanden ? (
            <MenuItem onClick={(event) => editAnamnese(event, patientId)}>
              <ListItemIcon>
                <AssignmentIcon color="secondary" />
              </ListItemIcon>
              <StyledListItemText primary="Anamnese anzeigen" />
            </MenuItem>
          ) : null}
        </>
      )}
      {!isReadOnly() && (
        <>
          {!!currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name !== 'calendar' && (
            <>
              <MenuItem onClick={(event) => addHVO(event, patientId)}>
                <ListItemIcon>
                  <EcoIcon color="secondary" />
                </ListItemIcon>
                <StyledListItemText primary="neue HVO erfassen" />
              </MenuItem>
              <MenuItem onClick={(event) => addTherapiebericht(event, patientId)}>
                <ListItemIcon>
                  <LocalHospitalIcon color="secondary" />
                </ListItemIcon>
                <StyledListItemText primary="Therapiebericht erfassen" />
              </MenuItem>
            </>
          )}
          <MenuItem onClick={(event) => addDate(event, patientId)}>
            <ListItemIcon>
              <CalendarTodayIcon color="secondary" />
            </ListItemIcon>
            <StyledListItemText primary="Termin vereinbaren" />
          </MenuItem>
          <MenuItem onClick={(event) => getAppointmentPDF(event, patientId)}>
            <ListItemIcon>
              <CreateIcon color="secondary" />
            </ListItemIcon>
            <StyledListItemText primary="Terminzettel erstellen" />
          </MenuItem>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  anamnesen: state.entities.anamnesen,
  api: {
    apiUrl: getApiUrl(apiServerHashSelector(state)),
    auth0Credentials: sCredentials(),
  },
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    selectCustomer: bindActionCreators(selectCustomer, dispatch),
    showNotification: bindActionCreators(showNotification, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemsPatient)
