import {
  addHours,
  addMinutes,
  differenceInMinutes,
  getMinutes,
  isAfter,
  isBefore,
  isSameMinute,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns'
import lodash from 'lodash'
import memoize from 'lodash/memoize'
import { memo, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import shortid from 'shortid'
import { isPropsEqual } from '../../utils/functions'
import AllDayItems from '../AllDayItems/AllDayItems'
import CalendarDateDnD, { CalendarDateNoDND } from '../CalendarDate'
import { mkSelectorShadeColumn, sEventsAndAllDayTermineDay, sEventsAndAllDayTermineDayAndTherapist } from './selectors'
import { StyledCalendarColumn } from './StyledCalendarColumn'
import { sApiServer } from '../../shared/utils/auth'
import { isReadOnly } from '../../utils/helpers'

interface FreeDatesResult {
  beginn?: Date
  ende?: Date
  flavor?: string
  isFreeDate?: boolean
  therapeut?: number
}

const getCalendarDateComponent = (dragndrop: boolean) => (dragndrop ? CalendarDateDnD : CalendarDateNoDND)

const objHash = (date): string => {
  let string = ''
  for (const key in date) {
    string += date[key]
  }
  return string
}

const fulfillsMinDuration = (begin, end, dateMinDuration): boolean =>
  isAfter(end, addMinutes(begin, dateMinDuration - 1))

const fillTimeframeWithDates = memoize(
  (begin, end, interval, baseDate = {}) => {
    const result: Array<FreeDatesResult> = []
    const beginMinutes = getMinutes(begin)

    const durationMinutes = differenceInMinutes(end, begin)
    const numberIntervals = Math.ceil(durationMinutes / interval)
    const minutesTilFullHour = 60 - beginMinutes
    const remainingMinutesBefore = minutesTilFullHour % interval
    const remainingMinutesAfter = durationMinutes - (numberIntervals * interval + remainingMinutesBefore)

    let time = begin
    let plusInterval: Date | null = null
    for (let iA = 0; iA < numberIntervals; iA++) {
      plusInterval = addMinutes(
        time,
        interval + (iA === 0 ? remainingMinutesBefore : 0) + (iA === numberIntervals - 1 ? remainingMinutesAfter : 0),
      )

      result.push({
        ...baseDate,
        beginn: time,
        ende: plusInterval,
      })

      time = plusInterval
    }

    return result
  },
  (begin, end, interval, baseDate) => '' + begin + end + interval + objHash(baseDate),
)

const calcFreeDates = memoize(
  (dates, { dateMinDuration, datesInterval, firstHour, numHours }, columnDay, baseDate = {}) => {
    const datesAr = dates.filter((date) => !date.isAllDay)

    if (datesAr.length <= 0) {
      const begin = setHours(setMinutes(setSeconds(columnDay, 0), 0), firstHour)
      const end = addHours(begin, numHours)
      return fillTimeframeWithDates(begin, end, datesInterval, baseDate)
    }

    datesAr.sort((dateA, dateB) => {
      const mA = dateA.beginn
      const mB = dateB.beginn
      if (isBefore(mA, mB)) return -1

      if (isAfter(mA, mB)) return 1

      return 0
    })

    const free = datesAr.reduce((previousValue, currentValue, currentIndex, ar) => {
      const mBegin = currentValue.beginn
      const mEnd = currentValue.ende
      const mPrevEnd = ar[currentIndex - 1] ? ar[currentIndex - 1].ende : false
      const mDayBegin = setHours(setMinutes(setSeconds(mBegin, 0), 0), firstHour)
      const mDayEnd = addHours(mDayBegin, numHours)
      const first = currentIndex === 0
      const last = currentIndex === ar.length - 1
      const beginsWithSchedule = isSameMinute(mBegin, mDayBegin)
      const endsWithSchedule = isSameMinute(mEnd, mDayEnd)
      const beginsRightAfterLastDate = mPrevEnd ? isSameMinute(mBegin, mPrevEnd) : false

      if (first && !beginsWithSchedule && fulfillsMinDuration(mDayBegin, mBegin, dateMinDuration)) {
        previousValue.push(fillTimeframeWithDates(mDayBegin, mBegin, datesInterval, baseDate))
      }

      if (!first && !beginsRightAfterLastDate && fulfillsMinDuration(mPrevEnd, mBegin, dateMinDuration)) {
        previousValue.push(fillTimeframeWithDates(mPrevEnd, mBegin, datesInterval, baseDate))
      }

      if (last && !endsWithSchedule && fulfillsMinDuration(mEnd, mDayEnd, dateMinDuration)) {
        previousValue.push(fillTimeframeWithDates(mEnd, mDayEnd, datesInterval, baseDate))
      }

      return previousValue
    }, [])

    return free
  },
  (dates, { dateMinDuration, datesInterval, firstHour, numHours }, columnDay, baseDate) => {
    const res =
      dates.map(objHash).join() + dateMinDuration + datesInterval + firstHour + numHours + columnDay + objHash(baseDate)

    return res
  },
)

const CalendarColumn = ({
  children,
  columnDay = new Date(),
  dates = [],
  gridConfig,
  markCurrentTime,
  onFreeDateClick,
  onDateClick,
  allDayHideText = false,
  person,
  room,
  disableDragNDrop = false,
  locked,
  eventsAndAllDayTermineDayAndTherapist,
  currentTime,
  shadeColumn,
  allDayGapHeight,
  onIcsItemClick,
  dialogActions,
  setUseWideColumns,
  currentServer,
}): JSX.Element => {
  const location = useLocation()
  const markCurrentTimeCalc = ({ markCurrentTime, gridConfig, person, currentTime, room }): JSX.Element | boolean => {
    if (!markCurrentTime) return false

    const CalendarDate = getCalendarDateComponent(false)

    return (
      <CalendarDate
        gridConfig={gridConfig}
        termin={{
          flavor: 'currentTime',
          beginn: currentTime,
          end: currentTime,
        }}
        therapeutId={person ? person.id : null}
        roomId={room ? room.id : null}
        dialogActions={dialogActions}
      />
    )
  }

  const freeDates = !isReadOnly()
    ? calcFreeDates(dates, gridConfig, columnDay, {
        flavor: 'fadeplus',
        isFreeDate: true,
        therapeut: person ? person.id : null,
        room: room ? room.id : null,
      })
    : []

  const flattenFreeDates = lodash.flatten<any>(freeDates)

  let allDates = lodash.concat(dates, flattenFreeDates).map((item: any) => ({
    ...(item || {}),
    _key: shortid.generate(),
  }))

  const CalendarDate = getCalendarDateComponent(!disableDragNDrop)

  useEffect(() => {
    if (setUseWideColumns) {
      if (eventsAndAllDayTermineDayAndTherapist.length > 0) {
        setUseWideColumns(true)
      }
    }
  }, [])

  return (
    <StyledCalendarColumn gridConfig={gridConfig} shadeColumn={shadeColumn} allDayGapHeight={allDayGapHeight}>
      <div className="header">{children}</div>
      <div className="allDayGap">
        {eventsAndAllDayTermineDayAndTherapist.length > 0 && (
          <AllDayItems
            hideText={allDayHideText}
            items={eventsAndAllDayTermineDayAndTherapist}
            onDateClick={onDateClick}
            onIcsItemClick={onIcsItemClick}
          />
        )}
      </div>
      <div className="body">
        {(locked ? [] : allDates).map((termin: Appointment) => {
          if (termin.isAllDay) {
            return null
          }

          return (
            <CalendarDate
              gridConfig={gridConfig}
              key={termin._key}
              onClick={termin.isFreeDate ? onFreeDateClick : onDateClick}
              termin={termin}
              therapeutId={person ? person.id : null}
              roomId={room ? room.id : null}
              dialogActions={dialogActions}
              roomView={location?.pathname?.includes('/rooms')}
            />
          )
        })}
        {markCurrentTimeCalc({ markCurrentTime, gridConfig, person, currentTime, room })}
      </div>
    </StyledCalendarColumn>
  )
}

const mapStateToProps = () => {
  const sShadeColumn = mkSelectorShadeColumn()

  return (state, props) => ({
    currentServer: sApiServer(state),
    locked: state.lockscreen.locked,
    shadeColumn: sShadeColumn(state, props),
    currentTime: state.time.currentTime,
    eventsAndAllDayTermineDay: sEventsAndAllDayTermineDay(state, props),
    eventsAndAllDayTermineDayAndTherapist: sEventsAndAllDayTermineDayAndTherapist(state, props),
  })
}

const shouldRerender = (prevProps, nextProps) => {
  const propKeysToCmp = [
    'person.id',
    'room.id',
    'dates',
    'markCurrentTime',
    'gridConfig',
    'shadeColumn',
    'columnDay',
    'currentTime',
  ]

  return isPropsEqual(prevProps, nextProps, propKeysToCmp)
}

export default connect(mapStateToProps)(memo(CalendarColumn, shouldRerender))
