import { Button, CardContent, ClickAwayListener, Typography } from '@material-ui/core'
import { Add as AddIcon, ArrowForward as ArrowForwardIcon } from '@material-ui/icons'
import { Warning as WarningIcon } from '@material-ui/icons'
import RuckInfoIcon from '../../../assets/icons/RUCK_i.svg'
import { useState } from 'react'
import InputField from '../../../shared/components/InputField/InputField'
import { StyledNewPraxis } from './StyledNewPraxis'
import { EnterPracticeButton } from '../PraxisCard/StyledPraxisCard'

const NewPraxisCard = ({ onSubmit, className, remainingPractices }): JSX.Element => {
  const [showInput, setShowInput] = useState<boolean>(false)
  const [touched, setTouched] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const isTextInvalid = () => {
    const charLimit = 70
    const errorMessage = `Max. ${charLimit} Zeichen erlaubt!`

    if (inputValue && inputValue.length > charLimit) return errorMessage

    return ''
  }

  const reset = () => {
    setTouched(false)
    setInputValue('')
    setShowInput(false)
  }

  const submit = () => {
    onSubmit(inputValue.trim())
    reset()
  }

  const onKeyDown = (e) => {
    const ENTER_KEY_CODE = 13
    if (e.which === ENTER_KEY_CODE && inputValue.trim() !== '') {
      submit()
    }
  }

  const handleClickAway = () => {
    setInputValue('')
    setShowInput(false)
    setTouched(false)
  }

  const unhideInput = () => {
    if (remainingPractices <= 0) {
      const url =
        process.env.NODE_ENV === 'production'
          ? 'https://pododesk.de/fuexc_unidy/start'
          : 'https://staging.pododesk.de/fuexc_unidy/start'
      window.open(url, '_self')
    } else {
      setShowInput(true)
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledNewPraxis className={className} square onClick={unhideInput}>
        <CardContent className="cardContent">
          {remainingPractices <= 0 ? (
            <div className="cardContentInner">
              <div>
                <RuckInfoIcon className="ruckInfoIcon" />
                <Typography variant="h6">Limit erreicht</Typography>
                <Typography variant="body2">
                  Sie haben die maximale Anzahl an Praxen erreicht. Bitte upgraden Sie Ihr Planmodell um weitere
                  anzulegen.
                </Typography>
              </div>
              <EnterPracticeButton color="secondary" variant="contained" startIcon={<ArrowForwardIcon />}>
                Planmodell upgraden
              </EnterPracticeButton>
            </div>
          ) : (
            <>
              <AddIcon className="addIcon" />
              <div className="title">Neue Praxis erstellen</div>
              <div className="subtitle">(deren Inhaber ich bin)</div>
              {showInput && (
                <div className="inputArea">
                  <InputField
                    onChange={(value) => setInputValue(String(value || ''))}
                    value={inputValue}
                    onKeyDown={onKeyDown}
                    autoFocus
                    onFocus={() => setTouched(true)}
                    error={touched && !!isTextInvalid()}
                    helperText={touched && !!isTextInvalid() ? isTextInvalid() : ''}
                    placeholder="Firmenbezeichnung Ihrer Praxis"
                    InputProps={{ className: 'textFieldInput' }}
                  />
                  {inputValue.trim() && (
                    <Button
                      type="button"
                      className="button"
                      variant="contained"
                      color="primary"
                      onClick={submit}
                      disabled={!!isTextInvalid()}
                    >
                      Erstellen
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </CardContent>
      </StyledNewPraxis>
    </ClickAwayListener>
  )
}

export default NewPraxisCard
