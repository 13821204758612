/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import InputField from '../../../shared/components/InputField/InputField'

import CheckboxXOR from '../../../shared/components/CheckboxXOR'
import CheckboxList from '../../CheckboxList/CheckboxList'
import DividerLink from '../components/DividerLink'
import FieldContainer from '../components/FieldContainer'

import HNBListe from '../HNBListe'
import { StyledAnamnesisForm } from '../styles'
import { fields } from '../utils'

const hnItems = [
  { value: 'R1', label: 'R1' },
  { value: 'R2', label: 'R2' },
  { value: 'R3', label: 'R3' },
  { value: 'R4', label: 'R4' },
  { value: 'R5', label: 'R5' },
  { value: 'L1', label: 'L1' },
  { value: 'L2', label: 'L2' },
  { value: 'L3', label: 'L3' },
  { value: 'L4', label: 'L4' },
  { value: 'L5', label: 'L5' },
]

const AF09 = ({
  fields: {
    hnAuffaelligkeiten,
    hnAuffaelligkeitenUnguisConvolutus,
    hnAuffaelligkeitenUnguisIncarnatus,
    hnAuffaelligkeitenWeitere,
    hnAuffaelligkeitenKommentar,
    hnBisherigePflegeUndPilzmittel,
    hnBisherigePflegeUndPilzmittelKommentar,
    patient,
  },
  readOnly,
}) => {
  const { serverHash } = useParams()

  return (
    <StyledAnamnesisForm autoComplete="off">
      <div className="outer">
        <div className="inner">
          <div className="columnFullWidth">
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/08`} iconDirection="up" />

            <CheckboxXOR
              {...hnAuffaelligkeiten}
              className="checkboxXORHead"
              label="Auffälligkeiten?"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />
          </div>
          <div className="column">
            {hnAuffaelligkeiten.value && (
              <FieldContainer label="Unguis Convolutus (Eingerollter Nagel)?" className="fieldContainerVariableHeight">
                <CheckboxList
                  {...hnAuffaelligkeitenUnguisConvolutus}
                  className="checkboxList"
                  items={hnItems}
                  disabled={readOnly}
                />
              </FieldContainer>
            )}
          </div>
          <div className="column">
            {hnAuffaelligkeiten.value && (
              <FieldContainer
                label="Unguis Incarnatus (Eingewachsener Nagel)?"
                className="fieldContainerVariableHeight"
              >
                <CheckboxList
                  className="checkboxList"
                  items={hnItems}
                  {...hnAuffaelligkeitenUnguisIncarnatus}
                  disabled={readOnly}
                />
              </FieldContainer>
            )}
          </div>
          <div className="columnFullWidth">
            {hnAuffaelligkeiten.value && (
              <div>
                <label className="label">Weitere Auffälligkeiten:</label> {/* Other abnormalities: */}
                <HNBListe {...hnAuffaelligkeitenWeitere} readOnly={readOnly} />
              </div>
            )}
          </div>
          <div className="columnFullWidth">
            <InputField {...hnAuffaelligkeitenKommentar} multiline rows={5} label="Kommentar" readOnly={readOnly} />
            <CheckboxXOR
              {...hnBisherigePflegeUndPilzmittel}
              className="checkboxXORHead"
              label="Bisherige Pflege- & Pilzmittel?"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />
            <InputField
              {...hnBisherigePflegeUndPilzmittelKommentar}
              multiline
              rows={5}
              label="Kommentar"
              readOnly={readOnly}
            />
          </div>
        </div>
      </div>
    </StyledAnamnesisForm>
  )
}

const validate = (values) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  return errors
}

export default reduxForm({
  form: 'anamneseForm',
  validate,
  fields,
  destroyOnUnmount: false,
})(AF09)
