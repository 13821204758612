/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import CheckboxXOR from '../../../shared/components/CheckboxXOR'
import DateField from '../../../shared/components/DateField/DateField'
import InputField from '../../../shared/components/InputField/InputField'
import { SelectField } from '../../../shared/components/SelectField/SelectField'
import { validateDateFieldNotInFuture } from '../../../utils/validation'

import DividerLink from '../components/DividerLink'
import FieldContainer from '../components/FieldContainer'
import { StyledAnamnesisForm } from '../styles'
import { fields } from '../utils'

const seEinlagenArtItems = [
  { value: 'Orthopädisch - weich', text: 'Orthopädisch - weich' },
  { value: 'Orthopädisch - hart', text: 'Orthopädisch - hart' },
  { value: 'Diabetisch', text: 'Diabetisch' },
]

const seSchuheArtItems = [
  { value: 'Diabetikerschuh', text: 'Diabetikerschuh' },
  { value: 'Orthopädischer Maßschuh', text: 'Orthopädischer Maßschuh' },
]

const AF06 = ({
  fields: {
    seEinlagen,
    seEinlagenArt,
    seEinlagenAngefertigtAm,
    seEinlagenKommentar,
    seSchuhe,
    seSchuheArt,
    seSchuheKommentar,
    patient,
  },
  readOnly,
}) => {
  const { serverHash } = useParams()

  return (
    <StyledAnamnesisForm autoComplete="off">
      <div className="outer">
        <div className="inner">
          <div className="columnFullWidth">
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/05`} iconDirection="up" />

            <CheckboxXOR
              {...seEinlagen}
              className="checkboxXORHead"
              label="Orthopädische- oder Diabetische Einlagen?"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />

            {seEinlagen.value && (
              <>
                <FieldContainer label="Art der Einlagen:" pullLeft>
                  <SelectField {...seEinlagenArt} options={seEinlagenArtItems} disabled={readOnly} />
                </FieldContainer>

                <FieldContainer label="Angefertigt am:" pullLeft>
                  <DateField
                    {...seEinlagenAngefertigtAm}
                    onChange={(date) => {
                      seEinlagenAngefertigtAm.onChange(date)
                      seEinlagenAngefertigtAm.onBlur(date)
                    }}
                    helperText={
                      !!seEinlagenAngefertigtAm.touched && !!seEinlagenAngefertigtAm.error
                        ? seEinlagenAngefertigtAm.error
                        : ''
                    }
                    error={!!seEinlagenAngefertigtAm.touched && !!seEinlagenAngefertigtAm.error}
                    fullWidth
                    disableFuture
                    readOnly={readOnly}
                  />
                </FieldContainer>
              </>
            )}

            <InputField {...seEinlagenKommentar} multiline rows={5} label="Kommentar" readOnly={readOnly} />

            <CheckboxXOR
              {...seSchuhe}
              label="Orthopädische- oder Diabetische Schuhe?"
              className="checkboxXORHead"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />

            {seSchuhe.value && (
              <FieldContainer label="Art der Schuhe:" pullLeft>
                <SelectField {...seSchuheArt} options={seSchuheArtItems} disabled={readOnly} />
              </FieldContainer>
            )}

            <InputField {...seSchuheKommentar} multiline rows={5} label="Kommentar" readOnly={readOnly} />

            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/07`} iconDirection="down" />
          </div>
        </div>
      </div>
    </StyledAnamnesisForm>
  )
}

const validate = (values, props) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  return {
    ...errors,
    ...validateDateFieldNotInFuture(values, props, ['seEinlagenAngefertigtAm']),
  }
}

export default reduxForm({
  form: 'anamneseForm',
  validate,
  fields,
  destroyOnUnmount: false,
})(AF06)
