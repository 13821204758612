import { Dialog, Link } from '@material-ui/core'
import styled from 'styled-components'

export const DialogContainer = styled(Dialog)`
  .MuiDialog-paper {
    max-height: 90vh;
  }
`

export const DialogTextContainer = styled.div`
  padding: 0 24px 12px 24px;
`

export const DialogLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const DialogLink = styled(Link)`
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  user-select: none;
`
