import { FC } from 'react';
import shortid from 'shortid';
import { StyledCalendarColumnSpacer } from './StyledCalendarColumnSpacer';

interface Props {
  flavor?: string;
  gridConfig?: any;
  allDayGapHeight?: number;
}

const formatHour = (hour, shouldFormatBig) => {
  if (!shouldFormatBig) {
    return <span className="spacerText">{hour}</span>;
  }

  const withZeros = hour < 10 ? `0${hour}` : hour;
  return (
    <span className="spacerText">
      <span className="spacerBigHour">{withZeros}</span>
      <span className="spacerMinute">00</span>
    </span>
  );
};

const CalendarColumnSpacer: FC<Props> = ({ flavor = 'line', gridConfig, children, allDayGapHeight = 0 }): JSX.Element => {
  const renderSpacer = () => {
    let timesBig = false;

    switch (flavor) {
      case 'line':
        return <div className="spacerLine" />;

      case 'timesBig':
        timesBig = true;
      // fall through

      case 'times':
        const { firstHour, numHours, gridRowMinutes } = gridConfig;

        const minuteMarkersCount = Math.floor(60 / gridRowMinutes) - 1;
        const minutes = new Array(minuteMarkersCount)
          .fill(null)
          .map(_ => ({ _key: shortid.generate(), value: gridRowMinutes}));
        
        const minuteMarkers = (_hourKey: string) => minutes.map((minute, index) => (
          <div className="spacerMinute row" key={`${_hourKey}_${minute._key}`}>
            <span className="spacerText">{minute.value * (index + 1)}</span>
          </div>
        ));

        const hours = new Array(numHours)
          .fill(null)
          .map(_ => ({ _key: shortid.generate(), value: firstHour}));
          

        const hourMarkers = hours.map((hour, index) => [
          <div className="spacerHour row" key={hour._key}>
            {formatHour(hour.value + index, timesBig)}
          </div>,
          ...minuteMarkers(hour._key),
        ]);

        return hourMarkers;

      default:
        return [];
    }
  };

  return (
    <StyledCalendarColumnSpacer gridConfig={gridConfig} allDayGapHeight={allDayGapHeight}>
      {flavor !== 'line' ? (
        <div className={children ? 'spacerHeader' : 'spacerHeader line'}>
          {children ?? <div className="spacerLine" />}
        </div>
      ) : null}
      <div className="spacerBody">
        {renderSpacer()}
      </div>
    </StyledCalendarColumnSpacer>
  );
};

export default CalendarColumnSpacer;
