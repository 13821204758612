/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import CheckboxXOR from '../../../shared/components/CheckboxXOR'
import InputField from '../../../shared/components/InputField/InputField'

import DividerLink from '../components/DividerLink'
import FieldContainer from '../components/FieldContainer'
import { StyledAnamnesisForm } from '../styles'
import { fields } from '../utils'

const AF04 = ({ fields: { kk, kkKrampfadern, kkKompressionsstruempfe, kkOedeme, kkKommentar, patient }, readOnly }) => {
  const { serverHash } = useParams()

  return (
    <StyledAnamnesisForm autoComplete="off">
      <div className="outer">
        <div className="inner">
          <div className="columnFullWidth">
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/03`} iconDirection="up" />
            <CheckboxXOR
              className="checkboxXORHead"
              label="Krampfadern, Kompressionsstrümpfe oder Ödeme?"
              labelNo="Nein"
              labelYes="Ja"
              {...kk}
              disabled={readOnly}
            />

            {kk.value && (
              <>
                <FieldContainer label="Krampfadern?" pullLeft>
                  <CheckboxXOR {...kkKrampfadern} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>
                <FieldContainer label="Kompressionsstrümpfe" pullLeft>
                  <CheckboxXOR {...kkKompressionsstruempfe} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>
                <FieldContainer label="Ödeme?" pullLeft>
                  <CheckboxXOR {...kkOedeme} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>
              </>
            )}

            <InputField {...kkKommentar} label="Kommentar" multiline rows={5} readOnly={readOnly} />

            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/05`} iconDirection="down" />
          </div>
        </div>
      </div>
    </StyledAnamnesisForm>
  )
}

const validate = (values) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  return errors
}

export default reduxForm({
  form: 'anamneseForm',
  validate,
  fields,
  destroyOnUnmount: false,
})(AF04)
