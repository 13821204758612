import { Typography } from '@material-ui/core'
import {
  InnerTableHeadCellContent,
  TableBody,
  TableCell,
  TableCellMultiline,
  TableContainer,
  TableHeadCell,
  TableHeadCellContent,
  TableRow,
} from './StyledPlanTable'
import { getCurrencyInEuros } from '../../utils/form'
import { calculateTotalPatientsCount } from '../../utils/helpers'

export const PlanTable = ({ plan, apiServers, smsCount }): JSX.Element => {
  return (
    <TableContainer>
      <thead>
        <TableRow>
          <TableHeadCell colSpan={3}>
            <TableHeadCellContent>
              <img src="https://media.hellmut-ruck.de/media/56/42/a4/1719214384/pododesk-Icon.svg" loading="eager" />{' '}
              <InnerTableHeadCellContent>
                <Typography variant="h4" color="secondary" style={{ fontWeight: 'bold' }}>
                  {plan?.name}
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  pododesk Plan
                </Typography>
                <Typography variant="body1">{plan?.articleNo}</Typography>
              </InnerTableHeadCellContent>
            </TableHeadCellContent>
          </TableHeadCell>
        </TableRow>
      </thead>
      <TableBody>
        <TableRow>
          <TableCell>Anzahl Patient:innen*</TableCell>
          <TableCell>
            {plan?.maxNumberOfPatientsPrefix}
            {plan?.maxNumberOfPatients ? ` ${plan.maxNumberOfPatients}` : ''}
          </TableCell>
          <TableCell>
            {plan?.maxNumberOfPatients
              ? `${(plan?.maxNumberOfPatients || 0) - calculateTotalPatientsCount(apiServers)} verbleibend}`
              : ''}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Anzahl Praxen</TableCell>
          <TableCell>
            {plan?.maxNumberOfPracticesPrefix ? `${plan.maxNumberOfPracticesPrefix} ` : ''}
            {plan?.maxNumberOfPractices ?? ''}
          </TableCell>
          <TableCell>
            {plan?.maxNumberOfPractices
              ? plan.maxNumberOfPractices - apiServers.length < 0
                ? '0 verbleibend'
                : `${plan.maxNumberOfPractices - apiServers.length} verbleibend`
              : ''}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <TableCellMultiline>
              Support Inklusivminuten pro Monat
              <Typography variant="body2">Preis je weitere Minute 1,30 € zzgl. MwSt.</Typography>
            </TableCellMultiline>
          </TableCell>
          <TableCell>{plan?.supportTime}</TableCell>
          <TableCell>{String.fromCharCode(8212)}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <TableCellMultiline>
              Support Erreichbarkeit
              <Typography variant="body2">von Montag bis Freitag</Typography>
            </TableCellMultiline>
          </TableCell>
          <TableCell>{plan?.supportReachability}</TableCell>
          <TableCell />
        </TableRow>

        <TableRow>
          <TableCell>SMS-Erinnerung (aktueller Monat)</TableCell>
          <TableCell>
            {typeof plan?.smsPrice !== 'number' ? String.fromCharCode(8212) : `${getCurrencyInEuros(plan.smsPrice)}€`}
          </TableCell>
          <TableCell>Versendet: {smsCount.totalSmsCount}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Schnittstelle zur Krankenkassen-Abrechnung (optional)</TableCell>
          <TableCell>
            {plan?.kkInterfaceAvailable ? (plan?.kkInterfaceIncluded ? 'gebucht' : 'nicht gebucht') : 'nicht verfügbar'}
          </TableCell>
          <TableCell />
        </TableRow>
      </TableBody>
    </TableContainer>
  )
}
