/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import CheckboxXOR from '../../../shared/components/CheckboxXOR'
import InputField from '../../../shared/components/InputField/InputField'
import { SelectField } from '../../../shared/components/SelectField/SelectField'

import DividerLink from '../components/DividerLink'
import FieldContainer from '../components/FieldContainer'
import { StyledAnamnesisForm } from '../styles'
import { fields } from '../utils'

const nsItems = [
  { value: '0/8', text: '0/8' },
  { value: '1/8', text: '1/8' },
  { value: '2/8', text: '2/8' },
  { value: '3/8', text: '3/8' },
  { value: '4/8', text: '4/8' },
  { value: '5/8', text: '5/8' },
  { value: '6/8', text: '6/8' },
  { value: '7/8', text: '7/8' },
  { value: '8/8', text: '8/8' },
  { value: 'nicht ermittelt', text: 'nicht ermittelt' },
]

const nsPosNegItems = [
  { value: 'positiv', text: 'positiv' },
  { value: 'negativ', text: 'negativ' },
  { value: 'nicht ermittelt', text: 'nicht ermittelt' },
]

const AF07 = ({
  fields: {
    nsErhoben,
    nsMallMedLinks,
    nsMallMedRechts,
    nsHalluxLinks,
    nsHalluxRechts,
    nsApexLinks,
    nsApexRechts,
    nsWaermeKaelteEmpfinden,
    nsMonofilament,
    nsKommentar,
    patient,
  },
  readOnly,
}) => {
  const { serverHash } = useParams()

  return (
    <StyledAnamnesisForm autoComplete="off">
      <div className="outer">
        <div className="inner">
          <div className="columnFullWidth">
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/06`} iconDirection="up" />
            <CheckboxXOR
              {...nsErhoben}
              className="checkboxXORHead"
              label="Neurologischer Status erhoben?"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />
          </div>
          <div className="column">
            {nsErhoben.value && (
              <>
                <FieldContainer label="Mall. Med. rechts:">
                  <SelectField {...nsMallMedRechts} options={nsItems} disabled={readOnly} />
                </FieldContainer>

                <FieldContainer label="Hallux rechts:">
                  <SelectField {...nsHalluxRechts} options={nsItems} disabled={readOnly} />
                </FieldContainer>

                <FieldContainer label="Apex rechts:">
                  <SelectField {...nsApexRechts} options={nsItems} disabled={readOnly} />
                </FieldContainer>

                <FieldContainer label="Wärme-/Kälteempfinden">
                  <SelectField {...nsWaermeKaelteEmpfinden} options={nsPosNegItems} disabled={readOnly} />
                </FieldContainer>
              </>
            )}
          </div>
          <div className="column">
            {nsErhoben.value && (
              <>
                <FieldContainer label="Mall. Med. links:">
                  <SelectField {...nsMallMedLinks} options={nsItems} disabled={readOnly} />
                </FieldContainer>

                <FieldContainer label="Hallux links:">
                  <SelectField {...nsHalluxLinks} options={nsItems} disabled={readOnly} />
                </FieldContainer>

                <FieldContainer label="Apex links:">
                  <SelectField {...nsApexLinks} options={nsItems} disabled={readOnly} />
                </FieldContainer>

                <FieldContainer label="Monofilament">
                  <SelectField {...nsMonofilament} options={nsPosNegItems} disabled={readOnly} />
                </FieldContainer>
              </>
            )}
          </div>
          <div className="columnFullWidth">
            <InputField {...nsKommentar} multiline rows={5} label="Kommentar" readOnly={readOnly} />
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/08`} iconDirection="down" />
          </div>
        </div>
      </div>
    </StyledAnamnesisForm>
  )
}

const validate = (values) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  return errors
}

export default reduxForm({
  form: 'anamneseForm',
  validate,
  fields,
  destroyOnUnmount: false,
})(AF07)
