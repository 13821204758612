import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import BerichteIndex from '../../components/BerichteIndex/BerichteIndex'
import Contacts from '../../components/Contacts/Contacts'
import Einstellungen from '../../components/Einstellungen/Einstellungen'
import Error404 from '../../shared/components/Error404/Error404'
import AddCustomer from '../AddCustomer/AddCustomer'
import AddDoctor from '../AddDoctor/AddDoctor'
import { AddNewList } from '../AddNew/AddNewList'
import AddTherapiebericht from '../AddTherapiebericht/AddTherapiebericht'
import { AnamnesisRoutes } from '../Anamnesis/AnamnesisRoutes'
import Calendar from '../Calendar/Calendar'
import CustomerDetails from '../CustomerDetails'
import Dokumentationen from '../Dokumentationen/Dokumentationen'
import EditCustomer from '../EditCustomer/EditCustomer'
import EditDoctor from '../EditDoctor/EditDoctor'
import Empty from '../Empty/Empty'
import { HVOControlContainer } from '../HVOControl/HVOControlContainer'
import ViewArzt from '../ViewArzt/ViewArzt'
import ViewHVO from '../ViewHVO/ViewHVO'

interface Props {
  dialogActions: {
    openTerminAbsageDialog: (termin: any) => void
    openTerminAbrechnenDialog: (termin: any) => void
    openFinishAnamneseDialog: (anamnese: any) => void
    openPlanLimitDialog: (data: {
      isOpen: boolean
      title: string
      body: string
      showConfirmBtn: boolean
      showUpgradeBtn: boolean
      confirmRedirectPath?: string
    }) => void
    closePlanLimitDialog: () => void
  }
  practicePlanId?: string
}

export const OverlayRoutes: FC<Props> = (props) => (
  <Routes>
    <Route path="/add-new" element={<AddNewList {...props} />} />
    <Route path="/anamnesis/:patient/*" element={<AnamnesisRoutes {...props} />} />
    <Route path="/calendar/*" element={<Calendar {...props} />} />
    <Route path="/contacts/*" element={<Contacts {...props} />} />
    <Route path="/contacts/customers/add" element={<AddCustomer {...props} />} />
    <Route path="/contacts/customers/:customer" element={<CustomerDetails {...props} />} />
    <Route path="/contacts/customers/:customer/edit" element={<EditCustomer />} />
    <Route path="/contacts/doctors/add" element={<AddDoctor />} />
    <Route path="/contacts/doctors/:doctor/edit" element={<EditDoctor />} />
    <Route path="/contacts/doctors/:arzt" element={<ViewArzt />} />
    <Route path="/hvo" element={<Empty />} />
    <Route path="/hvo/add/:patient" element={<HVOControlContainer />} />
    <Route path="/hvo/:heilmittelverordnung/edit" element={<HVOControlContainer />} />
    <Route path="/hvo/:heilmittelverordnung" element={<ViewHVO {...props} />} />

    <Route path="/berichte/*" element={<BerichteIndex {...props} />} />
    <Route path="/reports/add/:patient" element={<AddTherapiebericht />} />
    <Route path="/documentation/:patient" element={<Dokumentationen />} />
    <Route path="/documentation/:patient/:termin" element={<Dokumentationen />} />
    <Route path="/settings/*" element={<Einstellungen {...props} />} />

    <Route path="/404" element={<Error404 />} />
    <Route path="/*" element={<Navigate to={`./404`} />} />
  </Routes>
)
