import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { StyledPlanLimitDialog } from './StyledPlanLimitDialog'

interface Props {
  open: boolean
  showConfirmBtn: boolean
  showUpgradeBtn: boolean
  confirmRedirectPath?: string
  onCloseFunc: (...params) => any
  content: {
    title: string
    body: React.ReactNode
  }
}

export const PlanLimitDialog = ({
  open,
  onCloseFunc,
  showConfirmBtn,
  showUpgradeBtn,
  content,
  confirmRedirectPath,
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const handleDialogClose = (): void => {
    if (confirmRedirectPath) {
      navigate(confirmRedirectPath)
    }
    onCloseFunc()
  }

  const handleUpgradeRedirect = (): void => {
    const url =
      process.env.NODE_ENV === 'production'
        ? 'https://pododesk.de/fuexc_unidy/start'
        : 'https://staging.pododesk.de/fuexc_unidy/start'
    window.open(url, '_self')
  }

  return (
    <StyledPlanLimitDialog open={open}>
      <DialogTitle className="dialogTitle">{content.title}</DialogTitle>
      <DialogContent className="dialogContent">{content.body}</DialogContent>
      <DialogActions className="dialogActions">
        {showConfirmBtn && (
          <Button variant="contained" type="button" color="secondary" onClick={handleDialogClose}>
            Verstanden
          </Button>
        )}
        {showUpgradeBtn && (
          <Button variant="contained" type="button" color="secondary" onClick={handleUpgradeRedirect}>
            Planmodell upgraden
          </Button>
        )}
      </DialogActions>
    </StyledPlanLimitDialog>
  )
}
