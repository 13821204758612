/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FormControlLabel, Radio } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import { MultipleAutocompleteField } from '../../../shared/components/AutocompleteField'
import CheckboxXOR from '../../../shared/components/CheckboxXOR'
import DateField from '../../../shared/components/DateField/DateField'
import InputField from '../../../shared/components/InputField/InputField'
import { validateDateFieldNotInFuture } from '../../../utils/validation'

import DividerLink from '../components/DividerLink'
import FieldContainer from '../components/FieldContainer'
import { StyledAnamnesisForm, StyledRadioGroup } from '../styles'
import { fields } from '../utils'

const dfBeschwerdenOptions = [
  'Kribbeln',
  'Ameisen laufen',
  'Brennen/Schmerzen',
  'Muskelkrämpfe',
  'Unsicherheit beim Gehen',
  'Schwäche/Muskelschwund',
  'keine',
].map((item) => ({
  text: item,
  value: item,
}))

const AF02 = ({
  fields: {
    dfDiabetiker,
    dfTyp,
    dfDiabetikerSeitWann,
    dfInsulin,
    dfZuckertabletten,
    dfHba1cBekannt,
    dfHba1cWert,
    dfHba1cDatum,
    dfDiabetischePolyneuropathie,
    dfDiabetischeRetinopathie,
    dfDiabetischeNephropathie,
    dfKommentar,
    dfBeschwerden,
    patient,
  },
  readOnly,
}) => {
  const { serverHash } = useParams()

  return (
    <StyledAnamnesisForm autoComplete="off">
      <div className="outer">
        <div className="inner">
          <div className="columnFullWidth">
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/01`} iconDirection="up" />
            <CheckboxXOR
              {...dfDiabetiker}
              className="checkboxXORHead"
              label="Diabetiker?"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />
          </div>
          <div className="column">
            {dfDiabetiker.value && (
              <FieldContainer label="Typ">
                <StyledRadioGroup {...dfTyp} value={dfTyp.value}>
                  <FormControlLabel control={<Radio />} value="typ1" label="Typ 1" className="radioButtonVertical" disabled={readOnly} />
                  <FormControlLabel control={<Radio />} value="typ2" label="Typ 2" className="radioButtonVertical" disabled={readOnly} />
                </StyledRadioGroup>
              </FieldContainer>
            )}
          </div>
          <div className="column">
            {dfDiabetiker.value && (
              <FieldContainer label="Seit wann?">
                <DateField 
                  {...dfDiabetikerSeitWann}
                  disableFuture
                  fullWidth
                  onChange={(date) => {
                    dfDiabetikerSeitWann.onChange(date)
                    dfDiabetikerSeitWann.onBlur(date)
                  }}
                  helperText={!!dfDiabetikerSeitWann.touched && !!dfDiabetikerSeitWann.error ? dfDiabetikerSeitWann.error : ''}
                  error={!!dfDiabetikerSeitWann.touched && !!dfDiabetikerSeitWann.error}
                  readOnly={readOnly}
                />
              </FieldContainer>
            )}
          </div>
          <div className="column">
            {dfDiabetiker.value && (
              <>
                <FieldContainer label="Insulin?">
                  <CheckboxXOR {...dfInsulin} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>
                <FieldContainer label="Zuckertabletten?">
                  <CheckboxXOR {...dfZuckertabletten} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>
                <FieldContainer label="Hba1c bekannt?">
                  <CheckboxXOR {...dfHba1cBekannt} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>

                {dfHba1cBekannt.value && (
                  <>
                    <FieldContainer label="Hba1c Wert:">
                      <InputField {...dfHba1cWert} readOnly={readOnly} />
                    </FieldContainer>
                    <FieldContainer label="Gemessen am:">
                      <DateField 
                        fullWidth 
                        {...dfHba1cDatum}
                        disableFuture
                        onChange={(date) => {
                          dfHba1cDatum.onChange(date)
                          dfHba1cDatum.onBlur(date)
                        }}
                        helperText={!!dfHba1cDatum.touched && !!dfHba1cDatum.error ? dfHba1cDatum.error : ''}
                        error={!!dfHba1cDatum.touched && !!dfHba1cDatum.error}
                        readOnly={readOnly}
                      />
                    </FieldContainer>
                  </>
                )}
              </>
            )}
          </div>
          <div className="column">
            {dfDiabetiker.value && (
              <>
                <FieldContainer label="Diabetische Polyneuropathie (PNP)?">
                  <CheckboxXOR {...dfDiabetischePolyneuropathie} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>

                {dfDiabetischePolyneuropathie.value && (
                  <FieldContainer label="Beschwerden:" className="fieldContainerBeschwerden">
                    <MultipleAutocompleteField {...dfBeschwerden} options={dfBeschwerdenOptions} readOnly={readOnly} />
                  </FieldContainer>
                )}
                <FieldContainer label="Diabetische Retinopathie?">
                  <CheckboxXOR {...dfDiabetischeRetinopathie} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>
                <FieldContainer label="Diabetische Nephropathie?">
                  <CheckboxXOR {...dfDiabetischeNephropathie} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>
              </>
            )}
          </div>

          <div className="columnFullWidth">
            <InputField {...dfKommentar} multiline rows={5} label="Kommentar" readOnly={readOnly} />
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/03`} iconDirection="down" />
          </div>
        </div>
      </div>
    </StyledAnamnesisForm>
  )
}

const validate = (values, props) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  return {
    ...errors,
    ...validateDateFieldNotInFuture(values, props, ['dfDiabetikerSeitWann', 'dfHba1cDatum']),
  }
}

export default reduxForm({
  form: 'anamneseForm',
  validate,
  fields,
  destroyOnUnmount: false,
})(AF02)
