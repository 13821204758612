/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import { MultipleAutocompleteField } from '../../../shared/components/AutocompleteField'
import CheckboxXOR from '../../../shared/components/CheckboxXOR'
import InputField from '../../../shared/components/InputField/InputField'

import DividerLink from '../components/DividerLink'
import FieldContainer from '../components/FieldContainer'
import { StyledAnamnesisForm } from '../styles'
import { fields } from '../utils'

const dsBeschwerdenOptions = [
  'Gehstrecke <200m',
  'kalte Füße',
  'bläulich livide Haut',
  'keine Hyperkeratosen',
  'starkes Schmerzempfinden',
  'Puls nicht ertastbar',
  'keine',
].map((item) => ({
  text: item,
  value: item,
}))

const AF03: FC<any> = ({
  fields: { dsBekanntOderAnzeichen, dsPAVK, dsAndere, dsKommentar, dsBeschwerden, patient },
  readOnly,
}) => {
  const { serverHash } = useParams()

  return (
    <StyledAnamnesisForm autoComplete="off">
      <div className="outer">
        <div className="inner">
          <div className="columnFullWidth">
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/02`} iconDirection="up" />
            <CheckboxXOR
              {...dsBekanntOderAnzeichen}
              className="checkboxXORHead"
              label="Durchblutungsstörungen bekannt oder Anzeichen vorhanden?"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />
            {dsBekanntOderAnzeichen.value && (
              <>
                <FieldContainer label="pAVK?" pullLeft>
                  <CheckboxXOR {...dsPAVK} className="checkboxXOR" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                </FieldContainer>
                <FieldContainer label="Andere Durchblutungsstörungen?" pullLeft>
                  <InputField {...dsAndere} multiline readOnly={readOnly} />
                </FieldContainer>
                <FieldContainer label="Beschwerden:" className="fieldContainerBeschwerdenPullLeft">
                  <MultipleAutocompleteField {...dsBeschwerden} options={dsBeschwerdenOptions} readOnly={readOnly} />
                </FieldContainer>
              </>
            )}

            <InputField {...dsKommentar} multiline rows={5} label="Kommentar" readOnly={readOnly} />
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/04`} iconDirection="down" />
          </div>
        </div>
      </div>
    </StyledAnamnesisForm>
  )
}

const validate = (values) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  return errors
}

export default reduxForm({
  form: 'anamneseForm',
  validate,
  fields,
  destroyOnUnmount: false,
})(AF03)
