import styled from 'styled-components'
import Background from './pododesk-keyvisual_2000x1125.jpg'

export const StyledStartbereich = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-width: 100%;
    background: url(${Background});
    background-attachment: fixed;
    background-size: cover;
    flex: 1;

    .header {
      background-color: ${theme.palette.primary.main};
      width: 100vw;
      padding-left: 30px;
      height: 50px;
      font-size: x-large;
      color: #5e6267;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: fixed;
      z-index: ${theme.zIndex.appBar};
    }

    .triangle {
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid ${theme.palette.primary.main};
      border-bottom: 0 solid transparent;
      position: absolute;
      left: 40px;
      top: 100%;
    }

    .background {
      background-color: rgba(94, 98, 103, 0.7);
      box-sizing: border-box;
      max-width: 100vw;
      min-height: 100%;
      padding: ${theme.spacing(4)}px;
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: no-wrap;
      padding-top: 50px;

      ${theme.breakpoints.down('md')} {
        flex-wrap: wrap;
      }
    }

    .cardContainerOuter {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 30px;
    }

    .cardContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 30px;
    }

    .infoContainer {
      flex-grow: 0;
      height: min-content;
      display: flex;
      flex-dirextion: row;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: 30px;

      ${theme.breakpoints.down('sm')} {
        height: 100%;
      }
      ${theme.breakpoints.down('md')} {
        justify-content: center;
        flex-wrap: wrap
      }

      &__item {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-right: 25px;
        min-width: 300px;

        ${theme.breakpoints.down('md')} {
          margin-top: 25px;
          // height: calc(100% - 25px);
        }
      }
    }

    
  `}
`
