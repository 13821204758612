import fetch from 'isomorphic-fetch'
import * as R from 'ramda'
import { createSelector } from 'reselect'
import { startbereichRoutes } from './constants'
import { User } from 'oidc-client'

// TODO clean this up (copied from selectors.js) since
// selectors can't be required accross multiple files since the imports will conflict
export const apiServerHashSelector = createSelector(
  () => window.location.pathname.split('/')[1],
  (hashSegment) => {
    if (startbereichRoutes.indexOf(hashSegment) === -1 && hashSegment && hashSegment.length) {
      return hashSegment
    } else {
      return undefined
    }
  },
)

export function sAuth(state: StoreState): Auth {
  return state.auth
}

export const sApiServer = createSelector(sAuth, apiServerHashSelector, ({ apiServers }, apiServerHash) =>
  R.compose(R.head, R.filter(R.propEq('nameHash', apiServerHash)), R.defaultTo([]))(apiServers),
)

export const sApiServerName = createSelector(sApiServer, (apiServer) => (apiServer ? apiServer.name : undefined))

export const sCredentials = (): undefined | string | null => {
  const oidcStorage = window.localStorage.getItem(
    `oidc.user:${process.env.AUTH_AUTHORITY}:${process.env.AUTH_CLIENT_ID}`,
  )
  const user = User.fromStorageString(oidcStorage!)
  // check expired
  if (!user || user.expired) {
    return undefined
  }

  return user.id_token
}

export function fetchSecure(URL, options, credentials): any {
  if (!credentials) {
    console.error('ERROR: trying to use fetchSecure() without credentials!')
  }

  return fetch(URL, {
    ...options,
    headers: {
      ...options.headers,
      'X-Auth': credentials,
    },
  })
}
