import { bindActionCreators } from 'redux'
import * as changelogActions from '../../../actions/changelog'
import { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import { ChangelogContent, DialogContainer } from './StyledViewChangelogDialog'

interface Props {
  changelogId: number | null
  actions: Actions
  allChangelogs: { id: number; createdAt: string; content?: string }[]
  open: boolean
  onClose: () => unknown
}

const ViewChangelogDialog = ({ changelogId, actions, allChangelogs, open = false, onClose = () => {} }: Props) => {
  useEffect(() => {
    if (open) {
      if (!changelogId) {
        actions.changelog.loadLatestChangelog()
      } else {
        actions.changelog.loadChangelog(changelogId)
      }
    }
  }, [changelogId, open])

  const changelogToView = !open
    ? null
    : (changelogId ? allChangelogs.find((cl) => cl.id === changelogId) : allChangelogs[0]) || null

  useMemo(() => {}, [])

  return (
    <DialogContainer open={open && !!changelogToView} onClose={onClose}>
      <DialogTitle>
        Update vom {!!changelogToView?.createdAt && format(parseISO(changelogToView.createdAt), 'dd.MM.yy')}
      </DialogTitle>
      <DialogContent>
        {open && !changelogId && <Typography variant="body1">Seit Ihrem letzten Besuch gab es ein Update.</Typography>}
        {!!changelogToView && (
          <ChangelogContent dangerouslySetInnerHTML={{ __html: changelogToView?.content ?? '<div />' }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Verstanden
        </Button>
      </DialogActions>
    </DialogContainer>
  )
}

const mapStateToProps = (state) => ({
  allChangelogs: state.changelog.allChangelogs,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    changelog: bindActionCreators(changelogActions, dispatch),
  },
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ViewChangelogDialog)
