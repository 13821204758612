import { Dialog } from '@material-ui/core'
import styled from 'styled-components'

export const DialogContainer = styled(Dialog)`
  .MuiDialog-paper {
    max-height: 90vh;
    min-width: 50vw;
    max-width: 60vw;

    // max-width 90vw for mobile devices
    [theme.breakpoints.down('sm')]: {
      max-width: 90vw;
    }
  }
`

export const ChangelogContent = styled.div`
  margin-top: 12px;

  ul {
    gap: 12px;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
  }

  > ul {
    padding-left: 0;
    list-style-type: none;

    ul {
      margin-top: 8px;
      padding-left: 40px;
      list-style-type: disc;
    }
  }
`
