import { CircularProgress, IconButton, Paper } from '@material-ui/core'
import { Person as PersonIcon, PersonAdd as PersonAddIcon } from '@material-ui/icons'
import { FC, memo, useCallback } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as doctorsActions from '../../actions/doctors'
import AlphabetFilter from '../../components/AlphabetFilter/AlphabetFilter'
import DoctorsListView from '../../components/DoctorsListView/DoctorsListView'
import { StyledDoctorsList } from './StyledDoctorsList'
import { sApiServer } from '../../shared/utils/auth'
import { isReadOnly } from '../../utils/helpers'

const DoctorsList: FC<any> = ({
  actions,
  doctorsFilter,
  aerzteIds,
  initialLoadComplete,
  currentServer,
}): JSX.Element => {
  const navigate = useNavigate()
  const { serverHash } = useParams()

  const handleFilterClick = useCallback(
    (char) => {
      actions.filterDoctorsLastNameBeginsWith(char)
    },
    [actions.filterDoctorsLastNameBeginsWith],
  )

  const handleClearFilterClick = useCallback(() => {
    actions.filterDoctorsLastNameBeginsWith('')
  }, [actions.filterDoctorsLastNameBeginsWith])

  const handleAddDoctorClick = useCallback(() => {
    navigate(`/${serverHash}/contacts/doctors/add`)
  }, [navigate, serverHash])

  if (!initialLoadComplete.aerzte) {
    return (
      <div>
        <CircularProgress size={100} thickness={7} />
      </div>
    )
  }

  return (
    <StyledDoctorsList>
      <div className="topBarAlphabetFilter">
        <div className="topBarButtonWrapper">
          <IconButton onClick={handleClearFilterClick} className="topBarIconButton">
            <PersonIcon />
          </IconButton>
        </div>
        <AlphabetFilter selected={doctorsFilter.lastNameBeginsWith} onCharClick={handleFilterClick} />
        {!isReadOnly() && (
          <div className="topBarButtonWrapper">
            <IconButton onClick={handleAddDoctorClick} className="topBarIconButton">
              <PersonAddIcon />
            </IconButton>
          </div>
        )}
      </div>
      <div className="main">
        <DoctorsListView />
        {aerzteIds.length === 0 && (
          <Paper className="paper" elevation={2}>
            Sie haben noch keinen Arzt angelegt. Klicken Sie auf &quot;+&quot; und dann auf &quot;Arzt&quot; um die
            Ärzte Ihrer Patienten anzulegen. Danach können Sie diese für Heilmittelverordnungen, Therapieberichte und
            Patientenzuordnungen verwenden.
          </Paper>
        )}
      </div>
    </StyledDoctorsList>
  )
}

const mapStateToProps = (state) => ({
  doctorsFilter: state.doctorsFilter,
  aerzteIds: state.ids.aerzte,
  initialLoadComplete: state.initialLoadComplete,
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(doctorsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(DoctorsList))
