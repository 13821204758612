import { Dialog } from '@material-ui/core'
import styled from 'styled-components'

export const StyledNoPlanDialog = styled(Dialog)`
  ${({ theme }) => `
    .dialogActions {
      margin-bottom: ${theme.spacing(2)}px;
      align-items: start;
      padding: 8px 24px;
      flex-direction: column;
      gap: 8px;

      > button {
        margin: 0; 
      }
    }

    .dialogContent {
      margin-bottom: ${theme.spacing(2)}px;
    }

    .dialogTitle {
    }

    .link {
      color: ${theme.palette.secondary.main};
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`
