import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  Assignment as AssignmentIcon,
  Description as DescriptionIcon,
  NoteAdd as NoteAddIcon,
} from '@material-ui/icons'
import { format, isBefore, isSameDay } from 'date-fns'
import { default as de } from 'date-fns/locale/de'
import * as R from 'ramda'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import styled from 'styled-components'
import * as termineActions from '../../actions/dates'
import * as dokumentationenActions from '../../actions/dokumentationen'
import * as dokumentationsTexteActions from '../../actions/dokumentationsTexte'
import * as notificationActions from '../../actions/notification'
import PatientText from '../../components/PatientText/PatientText'
import { GERMAN_DATE_LONG_DAY_FNS, GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'
import { MultipleAutocompleteField } from '../../shared/components/AutocompleteField'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import InputField from '../../shared/components/InputField'
import { SelectField } from '../../shared/components/SelectField/SelectField'
import { sCurrentUserApi } from '../../shared/utils/users'
import { sortByBeginnDescending } from '../../utils/dates'
import { buildHeilmittelverordnungString } from '../../utils/heilmittelverordnungen'
import { buildLeistungenKKNagelkorrekturString, buildLeistungKKString } from '../../utils/leistungenKK'
import { buildLeistungPrivatString } from '../../utils/leistungenPrivat'
import FileUploadWithLightbox from '../FileUploadWithLightbox/FileUploadWithLightbox'
import { sAbbreviations } from '../../components/Calendar/selectors'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import { sApiServer } from '../../shared/utils/auth'
import { isReadOnly } from '../../utils/helpers'

const StyledForm = styled.form`
  ${({ theme }) => `
    flex-grow: 1;

    .container {
      display: grid;
      grid-template-columns: 1fr 250px;
      padding: ${theme.spacing(3)}px;
    }

    .disabledMessage {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .sidebar {
      display: flex;
      flex-direction: column;

      .termin-date {
        cursor: pointer;

        &.selected {
          background-color: ${theme.palette.primary.dark};
        }

        &:hover {
          background-color: ${theme.palette.primary.main};
        }
      }
    }

    .formFields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${theme.spacing(5)}px;
      padding-right: ${theme.spacing(3)}px;

      .Mui-disabled {
        color: #000;
        opacity: 1;
      }

    }

    .header {
      font-size: medium;
    }

    .details {
      display: flex;
      flex-direction: column;
    }

    .appointmentDetails {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      color: #000;
    }

    .detailsHeader {
      font-size: medium;
      color: ${theme.palette.text.primary};
    }

    .detailsField {
      margin-top: ${theme.spacing(3)}px;
    }

    .therapeutChargenNr {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: ${theme.spacing(3)}px;
      margin-top: ${theme.spacing(3)}px;
    }

    .documentationField {
      margin: ${theme.spacing(3)}px 0;
    }

    .previousEntries {
      color: #000;
    }

    .anamnesisButton {
      margin-bottom 15px;
      border-radius: 0;
    }
  `}
`

export const terminFields = ['terminId', 'leistungen', 'notizen']
export const dokumentationenFields = ['chargennummerInstrumente', 'therapeut', 'dateien']
export const fields = [...terminFields, ...dokumentationenFields, 'behandlungsDokumentation', 'medizinischeEmpfehlung']

const requiredFields = ['chargennummerInstrumente', 'therapeut']

function DokumentationenForm({
  actions,
  dirty,
  dokumentationen,
  dokumentationsTexte,
  termine,
  terminId,
  termineIds,
  patienten,
  patientId,
  users,
  currentUser,
  heilmittelverordnungen,
  leistungenKK,
  leistungenPrivat,
  leistungenPrivatIds,
  terminLimit = 10,
  initializeForm,
  fields,
  handleSubmit,
  abbreviations,
  praxisstammdaten,
  currentServer,
}) {
  const [showAllTermine, setShowAllTermine] = useState<boolean>(false)
  const [initializedOnLoad, setInitializedOnLoad] = useState<boolean>(false)
  const [currentFormId, setCurrentFormId] = useState<number>(0)
  const navigate = useNavigate()
  const { serverHash } = useParams()

  const toggleShowAllTermine = useCallback(() => {
    setShowAllTermine(!showAllTermine)
  }, [setShowAllTermine])

  const handleInitializeForm = async (termin) => {
    const dokumentation = dokumentationen[termin.dokumentation]

    const initValues = {
      terminId: termin.id,
      leistungen: termin.leistungen ?? [],
      notizen: termin.notizen ?? '',
      chargennummerInstrumente: dokumentation?.chargennummerInstrumente ?? '',
      therapeut: dokumentation?.therapeut ?? null,
      dateien: dokumentation?.dateien ?? [],
      behandlungsDokumentation: '',
      medizinischeEmpfehlung: '',
    }

    await initializeForm({})
    await initializeForm(initValues)
    setCurrentFormId(termin.id)
  }

  useEffect(() => {
    if (termine[terminId] && !initializedOnLoad) {
      const termin = termine[terminId]
      handleInitializeForm(termin)
      setInitializedOnLoad(true)
    }
  }, [terminId])

  const onSubmit = async (data) => {
    const termin = termine[data.terminId]

    const dokumentationsId = !termin.dokumentation
      ? (
          await actions.dokumentationen.createDokumentation({
            chargennummerInstrumente: data.chargennummerInstrumente,
            therapeut: data.therapeut,
            termin: data.terminId,
            dateien: data.dateien,
          })
        ).result[0]
      : (
          await actions.dokumentationen.updateDokumentation({
            ...dokumentationen[termin.dokumentation],
            dateien: data.dateien,
          })
        ).result[0]

    if (data.behandlungsDokumentation || data.medizinischeEmpfehlung) {
      await actions.dokumentationsTexte.createDokumentationsText({
        behandlungsDokumentation: data.behandlungsDokumentation.trim() === '' ? '-' : data.behandlungsDokumentation,
        medizinischeEmpfehlung: data.medizinischeEmpfehlung.trim() === '' ? '-' : data.medizinischeEmpfehlung,
        dokumentation: dokumentationsId,
      })
    }

    if (!R.equals(termin.leistungen, data.leistungen) || termin.notizen !== data.notizen) {
      await actions.termine.updateDate({
        ...termin,
        leistungen: data.leistungen ?? [],
        notizen: data.notizen ?? null,
      })
    }
  }

  const {
    notizen,
    chargennummerInstrumente,
    therapeut,
    dateien,
    behandlungsDokumentation,
    medizinischeEmpfehlung,
    leistungen,
  } = fields

  const patient = patienten[patientId]

  const now = new Date()

  const termineDesPatienten = useMemo(
    () =>
      termineIds
        .map((key) => termine[key])
        .filter(
          (termin) =>
            termin.patient === patientId &&
            (isSameDay(termin.beginn, now) || isBefore(termin.beginn, now)) &&
            (typeof termin.absagegrund !== 'string' || termin.absagegrund === ''),
        )
        .sort(sortByBeginnDescending),
    [termineIds, termine],
  )

  const therapeutenSelect = useMemo(
    () =>
      Object.keys(users)
        .filter((key) => users[key].istTherapeut || parseInt(key, 10) === therapeut.value)
        .map((key) => {
          const therapeut = users[key]
          return {
            ...therapeut,
            displayName: `${therapeut.vorname} ${therapeut.nachname}`,
          }
        }),
    [users],
  )

  const leistungenItemsKK = useMemo(() => {
    return Object.keys(leistungenKK)
      .map((key) => leistungenKK[key])
      .filter((leistungKK) => !leistungKK?.nagelkorrektur && !!leistungKK.kuerzel)
      .map((leistungKK) => {
        const leistungKKString = buildLeistungKKString(leistungKK)
        return {
          text: leistungKKString,
          value: { istKKLeistung: true, positionsnummer: leistungKK.positionsnummer },
          undeleteable: true,
          unselectable: true,
        }
      })
  }, [leistungenKK])

  const leistungenItemsNagelkorrektur = useMemo(() => {
    return Object.keys(leistungenKK)
      .map((key) => leistungenKK[key])
      .filter((leistungKK) => !!leistungKK?.nagelkorrektur)
      .map((leistungKK) => {
        const leistungKKString = buildLeistungenKKNagelkorrekturString(leistungKK)
        return {
          text: leistungKKString,
          value: { istKKLeistung: true, positionsnummer: leistungKK.positionsnummer },
          undeleteable: true,
          unselectable: true,
        }
      })
  }, [leistungenKK])

  const allLeistungenKK = leistungenItemsKK.concat(leistungenItemsNagelkorrektur)

  const leistungenItemsPrivat = useMemo(
    () =>
      leistungenPrivatIds
        .map((key) => leistungenPrivat[key])
        .map((leistungPrivat) => {
          const leistungString = buildLeistungPrivatString(
            leistungPrivat,
            praxisstammdaten.currency === 'EUR' ? '€' : 'CHF',
          )
          return {
            text: leistungString,
            value: { istKKLeistung: false, id: leistungPrivat.id },
            unselectable: !!leistungPrivat.deleted,
          }
        }),
    [leistungenPrivatIds, leistungenPrivat],
  )

  const leistungenItems = allLeistungenKK.concat(leistungenItemsPrivat)

  const abbreviationsList = useMemo(() => {
    const result: Record<string, string> = {}

    if (abbreviations) {
      for (const a in abbreviations) {
        const abb = abbreviations[a]

        result[abb.abbreviation] = abb.fullText
      }
    }

    return result
  }, [abbreviations])

  if (!therapeut.value && fields.terminId.value && termine[fields.terminId.value].therapeut === currentUser.id) {
    therapeut.onChange(currentUser.id)
  }

  const chosenTermin = termine[fields.terminId.value]
  let therapeutOfTermin
  let hvoOfTermin
  let dokumentation
  let noHVO
  if (chosenTermin) {
    therapeutOfTermin = users[chosenTermin.therapeut]
    hvoOfTermin = heilmittelverordnungen[chosenTermin.heilmittelverordnung]
    dokumentation = dokumentationen[chosenTermin.dokumentation]
    noHVO = chosenTermin.hvoWirdNachgereicht ? 'wird nachgereicht' : 'keine/privat'
  }

  const disabled = !fields.terminId.value
  let terminRowsRendered = 0

  return (
    <StyledForm isDisabled={disabled} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <IconHeading
        icon={<DescriptionIcon />}
        text={
          <span>
            Dokumentation | <PatientText patient={patient} showGender /> (
            <Link className="link" to={`/${serverHash}/contacts/customers/${patient && patient.id}`}>
              zur Patientenkartei
            </Link>
            )
          </span>
        }
      />
      <div className="container">
        <div>
          {disabled && (
            <div className="disabledMessage">
              <Typography variant="body1">Bitte wählen Sie einen Termin zur Dokumentation aus</Typography>
            </div>
          )}
          {!disabled && (
            <div>
              <div className="formFields">
                <div className="formGroup">
                  <Typography variant="h6" className="header">
                    Details zu diesem Termin
                  </Typography>
                  <br />
                  <div className="appointmentDetails">
                    <div className="details">
                      <Typography variant="h6" className="detailsHeader">
                        Therapeut
                      </Typography>
                      <Typography variant="body1">
                        {therapeutOfTermin && `${therapeutOfTermin.vorname} ${therapeutOfTermin.nachname.slice(0, 1)}.`}
                      </Typography>
                    </div>
                    <div className="details">
                      <Typography variant="h6" className="detailsHeader">
                        Heilmittelverordnung
                      </Typography>
                      <Typography variant="body1">
                        {hvoOfTermin
                          ? `vom ${buildHeilmittelverordnungString(hvoOfTermin, termine, leistungenKK)}`
                          : noHVO}
                      </Typography>
                    </div>
                  </div>
                  <div className="detailsField">
                    <MultipleAutocompleteField
                      {...leistungen}
                      className="field"
                      label="Leistungen"
                      options={leistungenItems}
                      readOnly={isReadOnly()}
                    />
                  </div>
                  <div className="detailsField">
                    <InputField
                      rows={''}
                      {...notizen}
                      label="Notiz des Termins"
                      error={notizen.touched && !!notizen.error}
                      helperText={notizen.touched && !!notizen.error && notizen.error}
                      multiline
                      disabled={isReadOnly()}
                    />
                  </div>
                </div>
                <div className="documentationGroup">
                  <Typography variant="h6" className="header">
                    Dokumentation zu diesem Termin
                  </Typography>
                  <div className="therapeutChargenNr">
                    <SelectField
                      {...therapeut}
                      label="behandelt von *" // treated by
                      error={therapeut.touched && !!therapeut.error}
                      helperText={therapeut.touched && !!therapeut.error && therapeut.error}
                      options={therapeutenSelect.map((user) => {
                        return { text: user.displayName, value: user.id }
                      })}
                      disabled={isReadOnly()}
                    />
                    <InputField
                      {...chargennummerInstrumente}
                      label="Chargen-Nr. Instrumente *"
                      error={chargennummerInstrumente.touched && !!chargennummerInstrumente.error}
                      helperText={
                        chargennummerInstrumente.touched &&
                        !!chargennummerInstrumente.error &&
                        chargennummerInstrumente.error
                      }
                      disabled={!!chargennummerInstrumente.initialValue || isReadOnly()}
                    />
                  </div>
                  <div className="documentationField">
                    {dokumentation?.dokumentationsTexte &&
                      dokumentation.dokumentationsTexte.map((key) => {
                        const dokumentationsText = dokumentationsTexte[key]
                        if (dokumentationsText?.behandlungsDokumentation) {
                          const user = users[dokumentationsText.createdBy]
                          return (
                            <div className="previousEntries">
                              <Typography variant="body1">
                                {`${format(dokumentationsText.createdAt, GERMAN_DATE_SHORT_YEAR_FNS)} | ${
                                  user.vorname
                                } ${user.nachname.slice(0, 1)}.:`}
                              </Typography>
                              {dokumentationsText.behandlungsDokumentation.split('\n').map((item, idx) => (
                                <Typography key={idx} variant="body1">
                                  {item}
                                </Typography>
                              ))}
                            </div>
                          )
                        }
                      })}
                  </div>
                  <div className="documentationField">
                    <InputField
                      rows={''}
                      {...behandlungsDokumentation}
                      label={`Behandlungsdokumentation${!therapeut.initialValue ? ' *' : ''}`}
                      multiline
                      error={behandlungsDokumentation.touched && !!behandlungsDokumentation.error}
                      helperText={
                        behandlungsDokumentation.touched &&
                        !!behandlungsDokumentation.error &&
                        behandlungsDokumentation.error
                      }
                      textExpand
                      abbreviations={abbreviationsList}
                      disabled={isReadOnly()}
                    />
                  </div>
                  <div className="documentationField">
                    {dokumentation?.dokumentationsTexte &&
                      dokumentation.dokumentationsTexte.map((key) => {
                        const dokumentationsText = dokumentationsTexte[key]
                        if (dokumentationsText?.medizinischeEmpfehlung) {
                          const user = users[dokumentationsText.createdBy]
                          return (
                            <div className="previousEntries">
                              <Typography variant="body1">
                                {`${format(dokumentationsText.createdAt, GERMAN_DATE_SHORT_YEAR_FNS)} | ${
                                  user.vorname
                                } ${user.nachname.slice(0, 1)}.:`}
                              </Typography>
                              {dokumentationsText.medizinischeEmpfehlung.split('\n').map((item, idx) => (
                                <Typography key={idx} variant="body1">
                                  {item}
                                </Typography>
                              ))}
                            </div>
                          )
                        }
                      })}
                  </div>
                  <div className="documentationField">
                    <InputField
                      rows={''}
                      {...medizinischeEmpfehlung}
                      label={`medizinische Empfehlung${!therapeut.initialValue ? ' *' : ''}`}
                      multiline
                      error={medizinischeEmpfehlung.touched && !!medizinischeEmpfehlung.error}
                      helperText={
                        medizinischeEmpfehlung.touched && !!medizinischeEmpfehlung.error && medizinischeEmpfehlung.error
                      }
                      textExpand
                      abbreviations={abbreviationsList}
                      disabled={isReadOnly()}
                    />
                  </div>
                </div>
              </div>

              <FileUploadWithLightbox
                {...dateien}
                assignTo={{
                  id: chosenTermin.dokumentation,
                  entity: 'dokumentationen',
                }}
                onFinish={() => {
                  setTimeout(handleSubmit(onSubmit), 0)
                }}
                readOnly={isReadOnly()}
              />

              <br />
              {!isReadOnly() && (
                <Button variant="contained" color="secondary" type="submit" disabled={!dirty} fullWidth>
                  Eingaben speichern
                </Button>
              )}
            </div>
          )}
        </div>
        <div className="sidebar">
          <Button
            className="anamnesisButton"
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={<AssignmentIcon />}
            onClick={() => {
              navigate(`/${serverHash}/anamnesis/${patientId}/01`)
            }}
          >
            zur Anamnese
          </Button>
          {termineDesPatienten.length > 0 && (
            <Paper elevation={1}>
              <List>
                {termineDesPatienten.map((termin) => {
                  terminRowsRendered++
                  if (!showAllTermine && terminRowsRendered > terminLimit) {
                    return null
                  }
                  return (
                    <ListItem
                      className={`termin-date ${currentFormId === termin?.id ? 'selected' : ''}`}
                      onClick={() => handleInitializeForm(termin)}
                      key={termin.id}
                    >
                      <ListItemText
                        primary={termin && format(termin.beginn, GERMAN_DATE_LONG_DAY_FNS, { locale: de })}
                      />
                      <ListItemIcon>
                        <Tooltip title={termin.dokumentation ? 'bereits dokumentiert' : 'nicht dokumentiert'}>
                          <IconButton disableTouchRipple>
                            {termin.dokumentation ? <DescriptionIcon /> : <NoteAddIcon />}
                          </IconButton>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItem>
                  )
                })}
                {termineDesPatienten.length > terminLimit && (
                  <ListItem onClick={toggleShowAllTermine}>
                    <ListItemText primary={showAllTermine ? 'weniger anzeigen' : 'mehr anzeigen'} />
                  </ListItem>
                )}
              </List>
            </Paper>
          )}
        </div>
      </div>
    </StyledForm>
  )
}

const validate = (values, props) => {
  const errors: KeyValue<string> = {}
  const { termine } = props
  const termin = termine[values.terminId]
  const required = [
    ...requiredFields,
    ...(termin && !termin.dokumentation ? ['behandlungsDokumentation', 'medizinischeEmpfehlung'] : []),
  ]

  required.forEach((fieldName) => {
    if (!values[fieldName] && values[fieldName] !== 0) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  console.log('errors: ', errors)

  return errors
}

const mapStateToProps = (state) => ({
  busy: state.notification.busy,
  dokumentationen: state.entities.dokumentationen,
  dokumentationsTexte: state.entities.dokumentationsTexte,
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  termine: state.entities.termine,
  termineIds: state.ids.termine,
  patienten: state.entities.patienten,
  users: state.entities.users,
  currentUser: sCurrentUserApi(state),
  leistungenKK: state.leistungenKK,
  leistungenPrivat: state.entities.leistungenPrivat,
  leistungenPrivatIds: state.ids.leistungenPrivat,
  abbreviations: sAbbreviations(state),
  praxisstammdaten: praxisstammdatenSelector(state),
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    dokumentationen: bindActionCreators(dokumentationenActions, dispatch),
    dokumentationsTexte: bindActionCreators(dokumentationsTexteActions, dispatch),
    termine: bindActionCreators(termineActions, dispatch),
    notification: bindActionCreators(notificationActions, dispatch),
  },
})

export default reduxForm(
  {
    form: 'dokumentationenForm',
    validate,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(DokumentationenForm))
