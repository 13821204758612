import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as changelogActions from '../../../actions/changelog'
import { DialogContainer, DialogLink, DialogLinkContainer, DialogTextContainer } from './StyledChangelogHistoryDialog'

interface Props {
  onClose: (...params: unknown[]) => unknown
  open: boolean
  actions: Actions
  allChangelogs: { id: number; createdAt: string }[]
  openViewChangelogDialog: (id: number) => unknown
}

const ChangelogHistoryDialog = ({
  onClose,
  open = false,
  actions,
  allChangelogs,
  openViewChangelogDialog,
}: Props): JSX.Element => {
  useEffect(() => {
    actions.changelog.loadChangelogs()
  }, [])

  return (
    <DialogContainer onClose={onClose} open={open}>
      <DialogTitle>pododesk Update-Historie</DialogTitle>
      <DialogTextContainer>
        <Typography variant="body2">
          Hier können Sie die Übersicht der Änderungen aus bisherigen Updates einsehen. Aus technischen Gründen sind nur
          Updates seit 01.04.23 dokumentiert.
        </Typography>
      </DialogTextContainer>
      <DialogContent>
        <DialogLinkContainer>
          {allChangelogs?.map((cl) => (
            <DialogLink key={cl.id} onClick={() => {
              openViewChangelogDialog(cl.id)
              onClose()
            }}>
              Update vom {format(parseISO(cl.createdAt), 'dd.MM.yy')}
            </DialogLink>
          ))}
        </DialogLinkContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Schließen
        </Button>
      </DialogActions>
    </DialogContainer>
  )
}

const mapStateToProps = (state) => ({
  allChangelogs: state.changelog.allChangelogs,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    changelog: bindActionCreators(changelogActions, dispatch),
  },
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ChangelogHistoryDialog)
