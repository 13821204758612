import { Card } from '@material-ui/core'
import styled from 'styled-components'

export const StyledNewPraxis = styled(Card)`
  ${({ theme }) => `
    background-color: rgba(228, 237, 178, 0.3);
    cursor: pointer;
    height: 250px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    transition: all 0.3s linear;
    justify-content: center;

    &:hover {
      background-color: ${theme.palette.primary.main};
      color: #5e6267;

      .addIcon {
        color: #fff;
        background-color: #5e6267;
      }

      .ruckInfoIcon {
        fill: #5e6267;
      }

      .textFieldInput {
        color: #5e6267;
      }
    }

    .cardContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 8px 8px 4px;
    }

    .cardContentInner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;
      text-align: left;
    }

    .textFieldInput {
      color: #fff;
    }

    .addIcon {
      background-color: #fff;
      border-radius: 16px;
      color: #5e6267;
      transition: all 0.3s linear;
    }

    .warningIcon {
      width: 1.3em;
      height: 1.3em;
    }

    .ruckInfoIcon {
      height: 44px;
      fill: #ffffff;
      transition: fill 0.3s linear;
    }

    .title {
      font-size: x-large;
    }

    .inputArea {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .button {
      margin-top: 10px;
      background-color: ${theme.palette.secondary.main};
      color: #fff;
    }
  `}
`
