import { ListItemIcon, MenuItem } from '@material-ui/core'
import {
  CheckCircle as CheckCircleIcon, Visibility as VisibilityIcon
} from '@material-ui/icons'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledListItemText } from './StyledListItemText'
import { sApiServer } from '../../shared/utils/auth'
import { connect } from 'react-redux'
import { isReadOnly } from '../../utils/helpers'

interface Props {
  dialogActions?: any,
  anamnese?: any,
  onCloseFunc: () => void
}

const MenuItemsAnamnese: FC<Props> = ({ dialogActions, anamnese, onCloseFunc, currentServer }) => {

  const navigate = useNavigate()
  const { serverHash } = useParams()

  const viewAnamnese = () => {
    navigate(`/${serverHash}/anamnesis/${anamnese.patient}/01`, {
      replace: true,
      state: { anamnese },
    })
    onCloseFunc()
  }

  const finishAnamnese = () => {
    dialogActions?.openFinishAnamneseDialog(anamnese)
    onCloseFunc()
  }

  if (!anamnese) return null

  return (
    <div>
      <MenuItem dense onClick={() => viewAnamnese()}>
        <ListItemIcon>
          <VisibilityIcon color="secondary" /> {/* ion-eye */}
        </ListItemIcon>
        <StyledListItemText primary={anamnese.abgeschlossen || isReadOnly() ? 'Anamnese anzeigen' : 'Anamnese anzeigen & bearbeiten'} />
      </MenuItem>
      {!anamnese.abgeschlossen && !isReadOnly() && (
        <MenuItem dense onClick={() => finishAnamnese()}>
          <ListItemIcon>
            <CheckCircleIcon color="secondary" /> {/* ion-checkmark */}
          </ListItemIcon>
          <StyledListItemText primary="Anamnese abschließen" />
        </MenuItem>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentServer: sApiServer(state),
})

export default connect(mapStateToProps)(MenuItemsAnamnese)
