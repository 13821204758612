import { CircularProgress, FormControlLabel, IconButton, Paper, Switch } from '@material-ui/core'
import { GetApp as GetAppIcon, Person as PersonIcon, PersonAdd as PersonAddIcon } from '@material-ui/icons'
import { format } from 'date-fns'
import { FC, memo, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { loadAnamnesen } from '../../actions/anamnesis'
import {
  filterCustomersLastNameBeginsWith,
  filterDeceased,
  filterLeavers,
  loadCustomers,
  loadCustomersAlphabetically,
} from '../../actions/customers'
import AlphabetFilter from '../../components/AlphabetFilter/AlphabetFilter'
import CustomersListView from '../../components/CustomersListView/CustomersListView'
import { FILTER_PATIENTEN_DEFAULT } from '../../constants/misc'
import { apiServerHashSelector, fetchSecure, sApiServer, sCredentials } from '../../shared/utils/auth'
import { sCurrentUser } from '../../shared/utils/users'
import { getApiUrl } from '../../utils/auth'
import { StyledCustomersList } from './StyledCustomersList'
import { isReadOnly } from '../../utils/helpers'

const CustomersList: FC<any> = ({
  customersFilter,
  patientenIds,
  initialLoadComplete,
  filterCustomersLastNameBeginsWith,
  loadCustomersAlphabetically,
  loadCustomers,
  loadAnamnesen,
  filterDeceased,
  filterLeavers,
  krankenkassen,
  api,
  currentUser,
  currentServer,
  ...props
}): JSX.Element => {
  const navigate = useNavigate()
  const { serverHash } = useParams()

  const handleFilterClick = useCallback(
    (char) => {
      filterCustomersLastNameBeginsWith(char)
      loadCustomersAlphabetically(char)
    },
    [filterCustomersLastNameBeginsWith, loadCustomersAlphabetically],
  )

  const handleClearFilterClick = useCallback(() => {
    filterCustomersLastNameBeginsWith('')
    loadCustomers()
  }, [loadCustomers, filterCustomersLastNameBeginsWith])

  const handleAddCustomerClick = useCallback(() => {
    navigate(`/${serverHash}/contacts/customers/add`)
  }, [serverHash])

  const handleDownloadCsv = useCallback(async () => {
    const { apiUrl, auth0Credentials } = api
    const URL = `${apiUrl}/patienten/csv`
    const response = await fetchSecure(
      URL,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ krankenkassen: krankenkassen }),
        credentials: 'include', // send credentials like cookies & basic auth
      },
      auth0Credentials,
    )

    const blob = await response.blob()
    const link = document.createElement('a')

    link.href = window.URL.createObjectURL(blob)
    link.style = 'visibility:hidden'
    const now = new Date()
    link.download = `Patienten ${format(now, 'dd.MM.yy HH:mm')}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [api, krankenkassen])

  useEffect(() => {
    loadCustomersAlphabetically(FILTER_PATIENTEN_DEFAULT)
    loadAnamnesen()
  }, [])

  if (!initialLoadComplete.patienten) {
    return (
      <StyledCustomersList>
        <CircularProgress className="circularProgress" size={100} thickness={7} />
      </StyledCustomersList>
    )
  }

  return (
    <StyledCustomersList>
      <div className="topBarAlphabetFilter">
        <div className="topBarButtonWrapper">
          <IconButton className="topBarIconButton" onClick={handleClearFilterClick}>
            <PersonIcon />
          </IconButton>
        </div>
        <AlphabetFilter selected={customersFilter.lastNameBeginsWith} onCharClick={handleFilterClick} />
        {!isReadOnly() && (
          <div className="topBarButtonWrapper">
            <IconButton className="topBarIconButton" onClick={handleAddCustomerClick}>
              <PersonAddIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
        {currentUser?.istAdmin && (
          <div className="downloadCsvButton">
            <IconButton className="downloadCsvIconButton" onClick={handleDownloadCsv}>
              <GetAppIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
      </div>
      <div className="main">
        <CustomersListView {...props} />
        {patientenIds.length === 0 && (
          <div className="paperWrapper">
            <Paper className="paper" elevation={3}>
              Sie haben noch keine Patienten angelegt. Klicken Sie auf &quot;+&quot; und dann auf &quot;Patient&quot;,
              um eine Patientenkartei anzulegen. Danach können Sie für den Patienten Heilmittelverordnungen, Termine,
              Anamnesen und Therapieberichte erstellen.
            </Paper>
          </div>
        )}
      </div>

      <div className="filterBar">
        <FormControlLabel
          control={<Switch />}
          label="verstorbene Patienten ausblenden"
          checked={customersFilter.skipDeceased}
          onChange={(e: any) => filterDeceased(e.target.checked)}
          className="toggle"
        />
        <FormControlLabel
          control={<Switch />}
          label="abgewanderte Patienten ausblenden"
          checked={customersFilter.skipLeavers}
          onChange={(e: any) => filterLeavers(e.target.checked)}
          className="toggle"
        />
      </div>
    </StyledCustomersList>
  )
}

const mapStateToProps = (state) => ({
  customersFilter: state.customersFilter,
  patientenIds: state.ids.patienten,
  initialLoadComplete: state.initialLoadComplete,
  krankenkassen: state.krankenkassen,
  api: {
    apiUrl: getApiUrl(apiServerHashSelector(state)),
    auth0Credentials: sCredentials(state),
  },
  currentUser: sCurrentUser(state),
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch) => ({
  filterDeceased: bindActionCreators(filterDeceased, dispatch),
  filterLeavers: bindActionCreators(filterLeavers, dispatch),
  loadCustomers: bindActionCreators(loadCustomers, dispatch),
  filterCustomersLastNameBeginsWith: bindActionCreators(filterCustomersLastNameBeginsWith, dispatch),
  loadCustomersAlphabetically: bindActionCreators(loadCustomersAlphabetically, dispatch),
  loadAnamnesen: bindActionCreators(loadAnamnesen, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomersList))
