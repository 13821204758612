import { ReactNode, createContext, useEffect, useMemo, useState } from 'react'
import { CardReaderInstance, SortedCardData } from '../../utils/cardreader/CardReaderInstance'
import { isWebBluetoothEnabled } from '../../utils/helpers'

interface CardReaderContext {
  isBluetoothConnected: boolean
  isCardReaderActive: boolean
  cardData: SortedCardData | null
  connectBluetooth: () => void
  disconnectBluetooth: () => void
  readCardData: () => Promise<void>
  resetCardData: () => void
}

export const CardReaderContext = createContext<CardReaderContext>({
  isBluetoothConnected: false,
  isCardReaderActive: false,
  cardData: null,
  connectBluetooth: () => undefined,
  disconnectBluetooth: () => undefined,
  readCardData: async () => undefined,
  resetCardData: () => undefined,
})

interface Props {
  children: ReactNode
}

export const CardReaderContextProvider = ({ children }: Props): JSX.Element => {
  // track bluetooth connection state
  const [isBluetoothConnected, setIsBluetoothConnected] = useState<boolean>(false)
  // track if card reader is currently reading data
  const [isCardReaderActive, setIsCardReaderActive] = useState<boolean>(false)
  // keep data in state for sharing across components
  const [cardData, setCardData] = useState<SortedCardData | null>(null)

  const onCardReaderConnected = (): void => {
    setIsBluetoothConnected(true)
  }

  const onCardReaderDisconnected = (): void => {
    setIsBluetoothConnected(false)
  }

  const onCardReaderStartReading = (): void => {
    setIsCardReaderActive(true)
  }

  const onCardReaderStopReading = (): void => {
    setIsCardReaderActive(false)
  }

  useEffect(() => {
    console.debug('window?.terminalRuntimeInitialized changed: ', window?.terminalRuntimeInitialized)
  }, [window?.terminalRuntimeInitialized])

  const bluetoothInit = useMemo(() => {
    if (isWebBluetoothEnabled() && !!window?.terminalRuntimeInitialized) {
      return new CardReaderInstance({
        onConnected: onCardReaderConnected,
        onDisconnected: onCardReaderDisconnected,
        onReadStart: onCardReaderStartReading,
        onReadEnd: onCardReaderStopReading,
      })
    } else {
      return null
    }
  }, [window?.terminalRuntimeInitialized])

  useEffect(() => {
    if (bluetoothInit) {
      window.t1_host_interface = bluetoothInit.getT1Interface()
      window.care_WebBluetooth_interface = bluetoothInit.getBluetoothInterface()
    } else {
      window.t1_host_interface = null
      window.care_WebBluetooth_interface = null
    }
  }, [bluetoothInit])

  const connectBluetooth = async (): Promise<void> => {
    bluetoothInit?.connectToCardReader()
  }

  const disconnectBluetooth = (): void => {
    bluetoothInit?.disconnectFromCardReader()
  }

  const readCardData = async (): Promise<void> => {
    const res = await bluetoothInit?.readCardData()

    if (!!res) {
      setCardData(res)
    } else {
      setCardData(null)
    }
  }

  const resetCardData = (): void => {
    setCardData(null)
  }

  return (
    <CardReaderContext.Provider
      value={{
        isBluetoothConnected,
        isCardReaderActive,
        cardData,
        connectBluetooth,
        disconnectBluetooth,
        readCardData,
        resetCardData,
      }}
    >
      {children}
    </CardReaderContext.Provider>
  )
}
